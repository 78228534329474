import { useState } from "react";
import useLocations from "../../Hooks/useLocations";
import { LocationCard } from "./LocationCard";
import { SelectedLocationCard } from "./SelectedLocationCard";
import { TrendingSpot } from "./TrendingSpot"; // Import the new component
import useSelectLocation from "../../Hooks/useSelectLocation";
import { useSavedLocationsContext } from "../../Context/SavedLocationContext";
import { GeneralInput } from "../General/Inputs/GeneralInput";
import { CallToActionSecondarySmall } from "../General/Buttons/CallToActionSecondarySmall";
import { FaTimes } from "react-icons/fa";
import { CallToActionMainOrangeSmall } from "../General/Buttons/CallToActionMainOrangeSmall";
import FilterList from "./FilterList";
import useProfile from "../../Hooks/useProfile";
import { useDistance } from "../../Context/DistanceContext";

export const List = () => {
  const { locations, loading, error: locError } = useLocations();
  const [searchTerm, setSearchTerm] = useState("");
  const { profile } = useProfile();
  const { selectedLocation, deselectLocation } = useSelectLocation(locations);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [filters, setFilters] = useState([]);
  const { savedLocations, showSaved, toggleShowSaved } =
    useSavedLocationsContext();
  const { getDistance } = useDistance();

  const removeFilter = (filterToRemove) => {
    setFilters(filters.filter((filter) => filter !== filterToRemove));
  };

  const clearFilters = () => setFilters([]);

  // Filter locations based on search and filters
  const filteredLocations = (showSaved ? savedLocations : locations)
    .filter((location) => {
      const searchMatch =
        location.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        location.key?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        location.spotLocationInfo?.place_name
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase());

      const tags = [
        location.wifi && "Wifi",
        location.whiteboards && "Whiteboards",
        location.outlets && "Outlets",
        location.IDRequired && "ID Required",
        location.foodDrink && "Food & Drink Allowed",
        location.groupWork && "Good for Group Work",
      ].filter(Boolean);

      const filtersMatch = filters.every((filter) => tags.includes(filter));

      return searchMatch && filtersMatch;
    })
    .sort((a, b) => {
      const distanceA = getDistance(a._id);
      const distanceB = getDistance(b._id);

      // If both distances are null, maintain original order
      if (distanceA === null && distanceB === null) return 0;
      // If only one distance is null, put it at the end
      if (distanceA === null) return 1;
      if (distanceB === null) return -1;
      // Otherwise sort by distance
      return distanceA - distanceB;
    });

  if (loading) return <div>Loading...</div>;
  if (locError) return <div>Error loading locations: {locError}</div>;

  // Select a random trending spot
  // const trendingSpot = locations.length > 0 ? locations[Math.floor(Math.random() * locations.length)] : null;

  return (
    <div className="w-full pt-16 -mt-[1px] h-screen border-borderLight dark:border-borderDark border-r overflow-hidden">
      {selectedLocation ? (
        <SelectedLocationCard
          location={selectedLocation}
          deselectLocation={deselectLocation}
        />
      ) : (
        <>
          {/* Search Input */}
          <div className="w-full fade-in p-2 bg-backgroundLight dark:bg-backgroundDark border-b border-t border-borderLight dark:border-borderDark">
            <GeneralInput
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search for a study spot"
            />
          </div>

          {/* Filters & Saved Toggle */}
          <div className="flex fade-in justify-between px-4 items-center py-2 bg-backgroundLight dark:bg-backgroundDark border-b border-borderLight dark:border-borderDark">
            <p className="text-xs poppins-light text-textLight dark:text-textDark2">
              Results ({filteredLocations.length})
            </p>
            <CallToActionSecondarySmall
              text="Filters"
              onClick={() => setFiltersOpen(!filtersOpen)}
            />
          </div>

          {filters.length > 0 && (
            <div className="flex fade-in flex-wrap gap-2 px-4 items-center py-2 bg-backgroundLight dark:bg-backgroundDark border-b border-borderLight dark:border-borderDark">
              {filters.map((filter, index) => (
                <CallToActionSecondarySmall
                  icon={<FaTimes />}
                  key={index}
                  text={filter}
                  onClick={() => removeFilter(filter)}
                />
              ))}

              {showSaved && (
                <CallToActionSecondarySmall
                  text={"Saved"}
                  icon={<FaTimes />}
                  onClick={toggleShowSaved}
                />
              )}

              <CallToActionMainOrangeSmall
                text={"Clear All"}
                onClick={clearFilters}
                className="ml-auto"
              />
            </div>
          )}

          {/* Trending Spot */}
          {/* {trendingSpot && <TrendingSpot location={trendingSpot} />} */}

          {/* List of Locations */}
          <div className="w-full h-full fade-in overflow-y-scroll scrollbar-hide pb-96 lg:pb-56">
            {filteredLocations.length > 0 ? (
              filteredLocations.map((location) => (
                <LocationCard key={location._id} location={location} />
              ))
            ) : (
              <div className="poppins-light text-black text-opacity-40 h-full flex items-center justify-center w-full">
                {profile
                  ? "No locations found."
                  : "Log in to see saved locations"}
              </div>
            )}
          </div>
        </>
      )}
      <FilterList
        isOpen={filtersOpen}
        onClose={() => setFiltersOpen(false)}
        setFilters={setFilters}
        filters={filters}
        toggleShowSaved={toggleShowSaved}
        showSaved={showSaved}
      />
    </div>
  );
};
