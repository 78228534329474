import React, { createContext, useContext, useEffect, useState } from "react";

const GetHelpContext = createContext();

export const GetHelpProvider = ({ children }) => {
  const [showingHelp, setShowingHelp] = useState(
    () => localStorage.getItem("showingHelp") === "true"
  );

  useEffect(() => {
    const handleStorageChange = () => {
      const state = localStorage.getItem("showingHelp") === "true";
      setShowingHelp(state);
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  useEffect(() => {
    localStorage.setItem("showingHelp", showingHelp);
  }, [showingHelp]);

  return (
    <GetHelpContext.Provider value={{ showingHelp, setShowingHelp }}>
      {children}
    </GetHelpContext.Provider>
  );
};

export const useGetHelp = () => useContext(GetHelpContext);
