import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useLocations from "../../Hooks/useLocations";
import {
  FaLayerGroup,
  FaLeaf,
  FaLightbulb,
  FaRegLightbulb,
  FaUser,
  FaVolumeMute,
  FaVolumeOff,
} from "react-icons/fa";
import { FaVolumeHigh } from "react-icons/fa6";
import { FaVolumeDown, FaClock, FaUsers } from "react-icons/fa";
import React from "react";
import useProfile from "../../Hooks/useProfile";
import AlertPopUp from "../General/Alerts/AlertPopUp";
import { AnimatePresence } from "framer-motion";
import { RiErrorWarningLine } from "react-icons/ri";
import PaginationProgress from "./PaginationProgress";
import { BasicInfo } from "./BasicInfo";
import { Features } from "./Features";
import { Comment } from "./Comment";

export const AddInfo = ({ location, showMobile }) => {
  const { profile } = useProfile();

  const navigate = useNavigate();
  const [instructions, setInstructions] = useState(true);
  const { refreshLocations } = useLocations();
  const [rating, setRating] = useState(0);
  const [newLocationName, setNewLocationName] = useState("");
  const [newLocationRating, setNewLocationRating] = useState(0);
  const [newLocationIDRequired, setNewLocationIDRequired] = useState(false);
  const [newLocationSilentArea, setNewLocationSilentArea] = useState(false);
  const [newLocationWifi, setNewLocationWifi] = useState(false);
  const [newLocationOutlets, setNewLocationOutlets] = useState(false);
  const [newLocationWhiteboards, setNewLocationWhiteboards] = useState(false);
  const [newLocationGroupWork, setNewLocationGroupWork] = useState(false);
  const [newLocationFoodDrink, setNewLocationFoodDrink] = useState(false);
  const [newLocationNoise, setNewLocationNoise] = useState(0);
  const [newLocationTables, setNewLocationTables] = useState(0);
  const [newLocationLighting, setNewLocationLighting] = useState(0);
  const [newLocationComment, setNewLocationComment] = useState("");
  const [message, setMessage] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [image, setImage] = useState(null);
  const [imageDisplay, setImageDisplay] = useState(null);
  const [showHelp, setShowHelp] = useState(true);
  const [file, setFile] = useState();
  const [showAlertPopUp, setShowAlertPopUp] = useState(false);
  const [icon, setIcon] = useState(<FaClock />);
  const [loading, setLoading] = useState(false);

  const [fromTime, setFromTime] = useState("09:00");
  const [toTime, setToTime] = useState("21:00");
  const [newLocationOpenHours, setNewLocationOpenHours] = useState("");

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({ top: -20, behavior: "smooth" });
    }
  }, [currentStep]);

  const formatTime = (time) => {
    if (!time) return "";
    const [hours, minutes] = time.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = ((hours + 11) % 12) + 1; // Convert to 12-hour format
    return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${period}`;
  };

  const options = [
    { id: 1, label: "Silent", icon: <FaVolumeMute /> },
    { id: 2, label: "Quiet", icon: <FaVolumeOff /> },
    { id: 3, label: "Moderate", icon: <FaVolumeDown /> },
    { id: 4, label: "Lively", icon: <FaVolumeHigh /> },
  ];

  const optionsTables = [
    { id: 1, label: "Individual", icon: <FaUser size={10} /> },
    { id: 2, label: "Shared", icon: <FaUsers /> },
    { id: 3, label: "Mixed", icon: <FaLayerGroup size={11} /> },
  ];

  const optionsLighting = [
    { id: 1, label: "Bright", icon: <FaLightbulb /> },
    { id: 2, label: "Natural", icon: <FaLeaf /> },
    { id: 3, label: "Dim", icon: <FaRegLightbulb /> },
  ];

  useEffect(() => {
    setNewLocationOpenHours(`${formatTime(fromTime)} - ${formatTime(toTime)}`);
  }, [fromTime, toTime]);

  const handleName = (e) => {
    const value = e.target.value;
    setNewLocationName(value);
  };

  const handleID = (value) => {
    setNewLocationIDRequired(value);
  };

  const handleSilentArea = (value) => {
    setNewLocationSilentArea(value);
  };

  const handleWifi = (value) => {
    setNewLocationWifi(value);
  };

  const handleOutlets = (value) => {
    setNewLocationOutlets(value);
  };

  const handleWhiteboards = (value) => {
    setNewLocationWhiteboards(value);
  };

  const handleGroupWork = (value) => {
    setNewLocationGroupWork(value);
  };

  const handleFoodDrink = (value) => {
    setNewLocationFoodDrink(value);
  };

  const handleComment = (e) => {
    setNewLocationComment(e.target.value);
  };

  const handleImage = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    const imageUrl = URL.createObjectURL(selectedFile);
    setImageDisplay(imageUrl);
  };

  const submitStudySpot = async () => {
    setLoading(true);
    try {
      if (newLocationName == "" || newLocationName == " ") {
        setMessage("Please enter a name for the location.");
        setIcon(<RiErrorWarningLine />);
        handleShowAlert();
        return;
      } else if (newLocationRating == 0) {
        setMessage("Please give the location a rating.");
        setIcon(<RiErrorWarningLine />);
        handleShowAlert();
        return;
      } else if (file == null) {
        setMessage("Please provide an image for the location");
        setIcon(<RiErrorWarningLine />);
        handleShowAlert();
        return;
      }

      if (
        newLocationOpenHours == null ||
        newLocationOpenHours == " - " ||
        newLocationOpenHours == "" ||
        newLocationOpenHours == " "
      ) {
        setMessage("Please provide open hours for the location");
        setIcon(<RiErrorWarningLine />);
        handleShowAlert();
        return;
      }

      let lighting = "";
      let tables = "";
      let noise = "";

      if (newLocationLighting == 0) {
        lighting = "Bright";
      } else if (newLocationLighting == 1) {
        lighting = "Natural";
      } else if (newLocationLighting == 2) {
        lighting = "Dim";
      }

      if (newLocationTables == 0) {
        tables = "Individual";
      } else if (newLocationTables == 1) {
        tables = "Shared";
      } else if (newLocationTables == 2) {
        tables = "Mixed";
      }

      if (newLocationNoise == 0) {
        noise = "Silent";
      } else if (newLocationNoise == 1) {
        noise = "Quiet";
      } else if (newLocationNoise == 2) {
        noise = "Moderate";
      } else if (newLocationNoise == 3) {
        noise = "Lively";
      }

      const formData = new FormData();

      // Append image file to FormData
      formData.append("image", file);

      // Append the rest of the study spot details
      formData.append("key", newLocationName);
      formData.append("name", newLocationName);
      formData.append("username", profile.username);
      formData.append("rating", newLocationRating);
      formData.append("IDRequired", newLocationIDRequired);
      formData.append("groupWork", newLocationGroupWork);
      formData.append("foodDrink", newLocationFoodDrink);
      formData.append("noiseLevel", noise);
      formData.append("tables", tables);
      formData.append("lighting", lighting);
      formData.append("openHours", newLocationOpenHours);
      formData.append("comment", newLocationComment);
      formData.append("wifi", newLocationWifi);
      formData.append("createdById", profile._id);
      formData.append("whiteboards", newLocationWhiteboards);
      formData.append("outlets", newLocationOutlets);
      formData.append("lat", location.lat);
      formData.append("long", location.lng);

      console.log("FormData:", formData);

      // Send the form data via POST request to the server
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/locations/createstudyspot`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data) {
        setLoading(false);
        setMessage("Study spot created successfully!");
        navigate(`/${response.data.id}`);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          console.error(error);
        } else if (error.response.status === 404) {
          console.error(error);
        } else {
          console.error(error);
        }
      } else if (error.request) {
        console.error(error);
      } else {
        console.error(error);
      }
    }
  };

  const handleShowAlert = () => {
    setShowAlertPopUp(true);
  };

  const handleCloseAlert = () => {
    setShowAlertPopUp(false);
  };

  return (
    <>
      <div className="fixed z-50 right-0 bottom-0">
        <AnimatePresence>
          {showAlertPopUp && message && (
            <AlertPopUp
              icon={icon}
              className={
                "bottom-auto top-20 lg:top-auto right-2 lg:left-auto text-xs lg:text-sm lg:right-2 lg:bottom-2"
              }
              message={message}
              onClose={handleCloseAlert}
            />
          )}
        </AnimatePresence>
      </div>

      <div
        ref={scrollContainerRef}
        className={`bg-backgroundLight h-full dark:bg-backgroundDark border-r border-borderLight dark:border-borderDark fade-in ${
          showMobile ? "lg:px-10 px-6 w-full" : "px-0 w-0"
        } pt-20 lg:pt-24 transition duration-700 ease-in-out scrollbar-hide`}
      >
        <h2 className="text-base mt-2 poppins-semibold text-black dark:text-stone-300 lg:text-xl">
          Create a Study Spot
        </h2>
        <p className="text-sm mt-1 text-textDark2 poppins-regular pb-2">
          Share your perfect spot in the study community
        </p>
        <div className="ml-2 mr-2 lg:mt-6 pt-4 pb-2">
          <PaginationProgress
            totalSteps={3}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        </div>
        <div className="w-full h-full pb-96 lg:pb-0 overflow-y-scroll  lg:overflow-y-visible overflow-x-hidden">
          {currentStep == 1 && (
            <BasicInfo
              newLocationName={newLocationName}
              newLocationRating={newLocationRating}
              handleName={handleName}
              setNewLocationRating={setNewLocationRating}
              file={file}
              setFromTime={setFromTime}
              setToTime={setToTime}
              setFile={setFile}
            />
          )}

          {currentStep == 2 && (
            <Features
              newLocationIDRequired={newLocationIDRequired}
              handleID={handleID}
              handleFoodDrink={handleFoodDrink}
              newLocationGroupWork={newLocationGroupWork}
              newLocationFoodDrink={newLocationFoodDrink}
              handleGroupWork={handleGroupWork}
              newLocationOutlets={newLocationOutlets}
              newLocationWhiteboards={newLocationWhiteboards}
              newLocationWifi={newLocationWifi}
              handleWhiteboards={handleWhiteboards}
              options={options}
              newLocationLighting={newLocationLighting}
              setNewLocationLighting={setNewLocationLighting}
              newLocationNoise={newLocationNoise}
              setNewLocationNoise={setNewLocationNoise}
              newLocationTables={newLocationTables}
              setNewLocationTables={setNewLocationTables}
              optionsLighting={optionsLighting}
              optionsTables={optionsTables}
              handleOutlets={handleOutlets}
              handleWifi={handleWifi}
            />
          )}

          {currentStep == 3 && (
            <Comment
              handleComment={handleComment}
              newLocationComment={newLocationComment}
              submitStudySpot={submitStudySpot}
              loading={loading}
            />
          )}
        </div>
      </div>
    </>
  );
};
