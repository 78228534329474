import { useState } from "react";
import AddMap from "../Components/Add/AddMap";

export const Create = () => {
  const [pinLocation, setPinLocation] = useState(null);

  return (
    <div className="w-screen h-screen overflow-hidden">
      <AddMap />
    </div>
  );
};
