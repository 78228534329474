import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useProfile from "../Hooks/useProfile";
import { CallToActionMain } from "../Components/General/Buttons/CallToActionMain";
import { useAlert } from "../Context/AlertContext";
import { GeneralInput } from "../Components/General/Inputs/GeneralInput";
// eslint-disable-next-line
import logoDark from "../Assets/logos/studyspotrlogodark.svg";
import logoLight from "../Assets/logos/studyspotrlogoorange.svg";

export const Login = () => {
  const { profile, refreshProfile } = useProfile();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { showAlert } = useAlert();

  const navigateRegister = () => {
    navigate("/register");
  };

  useEffect(() => {
    if (profile) {
      navigate("/profile");
    }
  }, [profile]);

  const logIn = async (e) => {
    e.preventDefault();

    if (email === "" || password === "") {
      showAlert("Please enter your email and password!");
      return;
    }

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}/api/auth/login`,
        { email, password },
        { withCredentials: true }
      );

      if (data) {
        localStorage.setItem("token", data.token);

        // Retrieve stored redirect path or default to home
        const redirectPath = localStorage.getItem("redirectAfterLogin") || "/";
        localStorage.removeItem("redirectAfterLogin"); // Clear storage after redirect

        refreshProfile();
        navigate(redirectPath);
        window.location.reload();
      }
    } catch (error) {
      showAlert(
        error.response?.data?.error || "Login failed. Please try again."
      );
      console.error("Login failed", error);
    }
  };

  const forgotPassword = (e) => {
    e.preventDefault();
    navigate("/reset-password");
  };

  return (
    <div className="flex bg-backgroundLight dark:bg-backgroundDark text-text flex-col items-center pt-24 lg:pt-0 lg:justify-center h-screen">
      <form
        onSubmit={logIn}
        className="w-10/12 lg:w-1/3 bg-backgroundCardsLight shadow-sm dark:bg-backgroundCardsDark rounded-xl px-4 lg:px-10 pt-10 pb-6 flex flex-col items-center fade-in"
      >
        <img src={logoLight} className="w-8 h-auto mb-4" alt="Logo" />
        <h1 className="poppins-medium text-textLight dark:text-textDark text-2xl text-center w-full">
          Welcome back
        </h1>
        <p className="poppins-light text-textLight dark:text-textDark text-xs text-center w-full mt-1.5">
          Please enter your details below to log in.
        </p>
        <GeneralInput
          className={"mt-8"}
          labelText={"Email or Username"}
          placeholder={"Email or Username"}
          onChange={(e) => setEmail(e.target.value)}
          type="text"
        />

        <GeneralInput
          className={"mt-4"}
          labelText={"Password"}
          placeholder={"Password"}
          hidden={true}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
        />
        <button
          type="button"
          onClick={forgotPassword}
          className="text-xs text-mainColorLight dark:text-mainColorDark w-full text-right mt-2 poppins-regular underline"
        >
          Forgot Password
        </button>

        <CallToActionMain
          text="Log in"
          type="submit"
          className={"mt-8 w-full"}
        />
        <button
          type="button"
          onClick={navigateRegister}
          className="text-xs text-textLight dark:text-textDark2 w-full text-center mt-6 poppins-regular"
        >
          Don't have an account?{" "}
          <span className="text-mainColorLight dark:text-mainColorDark">
            Create Account
          </span>
        </button>
      </form>
    </div>
  );
};
