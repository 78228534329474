import {
  FaPlug,
  FaChalkboard,
  FaRegIdCard,
  FaWifi,
  FaUsers,
} from "react-icons/fa";
import React, { useEffect } from "react";
import { FeatureSelector } from "./FeatureSelector";
import Slider from "./Slider";

export const Features = ({
  newLocationIDRequired,
  handleID,
  newLocationOutlets,
  newLocationWhiteboards,
  newLocationWifi,
  handleWhiteboards,
  options,
  optionsLighting,
  optionsTables,
  handleOutlets,
  newLocationLighting,
  setNewLocationLighting,
  newLocationNoise,
  setNewLocationNoise,
  newLocationTables,
  setNewLocationTables,
  handleWifi,
  handleFoodDrink,
  handleGroupWork,
  newLocationGroupWork,
  newLocationFoodDrink,
}) => {
  return (
    <>
      <label className="mb-2 mt-3 lg:mt-2 flex flex-row items-start gap-0.5 text-textLight dark:text-textDark2 poppins-regular text-xs ml-0.5">
        Features at this spot (select all that apply)
        <p className="text-mainColorLightOrange dark:text-mainColorDarkOrange">
          *
        </p>
      </label>
      <div className="grid xl:grid-cols-2 gap-2 lg:gap-4">
        <FeatureSelector
          icon={<FaRegIdCard size={12} />}
          label={"ID Required"}
          isSelected={newLocationIDRequired}
          onToggle={handleID}
        />
        {/* <FeatureSelector
              icon={<FaVolumeDown size={12} />}
              label={"Silent Areas"}
              isSelected={newLocationSilentArea}
              onToggle={handleSilentArea}
            /> */}
        <FeatureSelector
          icon={<FaWifi size={12} />}
          label={"Wifi"}
          isSelected={newLocationWifi}
          onToggle={handleWifi}
        />
        <FeatureSelector
          icon={<FaPlug size={12} />}
          label={"Outlets"}
          isSelected={newLocationOutlets}
          onToggle={handleOutlets}
        />
        <FeatureSelector
          icon={<FaChalkboard size={12} />}
          label={"Whiteboards"}
          isSelected={newLocationWhiteboards}
          onToggle={handleWhiteboards}
        />
        <FeatureSelector
          icon={<FaUsers size={12} />}
          label={"Good for Group Work"}
          isSelected={newLocationGroupWork}
          onToggle={handleGroupWork}
        />
        <FeatureSelector
          icon={<FaUsers size={12} />}
          label={"Food/Drinks Allowed"}
          isSelected={newLocationFoodDrink}
          onToggle={handleFoodDrink}
        />
      </div>
      <label className="mb-2 mt-2 lg:mt-3 flex flex-row items-start gap-0.5 text-textLight dark:text-textDark2 poppins-regular text-xs ml-0.5">
        Noise Level
        <p className="text-mainColorLightOrange dark:text-mainColorDarkOrange">
          *
        </p>
      </label>
      <Slider
        options={options}
        setSelected={setNewLocationNoise}
        selected={newLocationNoise}
      />

      <label className="mb-2 mt-2 lg:mt-3 flex flex-row items-start gap-0.5 text-textLight dark:text-textDark2 poppins-regular text-xs ml-0.5">
        Tables/Desks Space
        <p className="text-mainColorLightOrange dark:text-mainColorDarkOrange">
          *
        </p>
      </label>
      <Slider
        options={optionsTables}
        setSelected={setNewLocationTables}
        selected={newLocationTables}
      />

      <label className="mb-2 mt-2 lg:mt-3 flex flex-row items-start gap-0.5 text-textLight dark:text-textDark2 poppins-regular text-xs ml-0.5">
        Lighting
        <p className="text-mainColorLightOrange dark:text-mainColorDarkOrange">
          *
        </p>
      </label>
      <Slider
        options={optionsLighting}
        setSelected={setNewLocationLighting}
        selected={newLocationLighting}
      />
    </>
  );
};
