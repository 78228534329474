import React from "react";
import { InputGroup } from "../General/Inputs/InputGroup";
import { InputGroupRow } from "../General/Inputs/InputGroupRow";

export const RegisterFormStepOne = ({
  name,
  setName,
  username,
  setUsername,
  email,
  setEmail,
}) => (
  <div className="mt-8 w-full">
    <InputGroupRow
      fields={[
        {
          label: "Name",
          placeholder: "Name",
          value: name,
          onChange: setName,
          type: "text",
          required: true,
        },
        {
          label: "Username",
          placeholder: "Username",
          value: username,
          onChange: setUsername,
          type: "text",
          required: true,
        },
      ]}
    />
    <InputGroup
      fields={[
        {
          label: "Email",
          placeholder: "Email",
          value: email,
          onChange: setEmail,
          type: "email",
          required: true,
        },
      ]}
    />
  </div>
);

export const RegisterFormStepTwo = ({
  password,
  setPassword,
  passwordConfirm,
  setPasswordConfirm,
}) => (
  <div className="mt-4 w-full">
    <InputGroup
      fields={[
        {
          label: "Password",
          placeholder: "Password",
          value: password,
          onChange: setPassword,
          type: "password",
          required: true,
        },
        {
          label: "Confirm Password",
          placeholder: "Confirm Password",
          value: passwordConfirm,
          onChange: setPasswordConfirm,
          type: "password",
          required: true,
        },
      ]}
    />
  </div>
);

export const RegisterFormStepThree = ({
  linkedIn,
  setLinkedIn,
  instagram,
  setInstagram,
  discord,
  setDiscord,
  twitter,
  setTwitter,
}) => (
  <div className="mt-8 w-full flex flex-col gap-4">
    <InputGroupRow
      fields={[
        {
          label: "LinkedIn",
          placeholder: "e.g https://www.linkedin.com/in/username",
          value: linkedIn,
          onChange: setLinkedIn,
          type: "text",
          required: false,
        },
        {
          label: "Instagram",
          placeholder: "e.g https://www.instagram.com/username",
          value: instagram,
          onChange: setInstagram,
          type: "text",
          required: false,
        },
      ]}
    />
    <InputGroupRow
      fields={[
        {
          label: "Discord",
          placeholder: "e.g https://discord.com/users/userID",
          value: discord,
          onChange: setDiscord,
          type: "text",
          required: false,
        },
        {
          label: "Twitter",
          placeholder: "e.g https://x.com/username",
          value: twitter,
          onChange: setTwitter,
          type: "text",
          required: false,
        },
      ]}
    />
  </div>
);
