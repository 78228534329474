import React from "react";

export const EditableInput = ({
  labelText,
  placeholder,
  value,
  onChange,
  type = "text",
  className,
  required = false,
  currentValue,
  setIsEditing,
  isEditing,
}) => {
  const formatPhoneNumber = (input) => {
    // Remove all non-numeric characters
    const cleaned = input.replace(/\D/g, "");

    // Format into groups of 3-3-4
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (!match) return input;

    // Combine groups with dashes
    return [match[1], match[2], match[3]]
      .filter((group) => group) // Remove empty groups
      .join("-");
  };

  const handleInputChange = (e) => {
    const formattedValue =
      type === "tel" ? formatPhoneNumber(e.target.value) : e.target.value;
    onChange({ ...e, target: { ...e.target, value: formattedValue } });
  };

  return (
    <div className={`flex flex-col ${className} w-full`}>
      {labelText && (
        <label
          htmlFor={labelText}
          className="mb-1 flex flex-row items-start gap-0.5 text-textLight dark:text-textDark2 poppins-regular text-xs ml-0.5"
        >
          {labelText}
          {required && (
            <p className="text-mainColorLightOrange dark:text-mainColorDarkOrange">
              *
            </p>
          )}
        </label>
      )}
      {isEditing ? (
        <input
          id={labelText}
          type={type}
          value={value}
          onChange={handleInputChange}
          placeholder={placeholder}
          className="px-3.5 py-2.5 poppins-medium text-black dark:text-stone-300 opacity-80 text-base w-full border-none bg-backgroundLight dark:bg-backgroundDark rounded-md focus:outline-none focus:ring-2 focus:ring-mainColorLightOrange dark:focus:ring-mainColorDarkOrange"
        />
      ) : (
        <div
          onClick={() => setIsEditing(true)}
          className="px-0.5 py-0.5 poppins-medium text-black dark:text-stone-300 opacity-80 text-base hover:cursor-pointer"
        >
          {currentValue}
        </div>
      )}
    </div>
  );
};
