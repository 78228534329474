import React from "react";
import { EditableInput } from "../General/Inputs/EditableInput";

export const SocialMediaLink = ({
  url,
  platform,
  editingSocials,
  labelText,
  currentValue,
  onChange,
  placeholder,
  value,
}) => {
  const getUsernameFromUrl = (url) => {
    if (url) {
      try {
        const parsedUrl = new URL(url);

        if (parsedUrl.pathname.includes("/in/")) {
          return parsedUrl.pathname.split("/in/")[1].split("/")[0];
        }

        const segments = parsedUrl.pathname.split("/").filter(Boolean); // Remove empty segments
        return segments[segments.length - 1];
      } catch (error) {
        console.error("Invalid URL:", error);
        return "";
      }
    }
  };

  const username = getUsernameFromUrl(url);

  return (
    <div className="flex flex-col gap-0.5 flex-1">
      {platform && (
        <label
          htmlFor={platform}
          className="mb-1 flex flex-row items-start gap-0.5 text-textLight dark:text-textDark2 poppins-regular text-xs ml-0.5"
        >
          {platform}
        </label>
      )}
      {editingSocials ? (
        <EditableInput
          isEditing={editingSocials}
          currentValue={currentValue}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
        />
      ) : (
        <a
          href={url}
          rel="noopener noreferrer"
          target="_blank"
          className={`poppins-medium ml-0.5 ${
            url ? "opacity-80" : "opacity-30"
          } text-md ${
            editingSocials ? "mt-1.5" : "mt-0"
          } transition duration-500 ease-in-out`}
        >
          {url ? username : "Not Connected"}
        </a>
      )}
    </div>
  );
};
