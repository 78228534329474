export const Loading = ({ defualtLoading = false, text }) => {
  return (
    <div className="w-screen z-50 h-screen flex flex-col items-center justify-center ml-auto mr-auto">
      {defualtLoading && (
        <p className="text-center mb-4 -mt-20 text-black dark:text-stone-600 poppins-medium text-sm w-2/3 lg:w-1/4">
          As we are still in prototyping phases, loading may take up to 30
          seconds.{" "}
          <span className="text-mainColorLight dark:text-mainColorDark">
            If loading takes longer than 30 seconds refresh the page!
          </span>{" "}
          Thank you for your patience.
        </p>
      )}
      {text && text}
      <div className="loader"></div>
    </div>
  );
};
