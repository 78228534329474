import React, { useState } from "react";
import axios from "axios";
import useProfile from "../../Hooks/useProfile";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { FiStar } from "react-icons/fi";
import { FaStar } from "react-icons/fa";
import { CallToActionSecondary } from "../General/Buttons/CallToActionSecondary";
import { CallToActionMainOrange } from "../General/Buttons/CallToActionMainOrange";
import { TextBox } from "../General/Inputs/TextBox";

const RatingLocation = ({ location, cancelRating }) => {
  const [rating, setRating] = useState(0);
  const { profile } = useProfile();
  const [feedback, setFeedback] = useState("");
  const [reviewComment, setReviewComment] = useState("");

  const handleRating = (rate) => {
    setRating(rate);
  };

  const submitComment = async () => {
    try {
      const id = location._id;
      const username = profile.username;
      const userId = profile._id;
      const newReview = { comment: feedback, rating, username, userId };

      const response = await axios.put(
        `${process.env.REACT_APP_URL}/locations/addReview/${id}`,
        newReview
      );

      if (response.data) {
        console.log(`Comment added for ${location.name}!`);
        cancelRating();
        window.location.reload();
      } else {
        console.log("Failed to add comment");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("overlay")) {
      cancelRating();
    }
  };

  return (
    <div
      className="overlay fade-in fixed inset-0 z-30 flex items-center justify-center bg-black bg-opacity-60"
      onClick={handleOverlayClick}
    >
      <div className="bg-backgroundCardsLight dark:bg-backgroundCardsDark rounded-md px-6 py-6 lg:px-10 lg:py-8 w-10/12 lg:w-1/3 shadow-lg">
        <h1 className="text-sm lg:text-xl poppins-medium mb-2">
          Rate {location.name}
        </h1>

        <p className="poppins-regular text-xs lg:text-base mb-2">
          Your Rating <span className="text-orange-500">*</span>
        </p>
        <div className="flex mb-4 flex-row gap-1">
          {[1, 2, 3, 4, 5].map((star) => (
            <button key={star} onClick={() => handleRating(star)}>
              {star <= rating ? (
                <FaStar fill="#DA6202" className="text-base lg:text-xl" />
              ) : (
                <FaStar
                  fill="#e0e0e0"
                  className="star-full text-base lg:text-xl"
                />
              )}
            </button>
          ))}
        </div>

        <TextBox
          labelText={"Your Review:"}
          placeholder={"Leave a comment (optional)"}
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        />

        <div className="flex justify-end gap-2 mt-4">
          <CallToActionSecondary text={"Cancel"} onClick={cancelRating} />
          <CallToActionMainOrange
            text={"Submit Rating"}
            onClick={submitComment}
          />
        </div>
      </div>
    </div>
  );
};

export default RatingLocation;
