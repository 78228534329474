import { useState } from "react";
import { FaStar, FaBrain, FaMagic, FaArrowRight, FaUsers, FaBookOpen, FaClock } from "react-icons/fa";

const Sparkle = ({ delay = 0 }) => {
  const randomX = Math.random() * 100;
  const randomY = Math.random() * 100;
  const duration = 15 + Math.random() * 10;

  return (
    <div
      className="absolute animate-[float_var(--duration)s_ease-in-out_infinite,twinkle_var(--twinkle-duration)s_ease-in-out_infinite]"
      style={{
        "--duration": `${duration}s`,
        "--twinkle-duration": "3s",
        left: `${randomX}%`,
        top: `${randomY}%`,
        transform: `translate(0, 0)`,
        animation: `
          float ${duration}s ease-in-out infinite ${delay}s,
          twinkle 3s ease-in-out infinite ${delay}s
        `,
      }}
    >
      <FaStar
        size={Math.random() * 10 + 5}
        className="text-white opacity-80 transition-all duration-300"
        style={{
          filter: "drop-shadow(0 0 2px rgba(255, 255, 255, 0.5))",
        }}
      />
    </div>
  );
};

const QuizFeatureCard = ({ icon: Icon, title, description }) => (
  <div className="backdrop-blur-sm rounded-xl p-6 flex flex-col items-center text-center">
    <div className="bg-primary/20 p-3 rounded-lg mb-4">
      <Icon className="w-6 h-6 text-primary" />
    </div>
    <h3 className="text-lg font-semibold text-textLight dark:text-textDark mb-2">{title}</h3>
    <p className="text-sm text-textLight2 dark:text-textDark2">{description}</p>
  </div>
);

export const StudyTypeQuiz = () => {

  return (
    <div className="w-screen pb-10 overflow-y-scroll scrollbar-hide h-screen bg-backgroundLight dark:bg-backgroundDark fade-in pt-12 mt-4 lg:pt-10">
      <div className="relative overflow-hidden bg-gradient-to-b from-backgroundCardsLight to-backgroundLight dark:from-backgroundCardsDark dark:to-backgroundDark h-max py-8 px-4 lg:px-0 lg:py-24">
        <div className="absolute inset-0 bg-gradient-radial from-blue-500/20 via-transparent to-transparent" />


        {/* Hero Section */}
        <div className="relative z-10 flex flex-col items-center max-w-6xl mx-auto">
          <div className="flex items-center gap-2 bg-white/10 backdrop-blur-sm px-4 py-2 rounded-full mb-8">
            <FaMagic className="w-4 h-4 text-yellow-400" />
            <span className="text-sm text-textLight dark:text-textDark">Discover Your Study Style</span>
          </div>

          <h1 className="poppins-semibold text-4xl lg:text-7xl text-center text-textLight dark:text-textDark">
            Find Your Perfect
            <br />
            Study Method
          </h1>

          <p className="poppins-regular text-sm lg:text-base lg:w-2/5 w-10/12 text-center mt-6 lg:mt-8 text-textLight2 dark:text-textDark2">
            Take our comprehensive study style assessment to discover the learning techniques that work best for you. Unlock your academic potential today.
          </p>

          <button className="mt-8 dark:bg-primary hover:bg-primary/90 dark:text-white px-8 py-4 rounded-full flex items-center gap-2 transform hover:scale-105 transition-all duration-500 shadow-lg">
            <span className="poppins-medium">Start Quiz</span>
            <FaArrowRight className="w-4 h-4" />
          </button>

          {/* Stats */}
          <div className="flex flex-wrap justify-center gap-8 mt-12">
            <div className="flex items-center gap-2">
              <FaUsers className="w-5 h-5 text-primary" />
              <span className="text-sm text-textLight2 dark:text-textDark2">10k+ Students</span>
            </div>
            <div className="flex items-center gap-2">
              <FaClock className="w-5 h-5 text-primary" />
              <span className="text-sm text-textLight2 dark:text-textDark2">5 Minutes</span>
            </div>
            <div className="flex items-center gap-2">
              <FaBookOpen className="w-5 h-5 text-primary" />
              <span className="text-sm text-textLight2 dark:text-textDark2">7 Learning Styles</span>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="px-4 lg:px-20 py-16">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-2xl lg:text-4xl poppins-semibold text-center text-textLight dark:text-textDark mb-12">
            Why Take Our Study Style Quiz?
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <QuizFeatureCard
              icon={FaBrain}
              title="Personalized Insights"
              description="Get detailed analysis of your learning preferences and study habits."
            />
            <QuizFeatureCard
              icon={FaUsers}
              title="Tailored Recommendations"
              description="Receive custom study techniques and strategies that match your style."
            />
            <QuizFeatureCard
              icon={FaMagic}
              title="Immediate Results"
              description="Get your results instantly and start improving your study habits today."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudyTypeQuiz;
