import { ProfilePageCard } from "./ProfilePageCard";

export const AdditionalInformation = () => {
  return (
    <ProfilePageCard>
      <h2 className="poppins-medium text-lg">Additional Information</h2>

      <div className="flex flex-col lg:flex-row justify-between gap-3 lg:gap-0 w-full">
        <div className="flex flex-col gap-0.5 flex-1">
          <p className="poppins-light opacity-40 text-sm">Account Type</p>
          <p className="poppins-medium opacity-80 text-md">Standard Account</p>
        </div>
        <div className="flex flex-col gap-0.5 flex-1">
          <p className="poppins-light opacity-40 text-sm">Prefered Language</p>
          <p className="poppins-medium opacity-80 text-md">
            English{" "}
            <span className="text-xs poppins-regular text-black dark:text-stone-300">
              (More coming soon...)
            </span>
          </p>
        </div>
      </div>
    </ProfilePageCard>
  );
};
