import React from "react";
import DragAndDrop from "./DragAndDrop";
import { GeneralInput } from "../General/Inputs/GeneralInput";
import { TimeInput } from "./TimeInput";

export const BasicInfo = ({
  newLocationName,
  handleName,
  newLocationRating,
  setNewLocationRating,
  file,
  setFromTime,
  setToTime,
  setFile,
}) => {
  return (
    <div className="h-max overflow-y-scroll">
      <GeneralInput
        className={"mt-2"}
        labelText={"Name of the spot"}
        required={true}
        value={newLocationName}
        onChange={handleName}
        placeholder="E.g. UCLA Library 4th floor tables"
      />
      <label className="mb-2 mt-3 lg:mt-5 flex flex-row items-start gap-0.5 text-textLight dark:text-textDark2 poppins-regular text-xs ml-0.5">
        Rating
        <p className="text-mainColorLightOrange dark:text-mainColorDarkOrange">
          *
        </p>
      </label>
      <div className="space-y-1 lg:space-y-4">
        <div className="flex gap-2 lg:gap-3">
          {[1, 2, 3, 4, 5].map((star) => (
            <button
              key={star}
              onClick={() => setNewLocationRating(star)}
              className={`flex-1 aspect-square rounded-2xl transition-all ${
                star <= newLocationRating
                  ? "bg-gradient-to-br from-mainColorLight to-mainColorDark dark:to-backgroundDark3 text-white"
                  : "bg-backgroundCardsLight dark:bg-backgroundCardsDark text-gray-400 hover:bg-gray-200"
              }`}
            >
              <div className="flex flex-col items-center justify-center h-full">
                <span className="text-base lg:text-xl">★</span>
                <span className="text-xs lg:mt-1">{star}/5</span>
              </div>
            </button>
          ))}
        </div>
      </div>
      <label className="mb-2 mt-3 lg:mt-4 flex flex-row items-start gap-0.5 text-textLight dark:text-textDark2 poppins-regular text-xs ml-0.5">
        Hours of Operation
        <p className="text-mainColorLightOrange dark:text-mainColorDarkOrange">
          *
        </p>
      </label>
      <div className="grid xl:grid-cols-2 gap-2 lg:gap-6 h-max">
        <TimeInput
          label="Opens at"
          defaultTime="09:00"
          isOpen={true}
          onTimeChange={setFromTime}
        />
        <TimeInput
          label="Closes at"
          defaultTime="21:00"
          isOpen={false}
          onTimeChange={setToTime}
        />
      </div>
      <label className="mb-2 mt-3 lg:mt-5 flex flex-row items-start gap-0.5 text-textLight dark:text-textDark2 poppins-regular text-xs ml-0.5">
        {" "}
        Upload an Image of The Spot
        <p className="text-mainColorLightOrange dark:text-mainColorDarkOrange">
          *
        </p>
      </label>
      <DragAndDrop file={file} setFile={setFile} />
    </div>
  );
};
