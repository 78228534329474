import React from "react";
import {
  FiShield,
  FiDatabase,
  FiMap,
  FiUserPlus,
  FiBell,
} from "react-icons/fi";
import { LastUpdated } from "../Components/LastUpdated";

const PrivacyPolicy = () => {
  const sections = [
    {
      icon: <FiShield size={24} className="text-blue-600" />,
      title: "Data Collection",
      content: [
        "We collect the following types of information to provide you with the best possible study spot discovery experience:",
        "Location data to show nearby study spots",
        "Account information (email, name) for personalization",
        "Usage patterns to enhance app functionality",
        "Device information for optimization",
      ],
    },
    {
      icon: <FiDatabase size={24} className="text-green-600" />,
      title: "Data Usage",
      content: [
        "Your data helps us create a better StudySpotr experience:",
        "Personalizing study spot recommendations",
        "Showing popular spots in your area",
        "Improving search results based on preferences",
        "Analytics to enhance app performance",
      ],
    },
    {
      icon: <FiMap size={24} className="text-orange-600" />,
      title: "Location Data",
      content: [
        "Location data is essential for finding study spots near you:",
        "Accessed only when using the app",
        "Used to show nearby study locations",
        "Required for distance calculations",
        "Precise location is never shared with other users",
      ],
    },
    {
      icon: <FiShield size={24} className="text-purple-600" />,
      title: "Data Storage & Security",
      content: [
        "We take your data security seriously:",
        "All data is encrypted in transit and at rest",
        "Stored on secure AWS servers",
        "Data retention: active account data stored indefinitely",
        "We never sell your personal data to third parties.",
      ],
    },
    {
      icon: <FiUserPlus size={24} className="text-red-600" />,
      title: "Your Rights",
      content: [
        "You have full control over your data:",
        "Access your personal data anytime",
        "Update your information",
        "Request data deletion",
      ],
    },
    {
      icon: <FiBell size={24} className="text-yellow-600" />,
      title: "Policy Updates",
      content: [
        "We keep you informed about privacy policy changes:",
        "Email notifications for major changes",
        "Regular policy reviews",
      ],
    },
  ];

  return (
    <div className="w-screen pb-10 overflow-y-scroll scrollbar-hide h-screen bg-backgroundLight dark:bg-backgroundDark fade-in pt-12 mt-4 lg:pt-10">
      {/* Hero Section */}
      <div className="relative overflow-hidden bg-gradient-to-b from-backgroundCardsLight to-backgroundLight dark:from-backgroundCardsDark dark:to-backgroundDark py-12 lg:py-20 px-4 lg:px-0">
        <div className="absolute inset-0 bg-gradient-radial from-blue-400/20 via-transparent to-transparent" />

        <div className="relative z-10 flex flex-col items-center max-w-6xl mx-auto text-center">
          <div className="flex items-center gap-2 bg-white/10 backdrop-blur-sm px-4 py-2 rounded-full mb-6">
            <FiShield className="w-5 h-5 text-blue-600" />
            <span className="text-sm text-textLight dark:text-textDark">
              Study Spotr Privacy Policy
            </span>
          </div>

          <h1 className="poppins-semibold text-4xl lg:text-7xl text-textLight dark:text-textDark">
            Your data, your control.
          </h1>

          <p className="poppins-regular text-sm lg:text-base lg:w-2/5 w-10/12 text-center mt-6 lg:mt-8 text-textLight2 dark:text-textDark2">
            We value your privacy. Understand how StudySpotr collects, uses, and protects your data to ensure a safe experience.
          </p>

          <div className="mt-8 dark:text-white px-8 py-4 flex items-center gap-2 ">
            <span className="poppins-medium">Learn About Our Policies</span>
          </div>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="px-4 lg:px-20">
        <div className="max-w-6xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
          {sections.map((section, index) => (
            <div
              key={index}
              className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6"
            >
              <div className="flex items-center gap-3 mb-4">
                {section.icon}
                <h2 className="poppins-medium text-xl text-black dark:text-white">
                  {section.title}
                </h2>
              </div>

              <ul className="space-y-3">
                {section.content.map((text, idx) => (
                  <li
                    key={idx}
                    className={`${
                      idx === 0
                        ? "poppins-medium text-black dark:text-stone-300"
                        : "poppins-regular text-gray-600 dark:text-gray-400"
                    }`}
                  >
                    {text}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Last Updated Component */}
        <div className="mt-12 flex justify-center">
          <LastUpdated />
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;