import React from "react";
import { GeneralInput } from "./GeneralInput";

export const InputGroupRow = ({ fields }) => {
  return (
    <div className="flex flex-row w-full items-center gap-2">
      {fields.map(
        ({ label, placeholder, value, onChange, type, required }, index) => (
          <GeneralInput
            key={index}
            labelText={label}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            type={type}
            required={required}
            className="w-full"
          />
        )
      )}
    </div>
  );
};
