import React, { createContext, useContext, useEffect, useState } from "react";

const DistanceUnitContext = createContext();

export const DistanceUnitProvider = ({ children }) => {
  const [isMiles, setIsMiles] = useState(
    () => localStorage.getItem("isMiles") === "true"
  );

  useEffect(() => {
    const handleStorageChange = () => {
      const state = localStorage.getItem("isMiles") === "true";
      setIsMiles(state);
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  useEffect(() => {
    localStorage.setItem("isMiles", isMiles);
  }, [isMiles]);

  return (
    <DistanceUnitContext.Provider value={{ isMiles, setIsMiles }}>
      {children}
    </DistanceUnitContext.Provider>
  );
};

export const useDistanceUnit = () => useContext(DistanceUnitContext);
