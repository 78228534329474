import React from "react";
import { TextBox } from "../General/Inputs/TextBox";
import { CallToActionMainOrangeSmall } from "../General/Buttons/CallToActionMainOrangeSmall";

export const Comment = ({
  handleComment,
  loading = { loading },
  newLocationComment,
  submitStudySpot,
}) => {
  return (
    <div>
      <label className="mb-2 mt-3 lg:mt-2 flex flex-row items-start gap-0.5 text-textLight dark:text-textDark2 poppins-regular text-xs ml-0.5">
        Additional Comments
      </label>

      <TextBox
        value={newLocationComment}
        classNameArea={"min-h-52"}
        onChange={handleComment}
        placeholder="Enter any additional comments"
      />

      <CallToActionMainOrangeSmall
        className={"mt-4 w-full text-center justify-center items-center h-10"}
        onClick={submitStudySpot}
        text={loading ? "PLEASE WAIT" : "Create Study Spot"}
      />
    </div>
  );
};
