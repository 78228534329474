import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useProfile from "../Hooks/useProfile";
import axios from "axios";
import { StepIndicator } from "../Components/General/StepIndicator";
import { NavigationButtons } from "../Components/General/Buttons/NavigationButtons";
import {
  RegisterFormStepOne,
  RegisterFormStepTwo,
  RegisterFormStepThree,
} from "../Components/Profile/RegisterForm";
import { useAlert } from "../Context/AlertContext";
import logoLight from "../Assets/logos/studyspotrlogoorange.svg";

export const Register = () => {
  const { profile, refreshProfile } = useProfile();
  const navigate = useNavigate();
  const { showAlert } = useAlert();

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [instagram, setInstagram] = useState("");
  const [discord, setDiscord] = useState("");
  const [twitter, setTwitter] = useState("");
  const [registerState, setRegisterState] = useState(1);
  const [referral, setReferral] = useState(null);
  useEffect(() => {
    if (profile) navigate("/profile");
  }, [profile]);

  const { referralLink } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (name === "" || username === "" || email === "" || password === "") {
      showAlert("Please fill in all fields.");
      return;
    }

    if (password !== passwordConfirm) {
      showAlert("Passwords do not match!");
      return;
    }

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}/api/auth/register`,
        {
          name,
          username,
          email,
          password,
          linkedIn,
          instagram,
          discord,
          twitter,
          referedLink: referralLink || null,
        }
      );

      // Store token in localStorage
      localStorage.setItem("token", data.token);

      refreshProfile();
      navigate("/");
      window.location.reload();
    } catch (error) {
      showAlert(error.response?.data?.error || "Registration failed.");
    }
  };

  useEffect(() => {
    if (referralLink) {
      const getReferral = async () => {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL}/api/auth/referal`,
          {
            params: {
              referralLink,
            },
          }
        );
        setReferral(data);
        console.log(data);
      };
      getReferral();
    }
  }, [referralLink]);

  const renderStep = () => {
    switch (registerState) {
      case 1:
        return (
          <RegisterFormStepOne
            {...{ name, setName, username, setUsername, email, setEmail }}
          />
        );
      case 2:
        return (
          <RegisterFormStepTwo
            {...{ password, setPassword, passwordConfirm, setPasswordConfirm }}
          />
        );
      case 3:
        return (
          <RegisterFormStepThree
            {...{
              linkedIn,
              setLinkedIn,
              instagram,
              setInstagram,
              discord,
              setDiscord,
              twitter,
              setTwitter,
            }}
          />
        );
      default:
        return null;
    }
  };

  const navigateLogIn = () => {
    navigate("/logIn");
  };

  return (
    <div className="flex bg-backgroundLight dark:bg-backgroundDark text-text flex-col items-center pt-24 lg:pt-0 lg:justify-center h-screen">
      <form
        onSubmit={handleSubmit}
        className="w-10/12 lg:w-1/3 bg-backgroundCardsLight shadow-sm dark:bg-backgroundCardsDark rounded-xl px-4 lg:px-10 pt-10 pb-6 flex flex-col items-center fade-in"
      >
        <img src={logoLight} className="w-8 h-auto mb-4" alt="Logo" />
        <h1 className="poppins-medium text-textLight dark:text-textDark text-2xl text-center w-full">
          Register Now
        </h1>
        <p className="poppins-light text-textLight dark:text-textDark text-xs text-center w-full mt-1.5">
          Please enter your details below to sign up.
        </p>
        {referral && (
          <p className="poppins-light text-mainColorDarkOrange dark:text-mainColorLightOrange text-sm text-center w-full mt-2">
            Referred by {referral}
          </p>
        )}
        {renderStep()}
        <StepIndicator currentStep={registerState} totalSteps={3} />
        <NavigationButtons
          onBack={() => setRegisterState((prev) => Math.max(prev - 1, 1))}
          onNext={() => {
            if (registerState === 3) {
              handleSubmit(new Event("submit")); // Manually trigger form submission for the last step
            } else {
              setRegisterState((prev) => Math.min(prev + 1, 3));
            }
          }}
          backDisabled={registerState === 1}
          isLast={registerState === 3}
        />

        <button
          type="button"
          onClick={navigateLogIn}
          className="text-xs text-textLight dark:text-textDark2 w-full text-center mt-6 poppins-regular"
        >
          Already have an account?{" "}
          <span className="text-mainColorLight dark:text-mainColorDark">
            Log In
          </span>
        </button>
      </form>
    </div>
  );
};
