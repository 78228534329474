import { motion, AnimatePresence } from "framer-motion";
import { CallToActionSecondarySmall } from "../General/Buttons/CallToActionSecondarySmall";
import { CallToActionMainSmall } from "../General/Buttons/CallToActionMainSmall";
import { IoMdClose } from "react-icons/io";

const FilterList = ({
  toggleShowSaved,
  setFilters,
  filters,
  isOpen,
  onClose,
  showSaved,
}) => {
  const tags = [
    "Wifi",
    "Whiteboards",
    "Outlets",
    "ID Required",
    "Food & Drink Allowed",
    "Good for Group Work",
  ];

  const handleTagClick = (tag) => {
    setFilters(
      (prevFilters) =>
        prevFilters.includes(tag)
          ? prevFilters.filter((filter) => filter !== tag) // Remove if already selected
          : [...prevFilters, tag] // Add if not selected
    );
  };

  return (
    <AnimatePresence mode="wait">
      {isOpen && (
        <>
          <motion.div
            className="fixed inset-0 bg-black/30 backdrop-blur-sm z-40"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
          />
          <motion.div
            className="fixed top-36 left-4 bg-backgroundLight dark:bg-backgroundDark rounded-lg shadow-lg p-6 w-11/12 lg:w-1/4 z-50 border border-borderLight dark:border-borderDark"
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
          >
            <div className="flex justify-between items-center mb-5">
              <h3 className="text-lg font-semibold text-textLight dark:text-textDark">
                Filters
              </h3>
              <button
                onClick={onClose}
                className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
                aria-label="Close filters"
              >
                <IoMdClose className="text-textLight dark:text-textDark text-xl" />
              </button>
            </div>

            <div className="mb-4">
              <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-3">
                Features
              </h4>
              <div className="flex flex-wrap gap-2">
                {tags.map((tag) =>
                  filters.includes(tag) ? (
                    <CallToActionMainSmall
                      key={tag}
                      onClick={() => handleTagClick(tag)}
                      text={tag}
                    />
                  ) : (
                    <CallToActionSecondarySmall
                      key={tag}
                      onClick={() => handleTagClick(tag)}
                      text={tag}
                    />
                  )
                )}
              </div>
            </div>

            <div className="mb-2">
              <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-3">
                Collections
              </h4>
              <div>
                {showSaved ? (
                  <CallToActionMainSmall
                    onClick={toggleShowSaved}
                    text={"Saved"}
                  />
                ) : (
                  <CallToActionSecondarySmall
                    onClick={toggleShowSaved}
                    text={"Saved"}
                  />
                )}
              </div>
            </div>

            <div className="flex justify-end mt-6 pt-4 border-t border-gray-200 dark:border-gray-700">
              <CallToActionMainSmall text={"Apply Filters"} onClick={onClose} />
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default FilterList;
