import { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import useSelectLocation from "../../Hooks/useSelectLocation";
import useLocations from "../../Hooks/useLocations";
import { useSavedLocationsContext } from "../../Context/SavedLocationContext";
import useProfile from "../../Hooks/useProfile";
import SpotTag from "../SpotTag";
import useGetDistance from "../../Hooks/useGetDistance";
import { useDistance } from "../../Context/DistanceContext";
import { useViewContext } from "../../Context/ViewContext";
import { useDistanceUnit } from "../../Context/DistanceUnitContext";

export const LocationCard = ({ location }) => {
  const { locations } = useLocations();
  const { selectLocation } = useSelectLocation(locations);
  const { userLocation, getDistanceInMiles } = useGetDistance();
  const { savedLocations } = useSavedLocationsContext();
  const { profile } = useProfile();
  const { getDistance, setDistance } = useDistance();
  const { toggleList } = useViewContext();
  const { isMiles } = useDistanceUnit();

  const [userPremium, setUserPremium] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  const distance = getDistance(location._id);

  useEffect(() => {
    if (profile) {
      setUserPremium(!profile.isPremium);
      setUserLoggedIn(true);
    } else {
      setUserPremium(false);
      setUserLoggedIn(false);
    }
  }, [profile]);

  useEffect(() => {
    // console.log(userLocation, location.location, distance);
    if (distance === null && userLocation && location.location) {
      // calculate distance in meters using Haversine formula
      const R = 6371e3; // Earth's radius in meters
      const φ1 = (userLocation.lat * Math.PI) / 180; // latitude 1 in radians
      const φ2 = (location.location.lat * Math.PI) / 180; // latitude 2 in radians
      const Δφ = ((location.location.lat - userLocation.lat) * Math.PI) / 180;
      const Δλ = ((location.location.long - userLocation.lng) * Math.PI) / 180;

      const a =
        Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      const calculatedDistance = +(R * c).toFixed(2); // distance in meters
      setDistance(location._id, calculatedDistance);
    }
  }, [
    userLocation,
    location.location,
    distance,
    getDistanceInMiles,
    setDistance,
  ]);

  const isSaved = savedLocations.some(
    (savedLocation) => savedLocation._id === location._id
  );

  const tags = [
    location.wifi && { label: "Wifi" },
    location.whiteboards && { label: "Whiteboards" },
    location.outlets && { label: "Outlets" },
    location.IDRequired && { label: "ID Required" },
    location.foodDrink && { label: "Food & Drink Allowed" },
    location.groupWork && { label: "Good for Group Work" },
  ].filter(Boolean);

  const formatDistance = (distanceInMeters) => {
    if (distanceInMeters === null) {
      return "Loading Distance...";
    } else if (distanceInMeters < 1000) {
      return `${distanceInMeters} meters`;
    } else {
      return `${(distanceInMeters / 1000).toFixed(1)}km`;
    }
  };

  return (
    <button
      onClick={() => selectLocation(location)}
      className="w-full flex flex-row gap-4 justify-between bg-backgroundLight dark:bg-backgroundDark border-b border-borderLight dark:border-borderDark h-max py-5 px-5"
    >
      <div className="w-full flex text-left flex-col gap-1">
        <h1 className="poppins-medium text-base lg:text-lg text-textLight dark:text-textDark">
          {location.name}
        </h1>

        <div className="poppins-regular text-xs lg:text-sm text-textLight2 dark:text-text-textDark2 flex flex-row items-center gap-2">
          <p className="poppins-medium flex flex-row gap-0.5 items-center">
            {location.rating}
            <FaStar
              size={11}
              className="text-mainColorLightOrange dark:text-mainColorDarkOrange2 -mt-0.5"
            />
          </p>
          <div className="w-1 h-1 bg-backgroundLight3 dark:bg-backgroundDark3 rounded-full"></div>
          {distance !== null ? (
            <div className="poppins-regular text-xs">
              {formatDistance(distance)} away
            </div>
          ) : (
            <h1 className="poppins-regular text-xs text-textLight2 dark:text-textDark2">
              Loading Distance...
            </h1>
          )}
        </div>

        <div className="flex flex-wrap gap-x-2 gap-y-2 mt-1">
          {tags.map((tag, index) => (
            <SpotTag
              key={tag.label}
              label={tag.label}
              isLast={index === tags.length - 1} // Pass `isLast` as true for the last tag
            />
          ))}
          {isSaved && userLoggedIn && (
            <div className="flex flex-row items-center gap-2">
              <div className="w-1 h-1 bg-backgroundLight3 dark:bg-backgroundDark3 rounded-full"></div>
              <p className="poppins-regular text-xs text-mainColorLightOrange dark:text-mainColorDarkOrange2">
                Saved
              </p>
            </div>
          )}
        </div>
      </div>
    </button>
  );
};
