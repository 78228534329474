import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import defaultProfilePhoto from "../../Assets/defaultProfilePhoto.jpg";
import useProfile from "../../Hooks/useProfile";
import { TextBox } from "../General/Inputs/TextBox";
import { useParams } from "react-router-dom";
import { ConfirmAlertGeneral } from "../General/Alerts/ConfirmAlertGeneral";
import { EditDeleteModel } from "../General/Models/EditDeleteModel";
import { FiStar } from "react-icons/fi";
import { FaStar } from "react-icons/fa";
import { ShowUserInfo } from "../Community/ShowUserInfo";
import { CallToActionSecondarySmall } from "../General/Buttons/CallToActionSecondarySmall";
import { CallToActionMainSmall } from "../General/Buttons/CallToActionMainSmall";

export const ReviewCard = ({ initialComment, spotId }) => {
  const [comment, setComment] = useState(initialComment);
  const [newCommentEditing, setNewCommentEditing] = useState("");
  const [newRatingEditing, setNewRatingEditing] = useState(0);
  const { groupId, postId } = useParams();
  const [userInfo, setUserInfo] = useState(null);
  const { profile } = useProfile();
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState(null);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [openEditDeleteModule, setOpenEditDeleteModule] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    setNewCommentEditing(comment?.comment);
  }, [isEditing, comment]);

  const getPoster = async () => {
    try {
      if (comment && comment.userId) {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL}/locations/getPoster/${comment.userId}`,
          { withCredentials: true }
        );

        if (data?.userInfo) {
          setUserInfo(data.userInfo);
        }
      }
    } catch (error) {
      console.error("Getting poster details failed", error);
    }
  };

  useEffect(() => {
    getPoster();
  }, [comment.createdById, profile]);

  useEffect(() => {
    setNewRatingEditing(comment.rating);
  }, [comment]);

  const getTimeSincePost = (timestamp) => {
    const now = new Date();
    const postDate = new Date(timestamp);
    const diffInSeconds = Math.floor((now - postDate) / 1000);

    if (diffInSeconds < 60) {
      return `${diffInSeconds}s`;
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
      return `${diffInMinutes}m`;
    }

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
      return `${diffInHours}h`;
    }

    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 365) {
      return `${diffInDays}d`;
    }

    const diffInYears = Math.floor(diffInDays / 365);
    return `${diffInYears}${diffInYears === 1 ? "y" : "y"}`;
  };

  const handleEditResponse = async () => {
    const userId = profile._id;
    const newComment = newCommentEditing;
    const newRating = newRatingEditing;

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_URL}/locations/edit/${spotId}/${initialComment._id}`,
        {
          newComment,
          newRating,
        }
      );

      if (response.data) {
        setComment(response.data.data);
      }

      setNewCommentEditing("");
      setNewRatingEditing(0);
      setIsEditing(false);
      // this.comment = response.data.response;
    } catch (error) {
      console.error(
        "Error editing comment:",
        error.response?.data || error.message
      );
    }
  };

  const handleDeleteResponse = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_URL}/locations/delete/${spotId}/${initialComment._id}`,
        {
          data: {
            userId: profile._id,
          },
        }
      );

      if (response.data) {
        window.location.reload();
      }
    } catch (error) {
      console.error(
        "Error deleting comment:",
        error.response?.data || error.message
      );
    }
  };

  return (
    <>
      {isDeleting && (
        <div className="fixed top-0 left-0 z-50">
          <ConfirmAlertGeneral
            setCancel={() => setIsDeleting(false)}
            setConfirm={() => handleDeleteResponse()}
            title={"Deleting Comment"}
            text={`You are about to delete your comment! Once you do this it can not be undone, all of the content will be permentatly gone for yourself and all other members.`}
            confirmButtonText="Delete Comment"
          />
        </div>
      )}
      {showConfirmAlert && (
        <div className="fixed top-0 left-0 z-50">
          {/* <ConfirmAlertGeneral
            setCancel={() => setShowConfirmAlert(false)}
            setConfirm={() => joinGroup()}
            title={"Join Study Group"}
            text={
              "You must join the study group before you can post in this discussion forum! "
            }
            confirmButtonText="Join Group"
          /> */}
        </div>
      )}
      <div
        key={comment.id}
        className={`rounded-lg mb-2 bg-backgroundCardsLight dark:bg-backgroundCardsDark px-5 py-3`}
      >
        <div
          className={`flex flex-row justify-between items-center ${
            comment?.userId != profile?._id && "mb-1"
          }`}
        >
          {userInfo ? (
            <div className="flex items-center gap-2.5">
              <ShowUserInfo
                username={userInfo.username}
                profilePhoto={userInfo.profilePhoto || defaultProfilePhoto}
                date={
                  <p className="text-xs text-textLight2 dark:text-textDark2">
                    {getTimeSincePost(comment.timeStamp)}
                  </p>
                }
              />
              <div className="block w-1 h-1 bg-backgroundLight3 dark:bg-backgroundDark3 rounded-full"></div>
              <div className="flex gap-1 -ml-1">
                {Array(comment.rating)
                  .fill()
                  .map((_, index) => (
                    <FaStar
                      key={index}
                      size={10}
                      className="text-mainColorLightOrange dark:text-mainColorDarkOrange2"
                    />
                  ))}
              </div>
            </div>
          ) : (
            <p>Loading user info...</p>
          )}
          {comment?.userId == profile?._id && (
            <EditDeleteModel
              setIsEditing={setIsEditing}
              setIsDeleting={setIsDeleting}
              setOpenEditDeleteModule={setOpenEditDeleteModule}
              openEditDeleteModule={openEditDeleteModule}
            />
          )}
        </div>

        <div
          className={`poppins-regular text-sm flex flex-col justify-start text-textLight  dark:text-textDark ml-9`}
        >
          <span className="text-textLight dark:text-textDark"></span>{" "}
          {isEditing && (
            <div>
              <label className="mb-2 flex flex-row items-start gap-0.5 text-textLight dark:text-textDark2 poppins-regular text-xs ml-0.5">
                New Rating
                <p className="text-mainColorLightOrange dark:text-mainColorDarkOrange">
                  *
                </p>
              </label>
              <div className="space-y-1 lg:space-y-4 mb-3">
                <div className="flex gap-2 lg:gap-3">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <button
                      key={star}
                      onClick={() => setNewRatingEditing(star)}
                      className={`flex-1 aspect-square rounded-2xl transition-all ${
                        star <= newRatingEditing
                          ? "bg-gradient-to-br from-mainColorLight to-mainColorDark dark:to-backgroundDark3 text-white"
                          : "bg-backgroundCardsLight dark:bg-backgroundCardsDark text-gray-400 hover:bg-gray-200"
                      }`}
                    >
                      <div className="flex flex-col items-center justify-center h-full">
                        <span className="text-base lg:text-xl">★</span>
                        <span className="text-xs lg:mt-1">{star}/5</span>
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          )}
          {isEditing ? (
            <div className={`flex flex-col items-end gap-4 mb-2`}>
              <TextBox
                classNameArea={"min-h-32"}
                placeholder={comment.comment}
                value={newCommentEditing}
                onChange={(e) => setNewCommentEditing(e.target.value)}
                onBlur={(e) => {
                  if (
                    !e.relatedTarget ||
                    e.relatedTarget.innerText !== "Comment"
                  ) {
                  }
                }}
              />
            </div>
          ) : (
            comment.comment
          )}
          {isEditing && (
            <div className="flex ml-auto flex-row items-start gap-2">
              <CallToActionSecondarySmall
                text="Cancel"
                className={"fadeIn"}
                onClick={() => {
                  setIsEditing(false);
                }}
              />
              <CallToActionMainSmall
                text="Confirm"
                className={"fadeIn"}
                onClick={handleEditResponse}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
