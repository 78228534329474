import React, { useState, useEffect } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { AnimatePresence, motion } from "framer-motion";
import useProfile from "../../Hooks/useProfile";
import AlertPopUp from "../General/Alerts/AlertPopUp";
import { ProfilePageCard } from "./ProfilePageCard";
import crownIcon from "../../Assets/crownBadge.png";
import { CallToActionSecondarySmall } from "../General/Buttons/CallToActionSecondarySmall";
import { CallToActionMainOrangeSmall } from "../General/Buttons/CallToActionMainOrangeSmall";
import { CallToActionMainSmall } from "../General/Buttons/CallToActionMainSmall";
import { FaGift, FaQuestion } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export const Points = () => {
  const { profile } = useProfile();
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [activePoints, setActivePoints] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (profile) {
      setActivePoints(profile.activePoints || 0);
      setTotalPoints(profile.totalPoints || 0);
    }
  }, [profile, activePoints]);

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  // Ensure we don't divide by 0
  const progressWidth =
    totalPoints > 0 ? (activePoints / totalPoints) * 100 : 0;

    const navigatePointsPage = () => {
      navigate("/points");
    };

  return (
    <>
      <AnimatePresence>
        {showAlert && message && (
          <AlertPopUp
            icon={<FiAlertCircle />}
            message={message}
            onClose={handleCloseAlert}
          />
        )}
      </AnimatePresence>

      <ProfilePageCard>
        <div className="flex flex-row justify-between">
          <h2 className="poppins-medium text-black dark:text-stone-300 text-lg">
            Points
          </h2>
        </div>

        {profile && (
          <div className="flex flex-col gap-2">
            <div className="flex flex-col">
              <p className="poppins-regular text-textLight2 dark:text-textDark2 text-sm">
                Total Accumulated Points: {totalPoints}
              </p>
            </div>

            <div className="flex flex-col mt-1">
              <div className="flex flex-row items-end gap-2">
                <div className="w-full bg-gray-200 rounded-lg h-5 dark:bg-backgroundCardsDark2 mb-2">
                  <motion.div
                    initial={{ width: "0%" }}
                    animate={{
                      width: `${Math.min(
                        100,
                        (activePoints / totalPoints) * 5
                      )}%`,
                    }}
                    transition={{ duration: 0.8, ease: "easeOut" }}
                    className="h-5 bg-mainColorLight rounded-lg dark:bg-mainColorDark"
                  />
                </div>
                {/* <img className="w-8 h-8" src={crownIcon} /> */}
              </div>
              <p className="poppins-medium text-textLight dark:text-textDark text-xs">
                Active Points: {activePoints}
              </p>
            </div>

            <div className="flex flex-row items-center mt-2">
              {/* <button
                className="flex poppins-medium z-40 pr-3 pt-2 hover:text-textDark transition-all ease-in-out duration-300 text-textDark2 w-max text-xs flex-row items-center gap-2"
                // onClick={backToStudyGroups}
              >
                <FaGift size={12} /> Redeem Points
              </button> */}

              <button
                onClick={navigatePointsPage}
                className="flex poppins-medium z-40 pt-2 hover:text-textDark transition-all ease-in-out duration-300 text-textDark2 w-max text-xs flex-row items-center gap-2"
              >
                <FaQuestion size={12} /> How do I get points
              </button>
            </div>
          </div>
        )}
      </ProfilePageCard>
    </>
  );
};
