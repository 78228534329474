import defaultProfilePhoto from "../../Assets/defaultProfilePhoto.jpg";

export const ShowUserInfo = ({ profilePhoto, username, date }) => {
  return (
    <div className="flex items-center gap-2.5">
      <img
        src={profilePhoto || defaultProfilePhoto}
        alt={username || "Default Profile"}
        className={`${
          date ? "w-6 h-6" : "w-5 h-5"
        } rounded-full inline object-cover object-center`}
      />
      <div className="flex flex-row items-center gap-1.5">
        {username && <span className="text-sm poppins-medium">{username}</span>}
        <div className="block w-1 h-1 bg-backgroundLight3 dark:bg-backgroundDark3 rounded-full"></div>
        {date && <span className="text-xs poppins-medium">{date}</span>}
      </div>
    </div>
  );
};
