export const TextBox = ({
  labelText,
  placeholder,
  value,
  classNameArea,
  onChange,
  type = "text",
  className,
  required = false,
  onFocus,
  onBlur,
}) => {
  return (
    <div className={`flex flex-col ${className && className} w-full`}>
      {labelText && (
        <label
          htmlFor={labelText}
          className="mb-1.5 flex flex-row items-start gap-0.5 text-textLight dark:text-textDark2 poppins-regular text-xs ml-0.5"
        >
          {labelText}
          {required && (
            <p className="text-mainColorLightOrange dark:text-mainColorDarkOrange">
              *
            </p>
          )}
        </label>
      )}
      <textarea
        id={labelText}
        type={type}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        className={`px-3.5 ${
          classNameArea ? classNameArea : "min-h-8"
        } scrollbar-hide py-2.5 poppins-regular text-sm w-full border border-borderLight dark:border-borderDark bg-backgroundCardsLight dark:bg-backgroundCardsDark rounded-md text-textLight dark:text-textDark focus:outline-none focus:ring-2 focus:ring-backgroundDark`}
      />
    </div>
  );
};
