import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { FaCircle } from "react-icons/fa";
import { CallToActionSecondarySmall } from "../Buttons/CallToActionSecondarySmall";
import { CallToActionMainSmall } from "../Buttons/CallToActionMainSmall";
import { FaPencil, FaTrashCan } from "react-icons/fa6";

export const EditDeleteModel = ({
  setIsEditing,
  setIsDeleting,
  setOpenEditDeleteModule,
  openEditDeleteModule,
  customClass,
}) => {
  // Framer Motion animation variants
  const menuVariants = {
    hidden: { opacity: 0, y: -10 }, // Hidden state (slide up)
    visible: { opacity: 1, y: 0 }, // Visible state (dropped down)
    exit: { opacity: 0, y: -10 }, // Exit state (slide back up)
  };

  return (
    <div className="relative z-40">
      {/* Trigger button */}
      <button
        onClick={() => setOpenEditDeleteModule(!openEditDeleteModule)}
        className="flex flex-row gap-0.5 items-center w-8 h-8 justify-center rounded-full lg:hover:bg-borderLight dark:lg:hover:bg-borderDark transition-all duration-500 ease-in-out"
      >
        <FaCircle size={3} className="text-textLight2 dark:text-textDark2" />
        <FaCircle size={3} className="text-textLight2 dark:text-textDark2" />
        <FaCircle size={3} className="text-textLight2 dark:text-textDark2" />
      </button>

      <AnimatePresence>
        {openEditDeleteModule && (
          <motion.div
            key="editDeleteMenu"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={menuVariants}
            transition={{ duration: 0.3, ease: "easeInOut" }} // Adjust animation speed
            className={`absolute ${customClass} top-full dark:bg-backgroundCardsDark bg-backgroundCardsLight p-4 rounded-xl right-0 w-max items-end flex flex-col gap-2`}
          >
            <CallToActionSecondarySmall
              text={"Edit"}
              onClick={() => {
                setIsEditing(true);
                setOpenEditDeleteModule(false);
              }}
              icon={<FaPencil />}
            />
            <CallToActionSecondarySmall
              text={"Delete"}
              onClick={() => {
                setIsDeleting(true);
                setOpenEditDeleteModule(false);
              }}
              icon={<FaTrashCan />}
            />
            <CallToActionMainSmall
              text={"Close"}
              onClick={() => setOpenEditDeleteModule(false)}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
