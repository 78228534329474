import { FiArrowLeft, FiClock } from "react-icons/fi";
import { useRatingStars } from "../../Hooks/useRatingStars";
import { ExpandableImage } from "../ExpandableImage";
import { LocationInfo } from "./LocationInfo";
import TravelButton from "./TravelButton";
import { useEffect, useState } from "react";
import DragAndDrop from "../Add/DragAndDrop";
import {
  FaBiking,
  FaBookmark,
  FaCar,
  FaCarAlt,
  FaChalkboard,
  FaInfoCircle,
  FaLayerGroup,
  FaLeaf,
  FaLightbulb,
  FaNotEqual,
  FaPlug,
  FaRegBookmark,
  FaRegIdCard,
  FaRegLightbulb,
  FaUser,
  FaUsers,
  FaVolumeDown,
  FaVolumeMute,
  FaVolumeOff,
  FaVolumeUp,
  FaWalking,
  FaWifi,
} from "react-icons/fa";
import { useSavedLocationsContext } from "../../Context/SavedLocationContext";
import { useRoutesContext } from "../../Context/RoutesContext";
import useProfile from "../../Hooks/useProfile";
import RatingLocation from "./RatingLocation";
import SpotComments from "./SpotComments";
import { useViewContext } from "../../Context/ViewContext";
import { Feature } from "./Feature";
import { FaVolumeHigh, FaVolumeLow } from "react-icons/fa6";
import { Time } from "./Time";
import { SliderBlock } from "./SliderBlock";
import { CallToActionMainSmall } from "../General/Buttons/CallToActionMainSmall";
import { ReviewCard } from "../Add/ReviewCard";
import { EditDeleteModel } from "../General/Models/EditDeleteModel";
import { CallToActionSecondarySmall } from "../General/Buttons/CallToActionSecondarySmall";
import { GeneralInput } from "../General/Inputs/GeneralInput";
import { TimeInput } from "../Add/TimeInput";
import { FeatureSelector } from "../Add/FeatureSelector";
import Slider from "../Add/Slider";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const SelectedLocationCard = ({ location, deselectLocation }) => {
  const optionsTravel = [
    { id: 1, mode: "walking", icon: <FaWalking /> },
    { id: 2, mode: "driving", icon: <FaCar /> },
    { id: 3, mode: "cycling", icon: <FaBiking /> },
  ];

  const options = [
    { id: 1, label: "Silent", icon: <FaVolumeMute /> },
    { id: 2, label: "Quiet", icon: <FaVolumeOff /> },
    { id: 3, label: "Moderate", icon: <FaVolumeDown /> },
    { id: 4, label: "Lively", icon: <FaVolumeHigh /> },
  ];

  const optionsTables = [
    { id: 1, label: "Individual", icon: <FaUser size={10} /> },
    { id: 2, label: "Shared", icon: <FaUsers /> },
    { id: 3, label: "Mixed", icon: <FaLayerGroup size={11} /> },
  ];

  const optionsLighting = [
    { id: 1, label: "Bright", icon: <FaLightbulb /> },
    { id: 2, label: "Natural", icon: <FaLeaf /> },
    { id: 3, label: "Dim", icon: <FaRegLightbulb /> },
  ];

  const stars = useRatingStars(location?.rating || 0);
  const { savedLocations, save, unsave } = useSavedLocationsContext();
  const { time, setTime, transportType, setTransportType } = useRoutesContext();
  const [isRatingOpen, setIsRatingOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const { profile } = useProfile();
  const { toggleMap, toggleList, view } = useViewContext();
  const [editingSpot, setEditingSpot] = useState(false);
  const [editingSpotName, setEditingSpotName] = useState(location.name || "");
  const [openEditDeleteModule, setOpenEditDeleteModule] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [file, setFile] = useState(null);
  const [newLocationOpenHours, setNewLocationOpenHours] = useState(null);
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [newLocationNoise, setNewLocationNoise] = useState(
    options.findIndex((option) => option.label === location?.noiseLevel) || 0
  );

  const [newLocationTables, setNewLocationTables] = useState(
    optionsTables.findIndex((option) => option.label === location?.tables) || 0
  );

  const [newLocationLighting, setNewLocationLighting] = useState(
    optionsLighting.findIndex(
      (option) => option.label === location?.lighting
    ) || 0
  );

  useEffect(() => {
    const isEditPage = window.location.pathname.includes("/edit");
    if (isEditPage) {
      setIsEditing(true);
    }
  }, []);

  const convertTo24Hour = (time) => {
    let [hours, minutes] = time.split(":");
    let modifier = time.includes("PM") ? "PM" : "AM";

    hours = parseInt(hours, 10);

    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    } else if (modifier === "AM" && hours === 12) {
      hours = 0;
    }

    return `${hours.toString().padStart(2, "0")}:${minutes.replace(
      /( AM| PM)/,
      ""
    )}`;
  };

  const [startTime, endTime] = location.openHours
    ?.split(" - ")
    .map(convertTo24Hour) || ["06:00", "16:30"];

  const isSaved =
    profile &&
    savedLocations.some((savedLocation) => savedLocation._id === location._id);

  const openRatingModal = () => {
    setIsRatingOpen(true);
  };

  const closeRatingModal = () => {
    setIsRatingOpen(false);
  };

  const handleRating = (rate) => {
    setRating(rate);
  };

  const editSpot = () => {
    setEditingSpot(!editingSpot);
    setEditingSpotName(location.name);
  };

  const handleSpotNameChange = (e) => {
    setEditingSpotName(e.target.value);
  };

  const [features, setFeatures] = useState([
    { icon: <FaRegIdCard />, label: "ID Required", value: location.IDRequired },
    {
      icon: <FaChalkboard />,
      label: "Whiteboards",
      value: location.whiteboards,
    },
    { icon: <FaWifi size={12} />, label: "Wifi", value: location.wifi },
    { icon: <FaPlug size={12} />, label: "Outlets", value: location.outlets },
    {
      icon: <FaUsers size={12} />,
      label: "Good for Group Work",
      value: location.groupWork,
    },
    {
      icon: <FaUsers size={12} />,
      label: "Food/Drinks Allowed",
      value: location.foodDrink,
    },
  ]);

  const toggleFeature = (index) => {
    setFeatures((prevFeatures) =>
      prevFeatures.map((feature, i) =>
        i === index ? { ...feature, value: !feature.value } : feature
      )
    );
  };

  const formatTime = (time) => {
    if (!time) return "";
    const [hours, minutes] = time.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = ((hours + 11) % 12) + 1; // Convert to 12-hour format
    return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${period}`;
  };

  useEffect(() => {
    setNewLocationOpenHours(`${formatTime(fromTime)} - ${formatTime(toTime)}`);
  }, [fromTime, toTime]);

  // If not editing, sort: true features first, then false
  const displayFeatures = isEditing
    ? features // Keep order while editing
    : [...features].sort((a, b) => b.value - a.value);

  const editLocation = async () => {
    // Create FormData for file upload
    const formData = new FormData();

    // Add changed text fields to the form data
    if (editingSpotName !== location.name) {
      formData.append("name", editingSpotName);
    }

    if (newLocationOpenHours) {
      formData.append("openHours", newLocationOpenHours);
    }

    // Add updated feature values
    // features.forEach((feature) => {
    //   if (feature.label === "ID Required") {
    //     formData.append("IDRequired", feature.value);
    //   } else if (feature.label === "Whiteboards") {
    //     formData.append("whiteboards", feature.value);
    //   } else if (feature.label === "Wifi") {
    //     formData.append("wifi", feature.value);
    //   } else if (feature.label === "Outlets") {
    //     formData.append("outlets", feature.value);
    //   } else if (feature.label === "Good for Group Work") {
    //     formData.append("groupWork", feature.value);
    //   } else if (feature.label === "Food/Drinks Allowed") {
    //     formData.append("foodDrink", feature.value);
    //   }
    // });

    features.forEach((feature) => {
      formData.append(feature.label.replace(/\s+/g, ""), feature.value);
    });

    // Add slider selections
    if (
      newLocationNoise !==
      options.findIndex((option) => option.label === location.noiseLevel)
    ) {
      formData.append("noiseLevel", options[newLocationNoise].label);
    }

    if (
      newLocationTables !==
      optionsTables.findIndex((option) => option.label === location.tables)
    ) {
      formData.append("tables", optionsTables[newLocationTables].label);
    }

    if (
      newLocationLighting !==
      optionsLighting.findIndex((option) => option.label === location.lighting)
    ) {
      formData.append("lighting", optionsLighting[newLocationLighting].label);
    }

    // Append image file if it exists
    if (file) {
      formData.append("image", file);
    }

    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_URL}/locations/updateSpot/${location._id}`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (data) {
        setIsEditing(false);
        setMessage("");
        window.location.reload();
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setMessage(error.response.data.message);
        setShowAlert(true);
      } else {
        setMessage("Spot update failed. Please try again.");
        setShowAlert(true);
      }
    }
  };

  return (
    <>
      {isRatingOpen && (
        <RatingLocation location={location} cancelRating={closeRatingModal} />
      )}
      <div className="fade-in z-40 px-4 py-4 h-full max-h-screen flex flex-col">
        <div className="bg-backgroundLight dark:bg-backgroundDark text-sm poppins-regular flex flex-col gap-1 rounded-xl mb-4">
          <div className="flex flex-row justify-between">
            <button
              onClick={deselectLocation}
              className="flex text-black text-sm dark:text-stone-400 flex-row items-center gap-1"
            >
              <FiArrowLeft /> Back
            </button>
            <div className="flex flex-row items-center gap-2">
              {profile && (
                <div className="transition ease-in-out duration-300">
                  {isSaved ? (
                    <button
                      onClick={() => unsave(location)}
                      className="flex flex-row transition ease-in-out duration-300 items-center gap-1"
                    >
                      <p className="px-3 flex flex-row items-center dark:text-stone-400 transition ease-in-out duration-300 gap-1 py-1.5 poppins-regular text-xs bg-backgroundCardsLight dark:bg-backgroundCardsDark text-black rounded-full">
                        <FaBookmark size={10} /> Unsave
                      </p>
                    </button>
                  ) : (
                    <button
                      onClick={() => save(location)}
                      className="flex flex-row items-center transition ease-in-out duration-300 gap-1"
                    >
                      <p className="px-3 flex flex-row items-center dark:text-stone-400 transition ease-in-out duration-300 gap-1 py-1.5 poppins-regular text-xs bg-backgroundCardsLight dark:bg-backgroundCardsDark2 text-black rounded-full">
                        <FaRegBookmark size={10} /> Save
                      </p>
                    </button>
                  )}
                </div>
              )}

              <button
                onClick={toggleMap}
                className="flex lg:hidden flex-row transition ease-in-out duration-300 items-center gap-1"
              >
                <p className="px-4 flex flex-row bg-gray-200 dark:dark:bg-stone-800 dark:text-stone-200  transition ease-in-out duration-300 items-center gap-1 py-1.5 poppins-regular text-xs text-black rounded-full ">
                  Directions
                </p>
              </button>
            </div>
          </div>

          {isEditing && (
            <div className="flex flex-row items-center justify-between mt-3">
              <CallToActionSecondarySmall
                text={"Cancel"}
                onClick={() => {
                  setIsEditing(false);
                  navigate(`/${location._id}`);
                }}
              />
              <CallToActionMainSmall
                text={"Confirm Edits"}
                onClick={editLocation}
              />
            </div>
          )}

          <div className="flex flex-row justify-between items-center mt-2">
            <div className="flex gap-1 mt-2 flex-row items-center">
              {stars} <span className="">({location.ratingCount})</span>{" "}
              {profile && location.createdById !== profile._id && (
                <button
                  onClick={openRatingModal}
                  className="text-mainColorLightOrange dark:text-mainColorDarkOrange poppins-medium text-xs ml-1"
                >
                  Rate this Spot
                </button>
              )}
            </div>
            {location?.createdById == profile?._id && (
              <EditDeleteModel
                setIsEditing={setIsEditing}
                setIsDeleting={setIsDeleting}
                setOpenEditDeleteModule={setOpenEditDeleteModule}
                openEditDeleteModule={openEditDeleteModule}
              />
            )}
          </div>
          {isEditing ? (
            <GeneralInput
              labelText={"Spot Name"}
              required={true}
              value={editingSpotName}
              onChange={(e) => setEditingSpotName(e.target.value)}
              className={"mb-1"}
            />
          ) : (
            <h1 className=" text-textLight dark:text-textDark poppins-semibold text-lg lg:text-2xl mt-0.5">
              {location.name}
            </h1>
          )}
          <p className="poppins-regular text-xs text-textLight2 dark:text-textDark2">
            {location.spotLocationInfo.city}, {location.spotLocationInfo.region}
            , {location.spotLocationInfo.country}
          </p>
        </div>

        <div className="flex-1 -mt-2 overflow-y-scroll scrollbar-hide pb-60">
          {isEditing ? (
            <>
              <DragAndDrop
                previewUrlPass={location.imageUrl}
                file={file}
                setFile={setFile}
              />
              <div className="grid xl:grid-cols-2 gap-2 lg:gap-2 mt-4">
                <TimeInput
                  label="Opens at"
                  defaultTime={startTime}
                  isOpen={true}
                  onTimeChange={setFromTime}
                />
                <TimeInput
                  label="Closes at"
                  defaultTime={endTime}
                  isOpen={false}
                  onTimeChange={setToTime}
                />
              </div>
            </>
          ) : (
            <>
              <ExpandableImage selectedLocation={location} />
              <div className="grid xl:grid-cols-2 gap-2 lg:gap-2 mt-4">
                <Time
                  label="Opens at"
                  defaultTime={location.openHours}
                  isOpen={true}
                  staticTime={true}
                />
                <Time
                  label="Closes at"
                  defaultTime={location.openHours}
                  isOpen={false}
                  staticTime={true}
                />
              </div>
            </>
          )}

          {/* Location Info */}
          <div className="grid xl:grid-cols-2 gap-2 lg:gap-2 mt-4">
            {displayFeatures.map((feature, index) =>
              isEditing ? (
                <FeatureSelector
                  key={index}
                  icon={feature.icon}
                  label={feature.label}
                  isSelected={feature.value}
                  onToggle={() => toggleFeature(index)}
                />
              ) : (
                <Feature
                  key={index}
                  icon={feature.icon}
                  label={feature.label}
                  isSelected={feature.value}
                />
              )
            )}
          </div>

          <div
            className={`grid ${
              isEditing
                ? "xl:grid-rows-3"
                : "xl:grid-cols-3 gap-2 lg:gap-2 mt-4"
            }`}
          >
            {isEditing ? (
              <div className="mt-3">
                <label className="mb-1.5 flex flex-row items-start gap-0.5 text-textLight dark:text-textDark2 poppins-regular text-xs ml-0.5">
                  Noise Level
                  <p className="text-mainColorLightOrange dark:text-mainColorDarkOrange">
                    *
                  </p>
                </label>
                <Slider
                  options={options}
                  setSelected={setNewLocationNoise}
                  selected={newLocationNoise}
                />
              </div>
            ) : (
              <div>
                <label className="mb-1 flex flex-row gap-1 text-textLight dark:text-textDark2 poppins-regular text-xs ml-0.5 items-center">
                  <FaInfoCircle size={10} /> Noise Level:
                </label>
                <SliderBlock
                  option={location.noiseLevel}
                  icon={
                    location.noiseLevel === "Silent" ? (
                      <FaVolumeMute />
                    ) : location.noiseLevel === "Quiet" ? (
                      <FaVolumeOff />
                    ) : location.noiseLevel === "Moderate" ? (
                      <FaVolumeDown />
                    ) : location.noiseLevel === "Lively" ? (
                      <FaVolumeHigh />
                    ) : location.noiseLevel === "" ? (
                      <FaNotEqual />
                    ) : null
                  }
                />
              </div>
            )}

            {isEditing ? (
              <div className="mt-3">
                <label className="mb-1.5 flex flex-row items-start gap-0.5 text-textLight dark:text-textDark2 poppins-regular text-xs ml-0.5">
                  Tables
                  <p className="text-mainColorLightOrange dark:text-mainColorDarkOrange">
                    *
                  </p>
                </label>
                <Slider
                  options={optionsTables}
                  setSelected={setNewLocationTables}
                  selected={newLocationTables}
                />
              </div>
            ) : (
              <div>
                <label className="mb-1 flex flex-row gap-1 text-textLight dark:text-textDark2 poppins-regular text-xs ml-0.5 items-center">
                  <FaInfoCircle size={10} /> Tables:
                </label>
                <SliderBlock
                  option={location.tables}
                  icon={
                    location.tables === "Individual" ? (
                      <FaUser />
                    ) : location.tables === "Shared" ? (
                      <FaUsers />
                    ) : location.tables === "Mixed" ? (
                      <FaLayerGroup />
                    ) : location.tables === "" ? (
                      <FaNotEqual />
                    ) : null
                  }
                />
              </div>
            )}

            {isEditing ? (
              <div className="mt-3">
                <label className="mb-1.5 flex flex-row items-start gap-0.5 text-textLight dark:text-textDark2 poppins-regular text-xs ml-0.5">
                  Lighting
                  <p className="text-mainColorLightOrange dark:text-mainColorDarkOrange">
                    *
                  </p>
                </label>
                <Slider
                  options={optionsLighting}
                  setSelected={setNewLocationLighting}
                  selected={newLocationLighting}
                />
              </div>
            ) : (
              <div>
                <label className="mb-1 flex flex-row gap-1 text-textLight dark:text-textDark2 poppins-regular text-xs ml-0.5 items-center">
                  <FaInfoCircle size={10} /> Lighting:
                </label>
                <SliderBlock
                  option={location.lighting}
                  icon={
                    location.lighting === "Bright" ? (
                      <FaLightbulb />
                    ) : location.lighting === "Natural" ? (
                      <FaLeaf />
                    ) : location.lighting === "Dim" ? (
                      <FaRegLightbulb />
                    ) : location.lighting === "" ? (
                      <FaNotEqual />
                    ) : null
                  }
                />
              </div>
            )}
          </div>

          {!isEditing && (
            <>
              {" "}
              <div className="mt-4">
                <TravelButton
                  currentMode={transportType}
                  setTravelMode={setTransportType}
                  options={optionsTravel}
                />
                <div className="flex flex-row justify-between items-end mt-3">
                  <p className="mb-1 flex flex-row gap-1 text-textLight dark:text-textDark poppins-regular text-xs lg:text-sm ml-0.5 items-center">
                    Estimate Travel Time:{" "}
                    <span className="poppins-medium">{time}</span>
                  </p>
                  <CallToActionMainSmall text={"Open in Maps"} />
                </div>
              </div>
              <div className="flex flex-col mt-4 ml-1">
                <div className="flex flex-col mt-3">
                  <p
                    className={
                      "text-textLight dark:text-textDark2 text-xs mb-2"
                    }
                  >
                    {`Reviews (${location.comments.length})`}
                  </p>

                  <div className="flex flex-col gap-1">
                    {location.comments.length > 0 ? (
                      location.comments.map((comment) => (
                        <ReviewCard
                          initialComment={comment}
                          spotId={location._id}
                        />
                      ))
                    ) : (
                      <p className="poppins-light text-sm text-textLight2 dark:text-textDark2 h-full items-center flex justify-start w-full">
                        {profile
                          ? "No reviews made for this spot yet!"
                          : "Log in to see reviews!"}
                      </p>
                    )}
                  </div>
                  {/* <ReviewCard initialComment={}/> */}
                  {/* <SpotComments location={location} profile={profile} /> */}
                  {/* {location.comments.length >= 1 && (
                  <div className="bg-gray-100 border border-black flex flex-col items-start border-opacity-30 px-4 py-3 h-max rounded-xl mt-4">
                    <p className="text-center poppins-regular">
                      Upgrade to premium to see all comments!
                    </p>
                    <button className="text-orange-500 poppins-medium mt-2">
                      Upgrade Now
                    </button>
                  </div>
                )} */}
                </div>
              </div>{" "}
            </>
          )}
        </div>
      </div>
    </>
  );
};
