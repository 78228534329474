import { useEffect, useState } from "react";
import useProfile from "../Hooks/useProfile";
import { FiAward, FiGift, FiActivity } from "react-icons/fi";
import { FaArrowDown, FaCheckCircle } from "react-icons/fa";

export const PointsPage = () => {
  const { profile } = useProfile();
  const [activePoints, setActivePoints] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0);

  useEffect(() => {
    if (profile) {
      setActivePoints(profile.activePoints || 0);
      setTotalPoints(profile.totalPoints || 0);
    }
  }, [profile]);

  const pointActivities = [
    { activity: "Invite a Friend", points: 50 },
    { activity: "Create a Study Spot", points: 30 },
    { activity: "Add a Review on a Study Spot", points: 20 },
    { activity: "Report a Bug or Issue", points: 10 },
  ];

  return (
    <div className="w-screen pb-10 overflow-y-scroll scrollbar-hide h-screen bg-backgroundLight dark:bg-backgroundDark fade-in pt-12 mt-4 lg:pt-10">
      {/* Hero Section */}
      <div className="relative overflow-hidden bg-gradient-to-b from-backgroundCardsLight to-backgroundLight dark:from-backgroundCardsDark dark:to-backgroundDark py-12 lg:py-20 px-4 lg:px-0">
        <div className="absolute inset-0 bg-gradient-radial from-yellow-400/20 via-transparent to-transparent" />

        <div className="relative z-10 flex flex-col items-center max-w-6xl mx-auto text-center">
          <div className="flex items-center gap-2 bg-white/10 backdrop-blur-sm px-4 py-2 rounded-full mb-6">
            <FiAward className="w-5 h-5 text-yellow-400" />
            <span className="text-sm text-textLight dark:text-textDark">
              Study Spotr Points System
            </span>
          </div>

          <h1 className="poppins-semibold text-4xl lg:text-7xl text-textLight dark:text-textDark">
            Earn. Engage. Redeem.
          </h1>

          <p className="poppins-regular text-sm lg:text-base lg:w-2/5 w-10/12 text-center mt-6 lg:mt-8 text-textLight2 dark:text-textDark2">
            Earn points by engaging in Study Spotr activities and unlock
            exciting rewards. Be active, participate, and start climbing the
            leaderboard today!
          </p>

          <div className="mt-8 dark:text-white px-8 py-4 flex items-center gap-2 ">
            <span className="poppins-medium">Start Earning Points</span>
            <FaArrowDown className="w-4 h-4" />
          </div>

          {/* User Points Overview */}
          <div className="flex flex-col items-center mt-12">
            <FiAward className="text-yellow-400 text-6xl" />
            <h2 className="poppins-medium text-3xl mt-3 text-black dark:text-white">
              Your Points: <span className="text-primary">{activePoints}</span>
            </h2>
            <p className="poppins-regular text-sm text-gray-500 dark:text-gray-400 mt-3 -mb-12">
              Keep participating to earn more points and unlock rewards!
            </p>
          </div>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="px-4 lg:px-20 py-16">
        <div className="max-w-6xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Ways to Earn Points */}
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
            <h3 className="poppins-medium text-2xl text-black dark:text-white mb-4 text-center">
              Ways to Earn Points
            </h3>
            <ul className="space-y-4">
              {pointActivities.map((item, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center border-b pb-3 border-gray-200 dark:border-gray-700 last:border-b-0"
                >
                  <div className="flex items-center gap-3">
                    <FiActivity className="text-primary" />
                    <span className="poppins-regular text-black dark:text-white">
                      {item.activity}
                    </span>
                  </div>
                  <span className="poppins-medium text-primary">
                    {item.points} pts
                  </span>
                </li>
              ))}
            </ul>
          </div>

          {/* How to Redeem Points */}
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 flex flex-col items-center justify-center">
            <h3 className="poppins-medium text-2xl text-black dark:text-white mb-4 text-center">
              How to Redeem Points
            </h3>
            <FiGift className="text-green-500 text-5xl mb-4" />
            <p className="poppins-regular text-sm text-gray-500 dark:text-gray-400 text-center">
              Start earning points and redeem them for rewards such as discounts
              at selected study spots in the future!
            </p>
            {/* <button className="mt-6 bg-primary hover:bg-primary/90 text-white px-6 py-3 rounded-full flex items-center gap-2 transform hover:scale-105 transition-all duration-500 shadow-lg">
              <span className="poppins-medium">Get Notified</span>
              <FaCheckCircle className="w-4 h-4" />
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointsPage;
