import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import axios from "axios";
import StudySpotrLogo from "../Assets/logos/studyspotrlogoorange.svg";
import React from "react";
import { FiDatabase, FiLogOut, FiUser } from "react-icons/fi";
import { RiFileAddLine } from "react-icons/ri";
import {
  FaCog,
  FaList,
  FaMapMarkerAlt,
  FaMapPin,
  FaMoon,
  FaRegMoon,
  FaTimes,
  FaUsers,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../Context/ThemeContext";

const DropdownItem = ({ icon: Icon, label, onClick, image }) => (
  <button
    onClick={onClick}
    className="text-sm w-full text-textLight dark:text-textDark py-2.5 px-7 flex items-center poppins-regular gap-3 lg:hover:bg-gray-100 lg:dark:hover:bg-gray-800 transition duration-300"
  >
    {image ? (
      <img
        src={StudySpotrLogo}
        alt="Study Spotr Logo"
        className="flex w-5 h-5 mt-auto mb-auto mr-1 lg:mr-4"
      />
    ) : (
      Icon && <Icon className="text-textLight2 dark:text-textDark2" size={14} />
    )}
    {label}
  </button>
);

export const SideModal = ({
  isOpen,
  onClose,
  navigateCommunities,
  handleToggleList,
  handleToggleMap,
}) => {
  const navigate = useNavigate();
  const { isDarkMode, toggleDarkMode } = useTheme();

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_URL}/api/auth/logout`);
      localStorage.removeItem("token");
      navigate("/login");
      window.location.reload();
    } catch (error) {
      console.log("Logout error:", error);
    }
  };

  const menuItems = [
    {
      image: StudySpotrLogo,
      action: onClose,
    },
    {
      icon: FaList,
      label: "List - Spots",
      action: () => {
        handleToggleList();
        onClose();
      },
    },
    {
      icon: FaMapMarkerAlt,
      label: "Map - Spots",
      action: () => {
        handleToggleMap();
        onClose();
      },
    },
    // {
    //   icon: FaUsers,
    //   label: "Community",
    //   action: () => {
    //     navigateCommunities();
    //     onClose();
    //   },
    // },
  ];

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          {/* Background Overlay */}
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-80 z-40"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
          />

          {/* Sidebar Modal */}
          <motion.div
            className="fixed top-0 left-0 h-full w-64 bg-backgroundLight dark:bg-backgroundCardsDark shadow-lg z-50 flex flex-col gap-4 pt-6"
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            exit={{ x: "-100%" }}
            transition={{ type: "spring", stiffness: 120, damping: 20 }}
          >
            {menuItems.map((item, index) => (
              <DropdownItem
                key={index}
                icon={item?.icon}
                image={item?.image}
                label={item.label}
                onClick={item.action}
              />
            ))}
            <button
              onClick={onClose}
              className="text-sm mt-auto pb-6 w-full text-textLight dark:text-textDark py-2.5 px-7 flex items-center poppins-regular gap-3 lg:hover:bg-gray-100 lg:dark:hover:bg-gray-800 transition duration-300"
            >
              <FaTimes
                className="text-textLight2 dark:text-textDark2"
                size={14}
              />
              Close
            </button>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};
