import React, { createContext, useContext, useState } from "react";

const RoutesContext = createContext();

export const useRoutesContext = () => useContext(RoutesContext);

export const RoutesContextProvider = ({ children }) => {
  const [time, setTime] = useState("");
  const [transportType, setTransportType] = useState("driving");

  return (
    <RoutesContext.Provider
      value={{
        time,
        setTime,
        transportType,
        setTransportType,
      }}
    >
      {children}
    </RoutesContext.Provider>
  );
};
