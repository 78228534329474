import { FaStar, FaTrash } from "react-icons/fa";
import useSelectLocation from "../../Hooks/useSelectLocation";
import useLocations from "../../Hooks/useLocations";
import { useSavedLocationsContext } from "../../Context/SavedLocationContext";
import SpotTag from "../SpotTag";
import { CallToActionSecondarySmall } from "../General/Buttons/CallToActionSecondarySmall";
import { CallToActionMainSmall } from "../General/Buttons/CallToActionMainSmall";

export const LocationCardProfile = ({
  location,
  locationsCreate,
  deleteLocation,
  isMine,
}) => {
  const { locations } = useLocations();
  const { selectLocation } = useSelectLocation(locations);
  const { savedLocations } = useSavedLocationsContext();

  const isSaved = savedLocations.some(
    (savedLocation) => savedLocation._id === location._id
  );

  const tags = [
    location.wifi && { label: "Wifi" },
    location.whiteboards && { label: "Whiteboards" },
    location.outlets && { label: "Outlets" },
    location.IDRequired && { label: "ID Required" },
    location.silentArea && { label: "Silent Areas" },
  ].filter(Boolean);

  return (
    <div className="  mb-3 rounded-xl bg-backgroundCardsLight dark:bg-backgroundCardsDark border-opacity-30 h-max py-4 px-4 ">
      <div className="flex flex-row items-center justify-between">
        <CallToActionSecondarySmall
          text={"Click to view"}
          onClick={() => selectLocation(location)}
        />
        {isMine && (
          <CallToActionMainSmall
            onClick={() => deleteLocation(location._id)}
            icon={<FaTrash />}
          />
        )}
      </div>
      <div className="w-full flex flex-row gap-4 justify-between mt-3">
        <div className="w-full flex text-left flex-col gap-1">
          <h1 className="poppins-medium text-base lg:text-lg text-textLight dark:text-textDark">
            {location.name}
          </h1>

          <div className="flex flex-wrap gap-x-2 gap-y-2 mt-1">
            <div className="poppins-regular text-xs lg:text-sm text-textLight2 dark:text-text-textDark2 flex flex-row items-center gap-2">
              <p className="poppins-medium flex flex-row gap-0.5 items-center">
                {location.rating}
                <FaStar
                  size={11}
                  className="text-mainColorLightOrange dark:text-mainColorDarkOrange2 -mt-0.5"
                />
              </p>
            </div>
            <div className="w-1 h-1 bg-backgroundLight3 dark:bg-backgroundDark3 rounded-full mt-auto mb-auto"></div>
            {tags.map((tag, index) => (
              <SpotTag
                key={tag.label}
                label={tag.label}
                isLast={index === tags.length - 1}
              />
            ))}
            {isSaved && (
              <div className="flex flex-row items-center gap-2">
                <div className="w-1 h-1 bg-backgroundLight3 dark:bg-backgroundDark3 rounded-full"></div>
                <p className="poppins-regular text-xs text-mainColorLightOrange dark:text-mainColorDarkOrange2">
                  Saved
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
