import React, { useState } from "react";
import { FaDoorClosed, FaDoorOpen } from "react-icons/fa";

export const Time = ({ label, defaultTime, isOpen, staticTime = false }) => {
  const [start, end] = defaultTime?.split(" - ") || ["06:00 AM", "04:30 PM"];
  const [time, setTime] = useState(isOpen ? start : end);
  const [isTimePickerOpen, setTimePickerOpen] = useState(false);

  const timeOptions = Array.from({ length: 48 }, (_, i) => {
    const hour = Math.floor(i / 2)
      .toString()
      .padStart(2, "0");
    const minute = i % 2 === 0 ? "00" : "30";
    return formatTimeDisplay(`${hour}:${minute}`);
  });

  function formatTimeDisplay(timeString) {
    const [hour, minute, period] = timeString.split(/[: ]/);
    const hourNum = parseInt(hour);
    const displayHour = hourNum % 12 || 12;
    const amPm = period || (hourNum >= 12 ? "PM" : "AM");
    return `${displayHour}:${minute} ${amPm}`;
  }

  return (
    <div className="relative">
      <div
        className={`
          flex items-center gap-3 px-3 py-2 rounded-xl
          transition-all duration-300
          bg-backgroundCardsLight dark:bg-backgroundCardsDark
        `}
        onClick={() => setTimePickerOpen(!isTimePickerOpen)}
      >
        <div
          className={`
          p-3 rounded-lg flex items-center justify-center
          ${
            isOpen
              ? "bg-backgroundLight dark:bg-backgroundCardsDark2 text-mainColorDarkOrange"
              : "bg-backgroundLight dark:bg-backgroundCardsDark2 text-mainColorDark"
          }
        `}
        >
          {isOpen ? <FaDoorOpen size={12} /> : <FaDoorClosed size={12} />}
        </div>

        <div className="flex flex-col gap-0">
          <p className="text-xs text-textLight2 dark:text-textDark2 poppins-regular">
            {label}
          </p>
          <p className="text-sm lg:text-base poppins-medium text-textLight dark:text-textDark">
            {time}
          </p>
        </div>
      </div>

      {isTimePickerOpen && !staticTime && (
        <div className="absolute z-20 mt-2 poppins-regular text-textDark2 w-full bg-white dark:bg-backgroundCardsDark scrollbar-hide rounded-xl max-h-60 min-h-60 overflow-y-auto">
          <div className="p-2">
            {timeOptions.map((timeOption) => (
              <button
                key={timeOption}
                className={`w-full px-4 py-2 text-left rounded-lg transition-colors ${
                  time === timeOption
                    ? "bg-backgroundCardsDark2 text-textDark"
                    : "hover:bg-gray-100 dark:hover:bg-backgroundCardsDark2"
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  setTime(timeOption);
                  setTimePickerOpen(false);
                }}
              >
                {timeOption}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
