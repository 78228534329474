import { useUpdatedContext } from "../Context/UpdatedContext";

export const LastUpdated = () => {
  const { date, updateTime } = useUpdatedContext();
  return (
    <div className="mb-6 mt-12 text-center">
      <p className="text-sm poppins-light text-gray-500">
        Last updated: {date}
        {/* <button onClick={updateTime}>Update</button> */}
      </p>
    </div>
  );
};
