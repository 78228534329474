import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import axios from "axios";
import React from "react";
import {
  FiDatabase,
  FiLock,
  FiLogOut,
  FiPlus,
  FiSettings,
  FiUser,
  FiUserPlus,
  FiUsers,
} from "react-icons/fi";
import {
  RiBookMarkedLine,
  RiBookmarkLine,
  RiFileAddLine,
} from "react-icons/ri";
import { useNavigate, useLocation } from "react-router-dom";
import {
  FaBookMedical,
  FaBookOpen,
  FaCog,
  FaMapMarkerAlt,
  FaMoon,
  FaRegMoon,
  FaTimes,
  FaUsers,
} from "react-icons/fa";
import { useTheme } from "../Context/ThemeContext";

const DropdownItem = ({ icon: Icon, label, onClick, isActive }) => (
  <button
    onClick={onClick}
    className={`text-sm text-left w-full text-textLight py-1.5 dark:text-textDark px-6 border-borderLight dark:border-borderDark border-opacity-30 lg:hover:text-mainColorLightOrange lg:dark:hover:text-mainColorLightOrange transition duration-500 ease-in-out poppins-light tracking-wide flex flex-row gap-2 lg:gap-5 items-center`}
  >
    <Icon className={"text-textLight2 dark:text-textDark2"} size={16} />
    {label}
  </button>
);

export const CreateModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isDarkMode, toggleDarkMode } = useTheme();

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_URL}/api/auth/logout`);
      localStorage.removeItem("token");
      navigate("/login");
      window.location.reload();
    } catch (error) {
      console.log("Logout error:", error);
    }
  };

  const menuItems = [
    {
      icon: FaMapMarkerAlt,
      label: "Create a Study Spot",
      action: () => {
        navigate("/addStudySpot");
        onClose();
      },
      path: "/profile",
    },
    // {
    //   icon: FaUsers,
    //   label: "Create a Study Group",
    //   action: () => {
    //     navigate("/community/createastudygroup");
    //     onClose();
    //   },
    //   path: "/privacy",
    // },
    // {
    //   icon: FaBookOpen,
    //   label: "Create a Study Session",
    //   action: () => {
    //     navigate("/community/createastudysession");
    //     onClose();
    //   },
    //   path: "/created-spots",
    // },
  ];
  return (
    <AnimatePresence>
      {isOpen && (
        <>
          {/* Background Overlay */}
          <motion.div
            className="fixed inset-0 z-40"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
          />

          <motion.div
            className="fixed text-left top-16 mt-2 w-max right-2 lg:right-48 mr-3 bg-backgroundLight dark:bg-backgroundCardsDark rounded-2xl z-40 shadow-xl flex flex-col gap-6 pt-6"
            initial={{ y: "-100%" }}
            animate={{ y: 0 }}
            exit={{ y: "-120%" }}
            transition={{ type: "spring", stiffness: 120, damping: 20 }}
          >
            {menuItems.map((item, index) => (
              <DropdownItem
                key={index}
                icon={item.icon}
                label={item.label}
                onClick={item.action}
              />
            ))}
            <div className="w-full h-[1.5px] bg-borderLight dark:bg-borderDark"></div>
            <button
              onClick={onClose}
              className={`text-sm w-full pb-6 text-textLight dark:text-textDark px-6 border-borderLight dark:border-borderDark border-opacity-30  lg:hover:text-mainColorLightOrange lg:dark:hover:text-mainColorLightOrange transition duration-500 ease-in-out poppins-light tracking-wide flex flex-row gap-2 lg:gap-5 items-center `}
            >
              <FaTimes
                className={"text-textLight2 dark:text-textDark2"}
                size={16}
              />
              Close
            </button>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};
