import { useState, useCallback, useMemo } from "react";
import mapboxgl from "mapbox-gl";
import * as MapboxGeocoding from "@mapbox/mapbox-sdk/services/geocoding";

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

const useMapboxGeocode = () => {
  const [address, setAddress] = useState("");
  const [cityCountry, setCityCountry] = useState("");

  // Initialize the Mapbox geocoding service using useMemo
  const geocodingService = useMemo(() => {
    return MapboxGeocoding({ accessToken: MAPBOX_ACCESS_TOKEN });
  }, []);

  const getCityAndCountry = useCallback(
    async (lat, lng) => {
      try {
        const response = await geocodingService
          .reverseGeocode({
            query: [lng, lat],
            limit: 1,
            types: ["place", "country"], // Focus on getting city/country level data
          })
          .send();

        if (
          response &&
          response.body &&
          response.body.features &&
          response.body.features.length > 0
        ) {
          const features = response.body.features[0];
          const context = features.context || [];

          // Extract city and country from the response
          let city = "";
          let country = "";

          // First try to get city from place_type
          if (features.place_type.includes("place")) {
            city = features.text;
          }

          // Look through context to find country
          context.forEach((item) => {
            if (item.id.startsWith("country")) {
              country = item.text;
            }
            // Backup city search if not found in place_type
            if (!city && item.id.startsWith("place")) {
              city = item.text;
            }
          });

          const locationString =
            city && country
              ? `${city}, ${country}`
              : city || country || "Location not found";
          setCityCountry(locationString);
          return locationString;
        } else {
          throw new Error("No location found");
        }
      } catch (error) {
        console.error("Error fetching city and country:", error);
        setCityCountry("Location not found");
        throw error;
      }
    },
    [geocodingService]
  );

  // Keep the original getAddress function for full address if needed
  const getAddress = useCallback(
    async (lat, lng) => {
      try {
        const response = await geocodingService
          .reverseGeocode({
            query: [lng, lat],
            limit: 1,
          })
          .send();

        if (
          response &&
          response.body &&
          response.body.features &&
          response.body.features.length > 0
        ) {
          const location = response.body.features[0].place_name;
          setAddress(location);
          return location;
        } else {
          throw new Error("No address found");
        }
      } catch (error) {
        console.error("Error fetching address:", error);
        setAddress("Failed to get address");
        throw error;
      }
    },
    [geocodingService]
  );

  return { getAddress, getCityAndCountry, address, cityCountry };
};

export default useMapboxGeocode;
