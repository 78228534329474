import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { FiEye, FiEyeOff } from "react-icons/fi";
import logoLight from "../Assets/logos/studyspotrlogoorange.svg";
import { GeneralInput } from "../Components/General/Inputs/GeneralInput";
import { NavigationButtons } from "../Components/General/Buttons/NavigationButtons";
import { CallToActionSecondary } from "../Components/General/Buttons/CallToActionSecondary";
import { CallToActionMain } from "../Components/General/Buttons/CallToActionMain";
import { useAlert } from "../Context/AlertContext";

export const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [sent, setSent] = useState(false);
  const { id: token } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [timer, setTimer] = useState(300);
  const [isRunning, setIsRunning] = useState(false);
  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;

  const { showAlert } = useAlert();

  const cancel = () => {
    navigate("/login");
  };

  useEffect(() => {
    // If the timer is running, set up the interval to tick down every second
    let intervalId;
    if (isRunning) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 0) {
            clearInterval(intervalId); // Stop the timer when it reaches 0
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    // Clean up the interval when the component unmounts or if the timer stops
    return () => clearInterval(intervalId);
  }, [isRunning]);

  const backToEmail = () => {
    navigate("/reset-password");
    setMessage("");
  };

  const sendLink = async (event) => {
    event.preventDefault();
    if (email === "") {
      showAlert("Please enter your email!");
      return;
    }

    setIsRunning(true);
    setSent(true);

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}/resetpassword/sendLink`,
        { email },
        { withCredentials: true }
      );
      console.log(data); // You can log the response for debugging
    } catch (error) {
      console.error("Failed", error);
    }
  };

  const resetPassword = async (event) => {
    event.preventDefault();
    if (password === "") {
      showAlert("Please enter a new password!");
      return;
    }

    if (password != confirmPassword) {
      showAlert("Passwords do not match.");
      return;
    }

    try {
      // Send POST request to backend to change the password
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}/resetpassword/changePassword`,
        { token, password },
        { withCredentials: true }
      );

      showAlert(data.message);
      navigate("/login");
    } catch (error) {
      if (error.response && error.response.data) {
        showAlert(
          error.response.data.message || "An error occurred. Please try again."
        );
      } else {
        showAlert("An error occurred. Please try again.");
      }
      console.error(error);
    }
  };

  return (
    <div className="flex bg-backgroundLight dark:bg-backgroundDark text-text flex-col items-center pt-24 lg:pt-0 lg:justify-center h-screen">
      {!token && !sent && (
        <form
          onSubmit={sendLink}
          className="w-10/12 lg:w-1/3 bg-backgroundCardsLight shadow-sm dark:bg-backgroundCardsDark rounded-xl px-4 lg:px-10 pt-10 pb-6 flex flex-col items-center fade-in"
        >
          <img src={logoLight} className="w-8 h-auto mb-4" alt="Logo" />
          <h1 className="poppins-medium text-textLight dark:text-textDark text-2xl text-center w-full">
            Reset Password
          </h1>
          <p className="poppins-light text-textLight dark:text-textDark text-xs text-center w-full mt-1.5">
            Please enter your email below to reset your password.
          </p>
          <GeneralInput
            placeholder={"Email"}
            labelText={"Email"}
            className={"mt-8"}
            onChange={(e) => setEmail(e.target.value)}
            type={"email"}
            value={email}
          />
          <div className="flex flex-row gap-2 items-center w-full mt-6">
            <CallToActionSecondary
              text={"Cancel"}
              type={"button"}
              onClick={cancel}
              className={"w-full"}
            />
            <CallToActionMain
              text={"Send Email"}
              type={"submit"}
              className={"w-full"}
            />
          </div>
        </form>
      )}

      {sent && !token ? (
        <div className="w-10/12 lg:w-1/3 bg-backgroundCardsLight shadow-sm dark:bg-backgroundCardsDark rounded-xl px-4 lg:px-10 pt-10 pb-6 flex flex-col items-center fade-in">
          <h1 className="poppins-medium text-textLight dark:text-textDark text-2xl text-center w-full">
            Email Sent
          </h1>
          <h2 className="poppins-regular text-textLight dark:text-textDark text-base text-center w-full mt-4">
            A link has been sent to {email}, click the link to reset your
            password.{" "}
          </h2>
          <p className="text-mainColorLightOrange dark:text-mainColorDarkOrange">
            The link will expire in {minutes.toString().padStart(2, "0")}{" "}
            minutes and {seconds.toString().padStart(2, "0")} seconds!
          </p>
          <h2 className="poppins-light text-textLight dark:text-textDark text-xs text-center w-10/12 mt-4">
            (Check your spam folder if you don't see it! Please allow up to 30
            seconds for the email to arrive!)
          </h2>
          <div className="flex flex-row gap-2 items-center w-full mt-6">
            <CallToActionMain
              text={"Back to Log In"}
              type={"button"}
              onClick={cancel}
              className={"w-full"}
            />
          </div>
        </div>
      ) : null}

      {token && !sent && (
        <form
          onSubmit={resetPassword}
          className="w-10/12 lg:w-1/3 bg-backgroundCardsLight shadow-sm dark:bg-backgroundCardsDark rounded-xl px-4 lg:px-10 pt-10 pb-6 flex flex-col items-center fade-in"
        >
          <img src={logoLight} className="w-8 h-auto mb-4" alt="Logo" />
          <h1 className="poppins-medium text-textLight dark:text-textDark text-2xl text-center w-full">
            Reset Password
          </h1>
          <p className="poppins-light text-textLight dark:text-textDark text-xs text-center w-full mt-1.5">
            Please enter your new password below to reset it.
          </p>
          <GeneralInput
            placeholder={"New Password"}
            labelText={"New Password"}
            className={"mt-8"}
            onChange={(e) => setPassword(e.target.value)}
            type={"password"}
            value={password}
          />
          <GeneralInput
            placeholder={"Confirm New Password"}
            labelText={"Confirm New Password"}
            className={"mt-4"}
            onChange={(e) => setConfirmPassword(e.target.value)}
            type={"password"}
            value={confirmPassword}
          />
          <div className="flex flex-row gap-2 items-center w-full mt-6">
            <CallToActionSecondary
              text={"Cancel"}
              type={"button"}
              onClick={cancel}
              className={"w-full"}
            />
            <CallToActionMain
              text={"Reset Password"}
              type={"submit"}
              className={"w-full"}
            />
          </div>
        </form>
      )}
    </div>
  );
};
