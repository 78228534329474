export const GeneralButton = ({
  onClick,
  text,
  buttonBool,
  icon,
  orange,
  iconRight,
}) => {
  return (
    <button
      className={`${
        orange
          ? "bg-orange-500 dark:bg-orange-800 hover:bg-orange-600 dark:hover:bg-orange-900 text-white dark:text-orange-400"
          : buttonBool
          ? "bg-stone-900 dark:bg-stone-500 text-white dark:text-stone-200"
          : "bg-stone-200 dark:bg-stone-800 hover:bg-stone-100 dark:hover:bg-stone-700 text-stone-900 dark:text-stone-500"
      } poppins-medium text-xs lg:text-sm px-4 py-2 rounded-lg transition-all duration-500 flex flex-row items-center gap-2 w-max`}
      onClick={onClick}
    >
      {icon && !iconRight && icon}
      {text}
      {iconRight && icon}
    </button>
  );
};
