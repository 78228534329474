import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import useProfile from "../Hooks/useProfile";

const ProtectedRoute = ({ element }) => {
  const { profile, loading } = useProfile();
  const location = useLocation();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!profile) {
    // Store the attempted route before redirecting to login
    localStorage.setItem("redirectAfterLogin", location.pathname);
    return <Navigate to="/login" replace />;
  }

  return element;
};

export default ProtectedRoute;
