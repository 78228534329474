import { useNavigate } from "react-router-dom";
import { ProfilePageCard } from "./ProfilePageCard";
import { CallToActionMainOrangeSmall } from "../General/Buttons/CallToActionMainOrangeSmall";

export const StudyTypeQuizOnProfile = () => {
  const navigate = useNavigate();

  const handleTakeQuiz = () => {
    navigate("/studyTypeQuiz"); 
  };

  return (
    <ProfilePageCard>
      <h2 className="poppins-medium text-lg">Discover Your Study Type</h2>
      <p className="poppins-light text-sm opacity-70 mt-2">
        Unlock personalized study spot recommendations based on your unique study style. 
        Whether you thrive in quiet corners or lively cafés, this quiz will help you find 
        the perfect match!
      </p>

      <div className="mt-4">
        <CallToActionMainOrangeSmall
          text="Take Quiz"
          onClick={handleTakeQuiz}
        />
      </div>
    </ProfilePageCard>
  );
};
