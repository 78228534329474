import axios from "axios";
import React from "react";
import { FiDatabase, FiLogOut, FiUser } from "react-icons/fi";
import { RiFileAddLine } from "react-icons/ri";
import { useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { FaCoins } from "react-icons/fa";
const DropdownItem = ({ icon: Icon, label, onClick, isActive }) => (
  <button
    onClick={onClick}
    className={`text-sm w-full px-5 text-textLight dark:text-textDark2 lg:px-8 py-3.5 border-borderLight dark:border-borderDark border-opacity-30 hover:bg-backgroundCardsLight dark:hover:bg-backgroundCardsDark transition duration-500 ease-in-out poppins-regular flex flex-row gap-2 lg:gap-5 items-center ${
      isActive ? "bg-backgroundCardsLight dark:bg-backgroundCardsDark" : ""
    }`}
  >
    <Icon />
    {label}
  </button>
);

export const ProfileDropdown = ({
  toggleShowProfileDropdown,
  showProfileDropdown,
}) => {
  const navigate = useNavigate();
  const location = useLocation(); // Correctly get the location

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_URL}/api/auth/logout`);
      localStorage.removeItem("token");
      navigate("/login");
      toggleShowProfileDropdown();
      window.location.reload();
    } catch (error) {
      console.log("Logout error:", error);
    }
  };

  const menuItems = [
    {
      icon: FiUser,
      label: "View Profile",
      action: () => {
        navigate("/profile");
        toggleShowProfileDropdown();
      },
      path: "/profile",
    },
    {
      icon: FaCoins,
      label: "Points",
      action: () => {
        navigate("/points");
        toggleShowProfileDropdown();
      },
      path: "/points",
    },
    {
      icon: RiFileAddLine,
      label: "Created Spots",
      action: () => {
        navigate("/created-spots");
        toggleShowProfileDropdown();
      },
      path: "/created-spots",
    },
    {
      icon: FiDatabase,
      label: "Data & Privacy",
      action: () => {
        navigate("/privacy");
        toggleShowProfileDropdown();
      },
      path: "/privacy",
    },
    { icon: FiLogOut, label: "Log Out", action: handleLogout },
  ];

  const dropdownVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  return (
    <motion.div
      initial="hidden"
      animate={showProfileDropdown ? "visible" : "hidden"}
      exit="exit"
      variants={dropdownVariants}
      transition={{ duration: 0.35, ease: "easeInOut" }}
      className="absolute right-2 lg:right-4 mt-[7px] w-44 lg:w-52 top-14 lg:top-16 bg-backgroundLight dark:bg-backgroundDark rounded-b-xl drop-shadow-md dark:border-stone-500 border-stone-500 z-40"
    >
      <div className="py-2">
        {menuItems.map((item, index) => (
          <DropdownItem
            key={index}
            icon={item.icon}
            label={item.label}
            onClick={item.action}
            isActive={location.pathname === item.path}
          />
        ))}
      </div>
    </motion.div>
  );
};
