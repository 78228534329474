import React from "react";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";

// Hook to get stars based on a rating
export const useRatingStars = (rating) => {
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;

  const totalStars = [];

  // Fill full stars
  for (let i = 0; i < fullStars; i++) {
    totalStars.push(
      <FaStar
        className="star-full text-black dark:text-stone-300 text-xs"
        key={`full-${i}`}
      />
    );
  }

  // Add a half star if applicable
  if (hasHalfStar) {
    totalStars.push(
      <FaStarHalfAlt
        className="star-half text-black dark:text-stone-300 text-xs"
        key="half"
      />
    );
  }

  // Fill the remaining with empty stars up to 5 total
  while (totalStars.length < 5) {
    totalStars.push(
      <FaStar
        className="text-stone-200 dark:text-stone-700 text-xs"
        key={`empty-${totalStars.length}`}
      />
    );
  }

  return totalStars;
};
