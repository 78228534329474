import { useEffect, useState } from "react";
import useProfile from "../Hooks/useProfile";
import axios from "axios";
import { LocationCardProfile } from "../Components/Find/LocationCardProfile";
import { useNavigate } from "react-router-dom";
import { ConfirmAlertGeneral } from "../Components/General/Alerts/ConfirmAlertGeneral";
import { FiMapPin, FiPlusCircle } from "react-icons/fi";

export const CreatedSpots = () => {
  const { profile } = useProfile();
  const [showAlert, setShowAlert] = useState(false);
  const [locationsCreate, setLocationsCreated] = useState([]);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [locationToDelete, setLocationToDelete] = useState(null);
  const navigate = useNavigate();

  const handleShowAlert = () => setShowAlert(true);
  const handleCloseAlert = () => setShowAlert(false);

  const createSpot = () => navigate("/addStudySpot");

  const getLocationsCreated = async () => {
    if (!profile) return;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/locations/createdSpots/${profile._id}`
      );
      setLocationsCreated(response.data);
    } catch (error) {
      console.error("Error fetching created spots:", error);
    }
  };

  useEffect(() => {
    if (profile) getLocationsCreated();
  }, [profile]);

  const confirmDeleteLocation = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_URL}/locations/${locationToDelete}`
      );
      setLocationsCreated((prev) =>
        prev.filter((loc) => loc._id !== locationToDelete)
      );
      handleShowAlert();
    } catch (error) {
      console.error("Error deleting spot:", error);
    } finally {
      setShowConfirmAlert(false);
      setLocationToDelete(null);
    }
  };

  const deleteLocation = (id) => {
    setLocationToDelete(id);
    setShowConfirmAlert(true);
  };

  if (!profile) return <div className="text-center py-20">Loading...</div>;

  return (
    <>
      {showConfirmAlert && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <ConfirmAlertGeneral
            setCancel={() => setShowConfirmAlert(false)}
            setConfirm={confirmDeleteLocation}
            title={"Delete Study Spot"}
            text={"Are you sure you want to delete this study spot?"}
            confirmButtonText="Delete"
          />
        </div>
      )}

      <div className="w-screen h-screen bg-backgroundLight dark:bg-backgroundDark overflow-y-scroll fade-in pt-16 lg:pt-28">
        {/* Header Section */}
        <div className="relative overflow-hidden bg-gradient-to-b from-backgroundCardsLight to-backgroundLight dark:from-backgroundCardsDark dark:to-backgroundDark py-12 lg:py-16 px-4 lg:px-20">
          <div className="absolute inset-0 bg-gradient-radial from-orange-400/20 via-transparent to-transparent" />

          <div className="relative z-10 flex flex-col items-center text-center">
            <div className="flex items-center gap-2 bg-white/10 backdrop-blur-sm px-4 py-2 rounded-full mb-4">
              <FiMapPin className="w-5 h-5 text-orange-400" />
              <span className="text-sm text-textLight dark:text-textDark">
                Your Created Study Spots
              </span>
            </div>

            <h1 className="poppins-semibold text-4xl lg:text-6xl text-textLight dark:text-textDark">
              Manage Your Study Spots
            </h1>

            <p className="poppins-regular text-sm lg:text-base lg:w-2/5 w-10/12 mt-6 text-textLight2 dark:text-textDark2">
              View, edit, or delete your created study spots. Keep the list up-to-date for others to discover!
            </p>

            <button
              onClick={createSpot}
              className="mt-8 bg-orange-500 hover:bg-orange-600 text-white px-6 py-3 rounded-full flex items-center gap-2 transform hover:scale-105 transition-all duration-300 shadow-lg"
            >
              <FiPlusCircle className="w-5 h-5" />
              <span className="poppins-medium">Create a Study Spot</span>
            </button>
          </div>
        </div>

        {/* Study Spots List */}
        <div className="px-4 lg:px-20 py-12">
          {locationsCreate.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {locationsCreate.map((location) => (
                <LocationCardProfile
                  key={location._id}
                  location={location}
                  isMine={true}
                  deleteLocation={deleteLocation}
                />
              ))}
            </div>
          ) : (
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 flex flex-col items-center text-center">
              <p className="poppins-regular text-gray-500 dark:text-gray-400">
                You haven't created any study spots...yet!
              </p>
              <button
                onClick={createSpot}
                className="mt-4 bg-orange-500 hover:bg-orange-600 text-white px-6 py-2 rounded-full"
              >
                Create Your First Spot
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CreatedSpots;
