import React, { createContext, useContext, useState } from "react";

const DistanceContext = createContext();

export const DistanceProvider = ({ children }) => {
  const [distances, setDistances] = useState({});

  const setDistance = (locationId, distance) => {
    setDistances((prev) => {
      if (prev[locationId] === distance) return prev; // Avoid unnecessary state update
      return { ...prev, [locationId]: distance };
    });
  };

  const getDistance = (locationId) =>
    locationId in distances ? distances[locationId] : null;

  return (
    <DistanceContext.Provider value={{ setDistance, getDistance }}>
      {children}
    </DistanceContext.Provider>
  );
};

export const useDistance = () => useContext(DistanceContext);
