import React, { useState } from "react";

export const ExpandableImage = ({ selectedLocation }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  return (
    <>
      <div
        className={`relative object-cover overflow-hidden rounded-xl ${
          isFullscreen ? "h-72 lg:h-80" : "h-32 lg:h-52"
        } transition-all duration-1000 ease-in-out`}
        onClick={toggleFullscreen}
      >
        <img
          src={selectedLocation.imageUrl}
          alt={`${selectedLocation.name} image`}
          className={`w-full z-20 h-full object-cover transition-all duration-1000 ease-in-out object-center ${
            isFullscreen ? "h-full object-cover" : ""
          }`}
        />
        <p
          className={
            "text-white text-xs absolute top-2 left-2 poppins-light z-30 bg-black bg-opacity-60 px-2 py-1 rounded-xl"
          }
        >
          {isFullscreen
            ? "Click to close full image"
            : "Click to view full image"}
        </p>
      </div>
    </>
  );
};
