import React, { useState } from "react";
import { FaPen, FaTimes, FaCheck, FaClipboardCheck } from "react-icons/fa";
import { EditableInput } from "../General/Inputs/EditableInput";
import defaultImage from "../../Assets/defaultProfilePhoto.jpg";
import { ProfilePageCard } from "./ProfilePageCard";
import useProfile from "../../Hooks/useProfile";
import axios from "axios";
import { CallToActionSecondarySmall } from "../General/Buttons/CallToActionSecondarySmall";
import { CallToActionMainSmall } from "../General/Buttons/CallToActionMainSmall";
import { AnimatePresence } from "framer-motion";
import AlertPopUp from "../General/Alerts/AlertPopUp";
import { FiAlertCircle } from "react-icons/fi";

export const BasicInformation = () => {
  const { profile, refreshProfile } = useProfile();
  const [updatingInfo, setUpdatingInfo] = useState(false);
  const [updatingPhoto, setUpdatingPhoto] = useState(false);
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [newName, setNewName] = useState(null);
  const [newUsername, setNewUsername] = useState(null);
  const [newPhoneNumber, setNewPhoneNumber] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleImage = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const updateProfilePhoto = async () => {
    const formData = new FormData();
    formData.append("image", file);

    const userID = profile._id;

    const response = await axios.put(
      `${process.env.REACT_APP_URL}/api/auth/updateProfilePhoto/${userID}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response) {
      refreshProfile();
      window.location.reload();
      setFile(null);
      setUpdatingPhoto(false);
    }
  };

  const submitBasicInfoChanges = async () => {
    const payload = {};
    if (newName?.trim()) {
      payload.name = newName.trim();
    }
    if (newUsername?.trim()) {
      payload.username = newUsername.trim();
    }

    if (newPhoneNumber) {
      payload.phoneNumber = newPhoneNumber;
    }

    if (Object.keys(payload).length === 0) {
      setMessage("Please update at least one field!");
      return;
    }

    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_URL}/api/auth/updateUser/${profile._id}`,
        payload, // Send only the updated fields
        { withCredentials: true }
      );
      if (data) {
        refreshProfile();
        setUpdatingInfo(false);
        setMessage("");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        // console.log(error.response.data.message);
        setMessage(error.response.data.message);
        setShowAlert(true);
      } else {
        setMessage("Profile update failed. Please try again.");
        setShowAlert(true);
      }
      setMessage(error.response.data.message);
      setShowAlert(true);
    }
  };

  return (
    <>
      <AnimatePresence>
        {showAlert && message && (
          <AlertPopUp
            icon={<FiAlertCircle />}
            message={message}
            onClose={handleCloseAlert}
          />
        )}
      </AnimatePresence>
      <ProfilePageCard>
        <div className="flex flex-row justify-between">
          <h2 className="poppins-medium text-black dark:text-stone-300 text-lg">
            Basic Information
          </h2>
          <div className="lg:flex flex-row gap-4 items-center w-max hidden ml-auto mb-3 lg:mb-0">
            {updatingInfo && (
              <CallToActionMainSmall
                onClick={submitBasicInfoChanges}
                icon={<FaCheck />}
                text={"Submit"}
              />
            )}
            <CallToActionSecondarySmall
              onClick={() => setUpdatingInfo((prevState) => !prevState)}
              icon={updatingInfo ? <FaTimes size={12} /> : <FaPen size={10} />}
              text={updatingInfo ? "Cancel" : "Edit"}
              className={"h-max"}
            />
          </div>
        </div>

        {profile && (
          <div className="flex flex-col lg:flex-row gap-10 lg:items-center mt-2">
            <div className="flex flex-col gap-4 justify-center items-center">
              <div className="w-28 h-28 rounded-full overflow-hidden">
                <img
                  className="object-cover w-full h-full rounded-full"
                  src={
                    profile.profilePhoto ? profile.profilePhoto : defaultImage
                  }
                  alt="Profile"
                />
              </div>
              {updatingPhoto ? (
                <div className="flex flex-col lg:flex-row h-16 lg:h-7 overflow-hidden mt-2 ">
                  <input
                    id="file-upload"
                    className="poppins-regular text-xs mt-auto mb-auto"
                    type="file"
                    onChange={(e) => handleImage(e)}
                  />
                  <div className="flex flex-row mt-2 lg:mt-0 gap-4">
                    <CallToActionSecondarySmall
                      icon={<FaTimes />}
                      text={"Cancel"}
                      onClick={() => {
                        setUpdatingPhoto(false);
                        setFile(null);
                      }}
                    />
                    {file && (
                      <CallToActionMainSmall
                        onClick={() => {
                          updateProfilePhoto();
                        }}
                        text={"Update Photo"}
                        icon={<FaCheck />}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <CallToActionSecondarySmall
                  icon={<FaPen size={10} />}
                  text={"Edit Profile Photo"}
                  onClick={() => setUpdatingPhoto(true)}
                  className={"w-max mt-2"}
                />
              )}
            </div>
            <div className="lg:w-10/12 flex flex-col gap-8">
              <div className="flex flex-row items-center w-max lg:hidden ml-auto mb-3 lg:mb-0">
                {updatingInfo && (
                  <CallToActionMainSmall
                    onClick={submitBasicInfoChanges}
                    text={"Submit"}
                  />
                )}
                <CallToActionSecondarySmall
                  onClick={() => setUpdatingInfo((prevState) => !prevState)}
                  icon={
                    updatingInfo ? <FaTimes size={12} /> : <FaPen size={10} />
                  }
                  text={updatingInfo ? "Cancel" : "Edit"}
                  className={"h-max"}
                />
              </div>
              <div
                className={`flex flex-col lg:flex-row justify-between gap-3 lg:mt-0 lg:gap-6 w-full`}
              >
                <EditableInput
                  labelText={"Full Name"}
                  isEditing={updatingInfo}
                  setIsEditing={setUpdatingInfo}
                  placeholder={profile.name}
                  currentValue={profile.name}
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                />

                <EditableInput
                  labelText={"Username"}
                  isEditing={updatingInfo}
                  setIsEditing={setUpdatingInfo}
                  placeholder={profile.username}
                  currentValue={profile.username}
                  value={newUsername}
                  onChange={(e) => setNewUsername(e.target.value)}
                />
              </div>

              <div
                className={`flex flex-col lg:flex-row justify-between gap-3 lg:mt-0 lg:gap-6 w-full`}
              >
                <div className="flex flex-col gap-1 w-full">
                  <p className="mb-1 flex flex-row items-start gap-0.5 text-textLight dark:text-textDark2 poppins-regular text-xs ml-0.5">
                    Email
                  </p>
                  <p className="px-0.5 py-0.5 poppins-medium text-black dark:text-stone-300 opacity-80 text-base hover:cursor-pointer">
                    {profile.email}
                  </p>
                </div>
                <EditableInput
                  labelText={"Phone Number"}
                  isEditing={updatingInfo}
                  type={"tel"}
                  setIsEditing={setUpdatingInfo}
                  currentValue={profile.phoneNumber || "N/A"}
                  value={newPhoneNumber}
                  onChange={(e) => setNewPhoneNumber(e.target.value)}
                  pattern={"[0-9]{3}-[0-9]{3}-[0-9]{4}"}
                />
              </div>
            </div>
          </div>
        )}
      </ProfilePageCard>
    </>
  );
};
