import { createContext, useContext, useState, useEffect, useMemo } from "react";
import useProfile from "../Hooks/useProfile";
import axios from "axios";
import { useAlert } from "./AlertContext";

// Create the SavedLocationsContext
const SavedLocationsContext = createContext();

// Create a custom hook to use the context
export const useSavedLocationsContext = () => useContext(SavedLocationsContext);

export const SavedLocationsProvider = ({ children, locations = [] }) => {
  const [savedLocations, setSavedLocations] = useState([]);
  const { profile } = useProfile();
  const { showAlert } = useAlert();
  const [showSaved, setShowSaved] = useState(false);

  const toggleShowSaved = () => {
    setShowSaved((prevShowSaved) => !prevShowSaved);
  };

  const savedLocationIds = useMemo(
    () => profile?.savedLocations || [],
    [profile]
  );

  useEffect(() => {
    if (savedLocationIds.length > 0 && locations.length > 0) {
      const filteredLocations = locations.filter((location) =>
        savedLocationIds.includes(location._id)
      );
      setSavedLocations(filteredLocations);
    }
  }, [savedLocationIds, locations]);

  const save = async (loc) => {
    if (!profile) {
      console.log("User is not logged in, cannot save location.");
      return;
    }

    try {
      const userId = profile._id;
      await axios.put(
        `${process.env.REACT_APP_URL}/locations/saveSpot/${userId}/${loc._id}`
      );
      setSavedLocations((prevLocations) =>
        prevLocations.some((location) => location._id === loc._id)
          ? prevLocations
          : [...prevLocations, loc]
      );
      showAlert(`Study Spot Saved!`);
    } catch (error) {
      console.error("Error saving the location:", error.message);
    }
  };

  const unsave = async (loc) => {
    if (!profile) {
      console.log("User is not logged in, cannot unsave location.");
      return;
    }

    try {
      const userId = profile._id;
      await axios.put(
        `${process.env.REACT_APP_URL}/locations/unsaveSpot/${userId}/${loc._id}`
      );
      setSavedLocations((prevLocations) =>
        prevLocations.filter((location) => location._id !== loc._id)
      );
      showAlert(`Study Spot Unsaved!`);
    } catch (error) {
      console.error("Error unsaving the location:", error.message);
    }
  };

  return (
    <SavedLocationsContext.Provider
      value={{ savedLocations, save, unsave, showSaved, toggleShowSaved }}
    >
      {children}
    </SavedLocationsContext.Provider>
  );
};
