import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useViewContext } from "../Context/ViewContext";

const useSelectLocation = (locations) => {
  const navigate = useNavigate();
  const { id } = useParams(); // Extract the `id` param from the URL
  const [selectedLocation, setSelectedLocation] = useState(null);
  const { toggleList } = useViewContext();

  // Attempt selection based on the `id` param or `localStorage`
  useEffect(() => {
    if (locations && locations.length > 0) {
      if (id) {
        // Set selectedLocation based on the URL parameter
        findLocationById(id);
      } else {
        // Clear selected location if `id` is missing from URL
        setSelectedLocation(null);
      }
    }
  }, [id, locations]);

  // Function to find a location by ID and set it as selected
  const findLocationById = (id) => {
    const foundLocation = locations.find((loc) => loc._id === id);
    if (foundLocation) {
      setSelectedLocation(foundLocation);
      localStorage.setItem("selectedID", id); // Persist selection
    }
  };

  // Select a location and update URL with its `id`
  const selectLocation = (loc) => {
    if (!loc || !loc._id) return;
    setSelectedLocation(loc);
    localStorage.setItem("selectedID", loc._id); // Save to localStorage
    navigate(`/${loc._id}`); // Update URL to reflect selected location
    toggleList();
  };

  // Deselect location and clear `id` from URL
  const deselectLocation = () => {
    setSelectedLocation(null);
    localStorage.removeItem("selectedID");
    navigate("/"); // Reset URL to base
  };

  return {
    selectedLocation,
    selectLocation,
    deselectLocation,
  };
};

export default useSelectLocation;
