import useGeolocation from "./useGeolocation";

const useGetDistance = () => {
  const { location: userLocation } = useGeolocation();

  const toRadians = (degrees) => (degrees * Math.PI) / 180;

  const calculateDistance = (spotLocation, radius) => {
    if (!userLocation || !spotLocation) return null;

    const { lat: lat1, lng: lon1 } = userLocation;
    const { lat: lat2, long: lon2 } = spotLocation;

    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) ** 2 +
      Math.cos(toRadians(lat1)) *
        Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) ** 2;

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = radius * c;

    return distance; // Always return a number
  };

  const getDistanceInMiles = (spotLocation) => {
    const EARTH_RADIUS_MILES = 3958.8;
    const distanceInMiles = calculateDistance(spotLocation, EARTH_RADIUS_MILES);

    if (distanceInMiles === null) return null;

    // If less than 0.1 miles, convert to feet
    if (distanceInMiles < 0.1) {
      const distanceInFeet = distanceInMiles * 5280;
      return `${Math.round(distanceInFeet)} feet`;
    }

    // Return distance in miles
    return `${distanceInMiles.toFixed(1)} miles`;
  };

  const getDistanceInKilometers = (spotLocation) => {
    const EARTH_RADIUS_KM = 6371; // Earth's radius in kilometers
    const distanceInKilometers = calculateDistance(
      spotLocation,
      EARTH_RADIUS_KM
    );

    if (distanceInKilometers === null) return null;

    return `${distanceInKilometers.toFixed(1)} km`;
  };

  return { userLocation, getDistanceInMiles, getDistanceInKilometers };
};

export default useGetDistance;
