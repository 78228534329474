import React, { useRef, useState, useEffect } from "react";
import Map, { Source, Layer, Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import { FaMapMarkerAlt, FaPlus } from "react-icons/fa";
import useGeolocation from "../../Hooks/useGeolocation";
import { FiSettings } from "react-icons/fi";
import { FaLocationCrosshairs } from "react-icons/fa6";
import { useViewContext } from "../../Context/ViewContext";
import useLocations from "../../Hooks/useLocations";
import useSelectLocation from "../../Hooks/useSelectLocation";
import { Loading } from "../Loading";
import { useRoutesContext } from "../../Context/RoutesContext";
import { Geocoder } from "@mapbox/search-js-react";
import { SettingsModel } from "../SettingsModel";
import { useNavigate } from "react-router-dom";
import { LocationCard } from "./LocationCard";
import { GeneralInput } from "../General/Inputs/GeneralInput";
import { CallToActionMainOrange } from "../General/Buttons/CallToActionMainOrange";
import { GeneralButton } from "../General/GeneralButton";

const FindMap = () => {
  const { location: userLocation } = useGeolocation();
  const { locations } = useLocations();
  const { selectedLocation, deselectLocation, selectLocation } =
    useSelectLocation(locations);
  const { view, toggleList } = useViewContext();
  const [mapLoaded, setMapLoaded] = useState(false);
  const [mapStyle, setMapStyle] = useState("");
  const mapRef = useRef(null);
  const [pinLocation, setPinLocation] = useState(null);
  const [routeData, setRouteData] = useState(null);
  const { time, setTime, transportType, setTransportType } = useRoutesContext();
  const [inputValue, setInputValue] = useState("");
  const [showSettings, setShowSettings] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const navigateToAdd = () => {
    navigate("/addStudySpot");
  };

  const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

  useEffect(() => {
    const mapStyleValue = localStorage.getItem("mapStyle");
    if (mapStyleValue) {
      setLoading(true); // Start loading when style change begins
      setMapStyle(mapStyleValue); // Update the style
    }
  }, [showSettings]);

  const handleMapClick = (event) => {
    const features = event.target.queryRenderedFeatures(event.point, {
      layers: ["clusters", "unclustered-point"],
    });

    if (features.length > 0) {
      const feature = features[0];

      if (feature.properties.point_count !== undefined) {
        // Cluster: Zoom in on the cluster
        event.target.flyTo({
          center: feature.geometry.coordinates,
          zoom: event.target.getZoom() + 2,
          essential: true,
        });
      } else {
        // Single point: Select the location
        const { id } = feature.properties;
        const foundLocation = locations.find((location) => location._id === id);

        if (foundLocation) {
          selectLocation(foundLocation); // Trigger selection
          toggleList();
          fitMapToLocations(userLocation, foundLocation);
        }
      }
    } else {
      setRouteData(null);
      deselectLocation();
    }
  };

  const geojsonData = {
    type: "FeatureCollection",
    features: locations.map((marker) => ({
      type: "Feature",
      properties: {
        id: marker._id,
        name: marker.name,
      },
      geometry: {
        type: "Point",
        coordinates: [marker.location.long, marker.location.lat], // [lng, lat]
      },
    })),
  };

  useEffect(() => {
    if (selectedLocation != null) {
      fitMapToLocations(userLocation, selectedLocation);
    } else {
      // resetMapView(0, 0);
    }
    // eslint-disable-next-line
  }, [selectedLocation]);

  const fitMapToLocations = (userLocation, selectedLocation) => {
    if (mapRef.current) {
      const bounds = new mapboxgl.LngLatBounds();
      bounds.extend([userLocation.lng, userLocation.lat]); // User's location
      bounds.extend([
        selectedLocation.location.long,
        selectedLocation.location.lat,
      ]); // Selected location

      mapRef.current.fitBounds(bounds, {
        padding: { top: 150, bottom: 150, left: 400, right: 500 }, // Adjust left padding to offset to the left
        duration: 1000, // Animation duration
      });
    }
  };

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  const resetMapView = (bearing = 0, pitch = 0) => {
    if (mapRef.current && userLocation) {
      mapRef.current.flyTo({
        center: [userLocation.lng, userLocation.lat],
        zoom: 14,
        bearing: bearing,
        pitch: pitch,
        essential: true,
      });
    }
  };

  const fetchRoute = async (end) => {
    const url = `https://api.mapbox.com/directions/v5/mapbox/${transportType}/${userLocation.lng},${userLocation.lat};${end.long},${end.lat}?geometries=geojson&overview=full&access_token=${MAPBOX_TOKEN}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.routes && data.routes.length > 0) {
        setRouteData(data.routes[0].geometry); // Set the first route's geometry

        const route = data.routes[0];
        const travelTimeInSeconds = route.duration;
        setTime(formatTime(travelTimeInSeconds));
      }
    } catch (error) {
      console.error("Error fetching directions:", error);
    }
  };

  useEffect(() => {
    if (userLocation && selectedLocation) {
      fetchRoute(selectedLocation.location);
    }
  }, [userLocation, selectedLocation, transportType]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    let formattedTime = "";
    if (hours > 0) formattedTime += `${hours} hr `;
    if (minutes > 0) formattedTime += `${minutes} min `;
    if (secs > 0) formattedTime += `${secs} sec`;

    return formattedTime.trim();
  };

  const handleMapStyleLoaded = () => {
    setLoading(false); // End loading once the style is ready
  };

  const handleMapIdle = () => {
    setLoading(false); // Hide loading spinner
  };

  const filteredLocations = locations.filter((location) => {
    const nameMatch =
      location.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      location.key?.toLowerCase().includes(searchTerm.toLowerCase());

    const spotLocationInfoMatch = location.spotLocationInfo?.place_name
      ?.toLowerCase()
      .includes(searchTerm.toLowerCase());

    return nameMatch || spotLocationInfoMatch;
  });

  return (
    <>
      {showSettings && (
        <div className="fixed top-0 left-0 z-50">
          <SettingsModel
            setLoading={() => setLoading(true)}
            setCancel={() => setShowSettings(false)}
          />
        </div>
      )}
      <div
        className={`w-screen fade-in pt-14 ${
          view === "map" ? "lg:ml-0" : "lg:-ml-60"
        } z-0 h-screen transition-all duration-500 ease-in-out`}
      >
        {userLocation ? (
          <>
            <Map
              ref={mapRef}
              initialViewState={{
                latitude: userLocation.lat,
                longitude: userLocation.lng,
                zoom: 14,
                projection: "globe",
              }}
              style={{ width: "100%", height: "100%" }}
              mapStyle={mapStyle}
              mapboxAccessToken={MAPBOX_TOKEN}
              onClick={handleMapClick}
              onLoad={handleMapLoad}
              onStyleData={handleMapStyleLoaded}
              onIdle={handleMapIdle}
            >
              {mapLoaded && (
                <>
                  <Marker
                    latitude={userLocation.lat}
                    longitude={userLocation.lng}
                  >
                    <FaMapMarkerAlt
                      size={20}
                      className="text-black dark:text-black"
                    />
                  </Marker>

                  {pinLocation && (
                    <Marker
                      latitude={pinLocation.lat}
                      longitude={pinLocation.lng}
                    >
                      <FaMapMarkerAlt size={20} color="red" />
                    </Marker>
                  )}

                  {locations.map((loc) => (
                    <Marker
                      key={loc.name}
                      latitude={loc.location.lat}
                      longitude={loc.location.long}
                    >
                      <img
                        src="https://studyspotr.s3.us-east-2.amazonaws.com/Vector.png"
                        alt="Custom Icon"
                        style={{
                          width: "22px",
                          height: "30px",
                          cursor: "pointer",
                        }}
                      />
                    </Marker>
                  ))}

                  {routeData && selectedLocation && (
                    <Source
                      id="route"
                      type="geojson"
                      data={{ type: "Feature", geometry: routeData }}
                    >
                      <Layer
                        id="route-layer"
                        type="line"
                        layout={{
                          "line-join": "round",
                          "line-cap": "round",
                        }}
                        paint={{
                          "line-color": "#ff7e5f",
                          "line-width": 4,
                        }}
                      />
                    </Source>
                  )}

                  <Source
                    id="locations"
                    type="geojson"
                    data={geojsonData}
                    cluster={true}
                    clusterMaxZoom={14} // Max zoom to cluster points on
                    clusterRadius={50} // Radius of each cluster when clustering points
                  >
                    <Layer
                      id="clusters"
                      type="circle"
                      source="locations"
                      style={{ zIndex: "10" }}
                      filter={["has", "point_count"]}
                      paint={{
                        "circle-color": "#51bbd6",
                        "circle-opacity": 0,
                        "circle-radius": [
                          "step",
                          ["get", "point_count"],
                          20,
                          100,
                          30,
                          750,
                          40,
                        ],
                      }}
                    />
                    <Layer
                      id="cluster-count"
                      type="symbol"
                      source="locations"
                      filter={["has", "point_count"]}
                      layout={{
                        "text-field": "{point_count_abbreviated}",
                        "text-font": [
                          "DIN Offc Pro Medium",
                          "Arial Unicode MS Bold",
                        ],
                        "text-size": 12,
                      }}
                      paint={{
                        "text-color": "#ffffff",
                      }}
                    />
                    <Layer
                      id="location-labels"
                      type="symbol"
                      source="locations"
                      filter={["!", ["has", "point_count"]]}
                      layout={{
                        "text-field": "{name}",
                        "text-font": [
                          "DIN Offc Pro Medium",
                          "Arial Unicode MS Bold",
                        ],
                        "text-size": 14,
                        "text-offset": [0, 1.3], // Positions text above the point
                        "text-anchor": "top",
                        "text-allow-overlap": false, // Allow labels to overlap
                        "text-ignore-placement": false, // Ignore placement constraints
                        "text-padding": 4,
                        "text-max-width": 10,
                      }}
                      paint={{
                        "text-color": "#000000",
                        "text-halo-color": "#ffffff",
                        "text-halo-width": 2,
                        "text-opacity": 1, // Ensure full opacity
                      }}
                    />
                    <Layer
                      id="unclustered-point"
                      type="circle"
                      source="locations"
                      filter={["!", ["has", "point_count"]]}
                      paint={{
                        "circle-color": "#ff4f00",
                        "circle-radius": 9,
                        "circle-opacity": 0,
                      }}
                    />
                  </Source>
                </>
              )}
              <div
                className={`fixed hidden fade-in transition-left duration-500 ease-in-out z-40 lg:flex flex-row lg:flex-col gap-2 lg:gap-3 bottom-3 ${
                  view === "list" ? "lg:left-1/3 lg:pl-3" : "lg:left-4"
                }`}
              >
                <button
                  onClick={() => resetMapView(0, 0)}
                  className="bg-white w-max dark:bg-stone-900 p-3 rounded"
                >
                  <FaLocationCrosshairs
                    size={20}
                    className="text-orange-500"
                    strokeWidth={2.5}
                  />
                </button>

                <button
                  onClick={navigateToAdd}
                  className="bg-orange-500 dark:bg-orange-700 flex flex-row poppins-semibold text-white dark:text-orange-200 items-center gap-1 p-3 rounded shadow-lg"
                >
                  Add your own spot
                </button>
              </div>
              {view === "map" && (
                <>
                  <div className="fixed lg:hidden fade-in flex flex-row lg:flex-col gap-3 lg:gap-3 top-36 lg:top-40 -mt-1 left-3 pointer-events-auto">
                    {selectedLocation && (
                      <CallToActionMainOrange
                        text={"Back"}
                        onClick={toggleList}
                      />
                    )}
                  </div>
                  <div className="fixed lg:hidden fade-in flex flex-row lg:flex-col gap-3 lg:gap-3 top-32 mt-2 lg:top-40 right-2 pointer-events-auto">
                    <CallToActionMainOrange
                      onClick={navigateToAdd}
                      text={"Add a Study Spot"}
                    />
                    <button
                      onClick={() => resetMapView(0, 0)}
                      className="bg-backgroundLight dark:bg-backgroundDark w-8 h-8 flex items-center justify-center rounded shadow-lg hover:bg-gray-50 active:bg-gray-100"
                    >
                      <FaLocationCrosshairs
                        size={13}
                        className="text-orange-500"
                        strokeWidth={2.5}
                      />
                    </button>
                  </div>
                </>
              )}
            </Map>
            {view == "map" && (
              <>
                <div className="w-full top-16 lg:top-16 fixed left-0 z-40 h-16 p-2.5 bg-backgroundLight dark:bg-backgroundDark border-b border-borderLight dark:border-borderDark">
                  <GeneralInput
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder={"Search for a place"}
                  />
                </div>
                <div className="w-full fixed top-32 lg:top-36 z-40 max-h-[33vh] overflow-y-scroll scrollbar-hide">
                  {searchTerm.trim() &&
                    filteredLocations.length > 0 &&
                    filteredLocations.map((location) => (
                      <LocationCard key={location._id} location={location} />
                    ))}
                </div>
              </>
            )}
          </>
        ) : (
          <Loading defualtLoading={true} />
        )}
      </div>
    </>
  );
};

export default FindMap;
