import React, { useRef, useState, useEffect } from "react";
import Map, { Source, Layer, Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import { Geocoder } from "@mapbox/search-js-react";
import { FaMapMarkerAlt, FaMinus, FaArrowLeft } from "react-icons/fa";
import useGeolocation from "../../Hooks/useGeolocation";
import { FiChevronRight, FiSettings } from "react-icons/fi";
import { FaLocationCrosshairs } from "react-icons/fa6";
import { useViewContext } from "../../Context/ViewContext";
import useLocations from "../../Hooks/useLocations";
import useSelectLocation from "../../Hooks/useSelectLocation";
import { AddInfo } from "./AddInfo";
import { Loading } from "../Loading";
import { SettingsModel } from "../SettingsModel";
import { CallToActionSecondarySmall } from "../General/Buttons/CallToActionSecondarySmall";
import { CallToActionMainSmall } from "../General/Buttons/CallToActionMainSmall";

const AddMap = () => {
  const { location: userLocation } = useGeolocation();
  const { locations } = useLocations();
  const { selectedLocation, deselectLocation, selectLocation } =
    useSelectLocation(locations);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [mapStyle, setMapStyle] = useState("");
  const mapRef = useRef(null);
  const [pinLocation, setPinLocation] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showMobile, setShowMobile] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [showSettings, setShowSettings] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!userLocation || !mapRef.current) {
        window.location.reload();
      }
    }, 10000);

    return () => clearTimeout(timeout);
  }, [loading, userLocation, mapRef]);

  useEffect(() => {
    const mapStyleValue = localStorage.getItem("mapStyle");
    if (mapStyleValue) {
      setLoading(true); // Start loading when style change begins
      setMapStyle(mapStyleValue); // Update the style
    }
  }, [showSettings]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const mapStyleValue = localStorage.getItem("mapStyle");
    setMapStyle(mapStyleValue);
  }, []);

  const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

  const handleMapClick = (event) => {
    const lng = event.lngLat.lng;
    const lat = event.lngLat.lat;
    setPinLocation({ lat, lng });
    setShowMobile(true);
  };

  useEffect(() => {
    if (pinLocation != null) {
      fitMapToLocations(pinLocation);
    } else {
      resetMapView(0, 0);
    }
    // eslint-disable-next-line
  }, [pinLocation]);

  const fitMapToLocations = (pinLocation, bearing = 0, pitch = 0) => {
    if (mapRef.current && pinLocation) {
      mapRef.current.flyTo({
        center: [pinLocation.lng, pinLocation.lat],
        zoom: 18,
        bearing: bearing,
        pitch: pitch,
        essential: true,
      });
    }
  };

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  const resetMapView = (bearing = 0, pitch = 0) => {
    if (mapRef.current && userLocation) {
      mapRef.current.flyTo({
        center: [userLocation.lng, userLocation.lat],
        zoom: 18,
        bearing: bearing,
        pitch: pitch,
        essential: true,
      });
      // setPinLocation(null);
    }
  };

  return (
    <>
      {showSettings && (
        <div className="fixed top-0 left-0 z-50">
          <SettingsModel
            setLoading={() => setLoading(true)}
            setCancel={() => setShowSettings(false)}
          />
        </div>
      )}
      <div
        className={`w-screen bg-backgroundLight dark:bg-backgroundDark fade-in pt-16 z-0 h-screen transition-all duration-500 ease-in-out`}
      >
        <div
          className={`lg:w-2/3 w-full border-black border-opacity-30 fixed top-16 z-40 lg:z-50 right-0 ml-auto fade-in h-max p-3 bg-backgroundLight dark:bg-backgroundDark ${
            userLocation && "border-b border-black border-opacity-30"
          }`}
        >
          {userLocation && (
            <div className="">
              <Geocoder
                accessToken={MAPBOX_TOKEN}
                map={mapRef.current}
                mapboxgl={mapboxgl}
                value={inputValue}
                onChange={(value) => {
                  setInputValue(value);
                }}
                onResult={(result) => {
                  const { geometry } = result;
                  if (geometry) {
                    resetMapView(
                      geometry.coordinates[0],
                      geometry.coordinates[1]
                    );
                  }
                }}
              />
            </div>
          )}
        </div>
        {userLocation ? (
          <>
            <Map
              ref={mapRef}
              initialViewState={{
                latitude: userLocation.lat,
                longitude: userLocation.lng,
                zoom: 18,
                projection: "globe",
              }}
              style={{
                width: windowWidth < 640 ? "100%" : "68%",
                height: "100%",
                marginLeft: "auto",
              }}
              mapStyle={mapStyle}
              mapboxAccessToken={MAPBOX_TOKEN}
              onClick={handleMapClick}
              onLoad={handleMapLoad}
            >
              {mapLoaded && (
                <>
                  <Marker
                    latitude={userLocation.lat}
                    longitude={userLocation.lng}
                  >
                    <FaMapMarkerAlt fill="#000000" size={20} />
                  </Marker>

                  {pinLocation && (
                    <Marker
                      latitude={pinLocation.lat}
                      longitude={pinLocation.lng}
                    >
                      <FaMapMarkerAlt size={20} color="red" />
                    </Marker>
                  )}
                </>
              )}
              <div className="fixed lg:hidden fade-in flex flex-row lg:flex-col gap-3 lg:gap-3 top-40 -mt-1 right-3 pointer-events-auto">
                <button
                  onClick={() => resetMapView(0, 0)}
                  className="bg-backgroundLight dark:bg-backgroundDark w-8 h-8 flex items-center justify-center rounded shadow-lg hover:bg-gray-50 active:bg-gray-100"
                >
                  <FaLocationCrosshairs
                    size={16}
                    className="text-orange-500"
                    strokeWidth={2.5}
                  />
                </button>
                <button
                  onClick={() => setShowSettings(true)}
                  className="bg-backgroundLight dark:bg-backgroundDark w-8 h-8 flex items-center justify-center rounded shadow-lg hover:bg-gray-50 active:bg-gray-100"
                >
                  <FiSettings
                    size={16}
                    className="text-orange-500"
                    strokeWidth={2.5}
                  />
                </button>
              </div>
            </Map>

            <div className="hidden w-1/3 h-screen z-40 bg-backgroundLight dark:bg-backgroundDark fixed lg:block left-0 top-0">
              {pinLocation ? (
                <AddInfo location={pinLocation} showMobile={showMobile} />
              ) : (
                <div className="hidden px-8 pt-8 pb-28 border-r border-borderLight dark:border-borderDark w-1/3 h-screen border-t lg:flex flex-col items-center justify-center fixed left-0 top-20">
                  <div className="flex flex-col items-center justify-center w-full h-full border border-borderLight dark:border-borderDark rounded-lg bg-backgroundCardsLight dark:bg-backgroundCardsDark p-8">
                    <div className="flex items-center gap-2 bg-white/10 backdrop-blur-sm px-4 py-2 rounded-full mb-4">
                      <FaMapMarkerAlt className="w-5 h-5 text-orange-400" />
                      <span className="text-sm text-textLight dark:text-textDark">
                        Add Study Spot
                      </span>
                    </div>
                    
                    <h1 className="poppins-medium text-textLight dark:text-textDark text-2xl text-center mb-6">
                      Create Your Study Spot
                    </h1>
                    
                    <div className="flex flex-col gap-6 items-start w-full lg:w-3/5">
                      <div className="flex items-center gap-3 text-textLight2 dark:text-textDark2">
                        <div className="w-8 h-8 rounded-full bg-orange-500/10 flex items-center justify-center">
                          <span className="text-orange-500">1</span>
                        </div>
                        <p className="text-sm">Click on the map to select a location</p>
                      </div>
                      
                      <div className="flex items-center gap-3 text-textLight2 dark:text-textDark2">
                        <div className="w-8 h-8 rounded-full bg-orange-500/10 flex items-center justify-center">
                          <span className="text-orange-500">2</span>
                        </div>
                        <p className="text-sm">Fill in the study spot details</p>
                      </div>
                      
                      <div className="flex items-center gap-3 text-textLight2 dark:text-textDark2">
                        <div className="w-8 h-8 rounded-full bg-orange-500/10 flex items-center justify-center">
                          <span className="text-orange-500">3</span>
                        </div>
                        <p className="text-sm">Submit and share with the community</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div
              className={`fixed lg:hidden top-0 left-0 h-screen bg-backgroundLight dark:bg-backgroundDark z-40 w-full drawer-transition transition-transform duration-1000 ease-in-out ${
                !pinLocation ? "translate-x-0" : showMobile ? "translate-x-0" : "translate-x-full"
              }`}
            >
              {!pinLocation ? (
                <div className="px-6 pt-20 pb-28 flex flex-col items-center justify-center h-full">
                  <div className="flex flex-col items-center justify-center w-full h-full border border-borderLight dark:border-borderDark rounded-lg bg-backgroundCardsLight dark:bg-backgroundCardsDark p-8">
                    <div className="flex items-center gap-2 bg-white/10 backdrop-blur-sm px-4 py-2 rounded-full mb-4">
                      <FaMapMarkerAlt className="w-5 h-5 text-orange-400" />
                      <span className="text-sm text-textLight dark:text-textDark">
                        Add Study Spot
                      </span>
                    </div>
                    
                    <h1 className="poppins-medium text-textLight dark:text-textDark text-2xl text-center mb-6">
                      Create Your Study Spot
                    </h1>
                    
                    <div className="flex flex-col gap-6 items-start w-full">
                      <div className="flex items-center gap-3 text-textLight2 dark:text-textDark2">
                        <div className="w-8 h-8 rounded-full bg-orange-500/10 flex items-center justify-center">
                          <span className="text-orange-500">1</span>
                        </div>
                        <p className="text-sm">Click on the map to select a location</p>
                      </div>
                      
                      <div className="flex items-center gap-3 text-textLight2 dark:text-textDark2">
                        <div className="w-8 h-8 rounded-full bg-orange-500/10 flex items-center justify-center">
                          <span className="text-orange-500">2</span>
                        </div>
                        <p className="text-sm">Fill in the study spot details</p>
                      </div>
                      
                      <div className="flex items-center gap-3 text-textLight2 dark:text-textDark2">
                        <div className="w-8 h-8 rounded-full bg-orange-500/10 flex items-center justify-center">
                          <span className="text-orange-500">3</span>
                        </div>
                        <p className="text-sm">Submit and share with the community</p>
                      </div>
                    </div>

                    <CallToActionMainSmall
                      onClick={() => setPinLocation({ lat: userLocation.lat, lng: userLocation.lng })}
                      text="Start Adding Spot"
                      icon={<FaMapMarkerAlt />}
                      className="mt-8"
                    />
                  </div>
                </div>
              ) : (
                <>
                  <CallToActionSecondarySmall
                    onClick={() => setShowMobile(false)}
                    text="Back to Map"
                    icon={<FaArrowLeft />}
                    className="absolute top-20 right-4 z-50"
                  />
                  <AddInfo location={pinLocation} showMobile={showMobile} />
                </>
              )}
            </div>
          </>
        ) : (
          <Loading defualtLoading={true} />
        )}
      </div>
    </>
  );
};

export default AddMap;
