import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export const GeneralInput = ({
  labelText,
  placeholder,
  value,
  onChange,
  type = "text",
  className,
  required = false,
  hidden = false,
  onFocus,
  onBlur,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  return (
    <div className={`flex flex-col ${className && className} w-full`}>
      {labelText && (
        <label
          htmlFor={labelText}
          className="mb-1.5 flex flex-row items-start gap-0.5 text-textLight dark:text-textDark2 poppins-regular text-xs ml-0.5"
        >
          {labelText}
          {required && (
            <p className="text-mainColorLightOrange dark:text-mainColorDarkOrange">
              *
            </p>
          )}
        </label>
      )}
      <div className="relative">
        <input
          id={labelText}
          type={isPasswordVisible ? "text" : type} // Toggle between text and password
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder}
          className="px-3.5 py-2.5 poppins-regular text-sm w-full border border-borderLight dark:border-borderDark bg-backgroundCardsLight dark:bg-backgroundCardsDark rounded-md text-textLight dark:text-textDark focus:outline-none focus:ring-2 focus:ring-backgroundDark"
        />
        {type === "password" && (
          <button
            type="button"
            onClick={handleTogglePasswordVisibility}
            className="absolute right-4 top-1/2 transform -translate-y-1/2 text-textLight dark:text-textDark focus:outline-none"
          >
            {isPasswordVisible ? (
              <p className="poppins-regular text-textLight2 dark:text-textDark2 text-xs">
                Hide
              </p>
            ) : (
              <p className="poppins-regular text-textLight2 dark:text-textDark2 text-xs">
                Show
              </p>
            )}
          </button>
        )}
      </div>
    </div>
  );
};
