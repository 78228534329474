export const CallToActionMain = ({
  text,
  onClick,
  icon,
  type,
  className,
  isMain = true,
}) => {
  return (
    <button
      className={`${
        isMain
          ? "bg-mainColorLight dark:bg-mainColorDark text-white"
          : "bg-buttonSecondaryLight dark:bg-buttonSecondaryDark text-textLight dark:text-white"
      } lg:hover:scale-95 duration-700 justify-center ease-in-out poppins-medium text-xs flex flex-row items-center gap-2 lg:text-sm px-4 py-2 lg:px-6 lg:py-3 ${
        className ? className : "w-max rounded-lg"
      }`}
      onClick={onClick && onClick}
      type={type}
    >
      {icon && icon} {text}
    </button>
  );
};
