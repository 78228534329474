import React from "react";
import { useLocation } from "react-router-dom";

const NavButton = ({
  view,
  activeURL,
  viewShould,
  onclickFunction,
  icon,
  text,
}) => {
  const addressURL = useLocation();

  const isActive = addressURL.pathname === activeURL;

  return (
    <button
      onClick={onclickFunction}
      className={`${
        view === viewShould && isActive
          ? "bg-mainColorLightOrange dark:bg-mainColorDarkOrange text-white"
          : " text-gray-400 dark:text-textDark2"
      } lg:hover:scale-95 duration-700 ease-in-out poppins-medium rounded-lg text-xs px-4 py-2 lg:px-4 lg:py-2 flex flex-row items-center gap-2`}
    >
      {icon}
      {text}
    </button>
  );
};

export default NavButton;
