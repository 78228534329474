import React, { useState } from "react";
import {
  FaCheck,
  FaTimes,
  FaRegIdCard,
  FaVolumeDown,
  FaWifi,
  FaPlug,
  FaChalkboard,
} from "react-icons/fa";

export const FeatureSelector = ({ icon, label, isSelected, onToggle }) => {
  return (
    <button
      onClick={() => onToggle(!isSelected)}
      className={`
        relative w-full p-3 rounded-2xl overflow-hidden transition-all duration-300
        ${
          isSelected
            ? "bg-borderLight dark:bg-backgroundCardsDark2"
            : "bg-backgroundCardsLight dark:bg-backgroundCardsDark"
        }
      `}
    >
      <div className="flex items-center gap-2">
        <div
          className={`
          p-2 rounded-full flex items-center justify-center transition-all duration-300
          ${
            isSelected
              ? "bg-mainColorDark bg-opacity-20 text-mainColorDark"
              : "bg-backgroundLight dark:bg-backgroundCardsDark2 text-textLight dark:text-textDark2"
          }
        `}
        >
          {icon}
        </div>

        <span
          className={`
          text-xs text-left poppins-medium transition-colors duration-300 text-textLight dark:text-textDark
        `}
        >
          {label}
        </span>

        <div
          className={`
          ml-auto p-2 rounded-full flex items-center justify-center transition-all duration-300
          ${
            isSelected
              ? "bg-orange-500 text-white"
              : "bg-backgroundLight dark:bg-backgroundCardsDark2"
          }
        `}
        >
          {isSelected ? <FaCheck size={10} /> : <FaTimes size={10} />}
        </div>
      </div>
    </button>
  );
};
