export const SliderBlock = ({ option, icon }) => {
  const displayText =
    option && option.trim() !== ""
      ? option === "Mixed"
        ? "Individual and Shared"
        : option
      : "Not Available";

  return (
    <div className="relative w-full px-2 py-1 rounded-2xl overflow-hidden transition-all duration-300 bg-backgroundCardsLight dark:bg-backgroundCardsDark">
      <div className="relative px-2">
        <div className="relative flex items-start h-12">
          <div
            className={`
                flex-1 h-full flex gap-2 items-center justify-left
                text-xs poppins-regular z-10 transition-colors duration-200
                text-textDark2
              `}
          >
            <div
              className={`
                p-2 rounded-full flex items-center justify-center transition-all duration-300
                ${
                  true
                    ? "bg-mainColorDark bg-opacity-20 text-mainColorDark"
                    : "bg-backgroundLight dark:bg-backgroundCardsDark2 text-textLight dark:text-textDark2"
                }
              `}
            >
              {icon}
            </div>

            <span
              className={`
                text-xs text-left poppins-medium transition-colors duration-300 text-textLight dark:text-textDark
              `}
            >
              {displayText}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
