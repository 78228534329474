export const CallToActionSecondary = ({
  text,
  onClick,
  icon,
  type,
  className,
  disabled = false, // Add the disabled prop with default value false
}) => {
  return (
    <button
      className={`bg-buttonSecondaryLight flex flex-row items-center gap-2 dark:bg-buttonSecondaryDark lg:hover:scale-95 duration-700 ease-in-out poppins-medium rounded-lg text-xs lg:text-sm text-textLight dark:text-white px-4 py-2 lg:px-6 lg:py-3 ${
        className ? className : "w-max"
      } ${disabled ? "opacity-50 cursor-not-allowed" : ""}`} // Add conditional classes
      onClick={!disabled && onClick} // Disable onClick if button is disabled
      type={type}
      disabled={disabled} // Set the disabled attribute
    >
      {icon && icon} {text}
    </button>
  );
};
