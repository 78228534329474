import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { useDistanceUnit } from "../Context/DistanceUnitContext";
import useLocations from "../Hooks/useLocations.js";

export const SettingsModel = ({ setCancel, setLoading }) => {
  const [is3D, setIs3D] = useState(false);
  const [isSatellite, setIsSatellite] = useState(false);
  const { isMiles, setIsMiles } = useDistanceUnit();

  const {
    locations,
    loading,
    error: locError,
    refreshLocations,
  } = useLocations();

  const handle3D = (value) => {
    setIs3D(value);
    localStorage.setItem("is3D", value);
    if (value == true) {
      localStorage.setItem(
        "mapStyle",
        "mapbox://styles/studyspotr/cm3wi5cw2003401sl7xtw2do6"
      );
      setLoading();
    } else if (value == false) {
      localStorage.setItem(
        "mapStyle",
        "mapbox://styles/studyspotr/cm3wa1bm4001m01rc57lp98sh"
      );
      setLoading();
    }
  };

  const handleMiles = (value) => {
    setIsMiles(value);
    refreshLocations();
  };

  const handleSatellite = (value) => {
    setIsSatellite(value);
    localStorage.setItem("isSatellite", value);
    if (value == true) {
      localStorage.setItem(
        "mapStyle",
        "mapbox://styles/studyspotr/cm3wi49xh00dg01rd45ktgb0o"
      );
      setLoading();
    } else if (value == false) {
      localStorage.setItem(
        "mapStyle",
        "mapbox://styles/studyspotr/cm3wa1bm4001m01rc57lp98sh"
      );
      setLoading();
    }
  };

  useEffect(() => {
    const is3DValue = localStorage.getItem("is3D");
    setIs3D(is3DValue === "true");

    const isSatelliteValue = localStorage.getItem("isSatellite");
    setIsSatellite(isSatelliteValue === "true");

    const isMilesValue = localStorage.getItem("isMiles");
    setIsMiles(isMilesValue === "true");
  }, []);

  return (
    <div
      className="overlay fade-in fixed inset-0 z-30 flex items-center justify-center bg-black bg-opacity-60"
      onClick={() => setCancel(false)}
    >
      <div
        className="bg-white dark:bg-stone-900 rounded-md px-6 py-6 lg:px-10 lg:py-8 w-10/12 lg:w-1/3 shadow-lg"
        onClick={(e) => e.stopPropagation()} // Prevents the event from bubbling up to the overlay
      >
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-xl text-black dark:text-stone-300 poppins-medium mb-1">
            Settings
          </h1>
          <button
            onClick={() => setCancel(false)}
            className="text-xs flex flex-row items-center gap-1 lg:text-sm poppins-regular rounded-lg transition duration-500 ease-in-out"
          >
            Close <FaTimes />
          </button>
        </div>

        <div className="flex flex-col gap-y-6 lg:flex-row lg:flex-wrap justify-between w-full">
          {/* Your settings options remain unchanged */}
          <div className="flex flex-col gap-0.5">
            <p className="poppins-light opacity-40 text-sm">Map Dimension</p>
            <div className="flex flex-row gap-2 mt-2">
              <button
                onClick={() => handle3D(true)}
                className={`${
                  is3D ? "bg-orange-500 text-white" : "bg-stone-200"
                } poppins-medium px-3 py-1.5 rounded-lg`}
              >
                3D
              </button>
              <button
                onClick={() => handle3D(false)}
                className={`${
                  !is3D ? "bg-orange-500 text-white" : "bg-stone-200"
                } poppins-medium px-3 py-1.5 rounded-lg`}
              >
                2D
              </button>
            </div>
          </div>
          {/* Additional settings */}
          <div className="flex flex-col gap-0.5">
            <p className="poppins-light opacity-40 text-sm">Satellite View</p>
            <div className="flex flex-row gap-2 mt-2">
              <button
                onClick={() => handleSatellite(true)}
                className={`${
                  isSatellite ? "bg-orange-500 text-white" : "bg-stone-200"
                } poppins-medium px-3 py-1.5 rounded-lg`}
              >
                Yes
              </button>
              <button
                onClick={() => handleSatellite(false)}
                className={`${
                  !isSatellite ? "bg-orange-500 text-white" : "bg-stone-200"
                } poppins-medium px-3 py-1.5 rounded-lg`}
              >
                No
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-0.5">
            <p className="poppins-light opacity-40 text-sm">Distance Units</p>
            <div className="flex flex-row gap-2 mt-2">
              <button
                onClick={() => handleMiles(true)}
                className={`${
                  isMiles ? "bg-orange-500 text-white" : "bg-stone-200"
                } poppins-medium px-3 py-1.5 rounded-lg`}
              >
                Miles
              </button>
              <button
                onClick={() => handleMiles(false)}
                className={`${
                  !isMiles ? "bg-orange-500 text-white" : "bg-stone-200"
                } poppins-medium px-3 py-1.5 rounded-lg`}
              >
                KMs
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
