import React from "react";
import { GeneralInput } from "./GeneralInput";

export const InputGroup = ({ fields }) => {
  return (
    <>
      {fields.map(
        ({ label, placeholder, value, onChange, type, required }, index) => (
          <GeneralInput
            key={index}
            labelText={label}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            type={type}
            required={required}
            className="mt-4"
          />
        )
      )}
    </>
  );
};
