import React from "react";
import { CallToActionMain } from "../Buttons/CallToActionMain";
import { CallToActionSecondary } from "../Buttons/CallToActionSecondary";

export const NavigationButtons = ({ onBack, onNext, backDisabled, isLast }) => {
  return (
    <div className="flex flex-row items-center w-full gap-4">
      <CallToActionSecondary
        text="Back"
        type="button"
        className="mt-4 w-full"
        onClick={onBack}
        disabled={backDisabled}
      />
      <CallToActionMain
        text={isLast ? "Register" : "Next"}
        type="button" // Ensure it's always a button
        className="mt-4 w-full"
        onClick={onNext} // Handle navigation only
      />
    </div>
  );
};
