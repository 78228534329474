import React from "react";

export const StepIndicator = ({ currentStep, totalSteps }) => {
  return (
    <div className="flex mt-4 flex-row items-center gap-2">
      {Array.from({ length: totalSteps }, (_, i) => (
        <div
          key={i}
          className={`w-1.5 h-1.5 rounded-full ${
            currentStep === i + 1
              ? "bg-mainColorLight dark:bg-mainColorDark"
              : "bg-borderLight dark:bg-borderDark"
          }`}
        ></div>
      ))}
    </div>
  );
};
