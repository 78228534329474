import {
  FiChevronDown,
  FiChevronUp,
  FiFilePlus,
  FiList,
  FiMoon,
  FiNavigation,
  FiPlus,
} from "react-icons/fi";
import { useViewContext } from "../Context/ViewContext";
import StudySpotrLogo from "../Assets/logos/studyspotrlogoorange.svg";
import useProfile from "../Hooks/useProfile";
import { useNavigate, useParams } from "react-router-dom";
import defaultProfilePhoto from "../Assets/defaultProfilePhoto.jpg";
import { useEffect, useState } from "react";
import { ProfileDropdown } from "./ProfileDropdown";
import useMapboxGeocode from "../Hooks/useMapboxGeocode";
import useGeolocation from "../Hooks/useGeolocation";
import { useLocation } from "react-router-dom";
import { useTheme } from "../Context/ThemeContext";
import NavButton from "./NavButton";
import {
  FaBars,
  FaCog,
  FaHamburger,
  FaLightbulb,
  FaList,
  FaMapMarkerAlt,
  FaMoon,
  FaPlus,
  FaPlusCircle,
  FaRegBell,
  FaTimes,
  FaUserPlus,
  FaUsers,
} from "react-icons/fa";
import { BsFillLightbulbFill } from "react-icons/bs";
import { useAlert } from "../Context/AlertContext";
import { CallToActionMainOrange } from "./General/Buttons/CallToActionMainOrange";
import { CallToActionMain } from "./General/Buttons/CallToActionMain";
import { LikeModal } from "./LikeModal";
import { SideModal } from "./SideModal";
import { DesktopModal } from "./DesktopModal";
import { CreateModal } from "./CreateModal";
import { PiCoins, PiCoinVerticalBold } from "react-icons/pi";
import { CallToActionSecondarySmall } from "./General/Buttons/CallToActionSecondarySmall";
import { CallToActionMainOrangeSmall } from "./General/Buttons/CallToActionMainOrangeSmall";
import { NotificationsModal } from "./NotificationsModal";
import axios from "axios";
import { CallToActionMainSmall } from "./General/Buttons/CallToActionMainSmall";

export const Nav = () => {
  const { toggleMap, toggleList, view } = useViewContext();
  const { profile } = useProfile();
  const navigate = useNavigate();
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const { location } = useGeolocation();
  const addressURL = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isOut, setIsOut] = useState(false);
  const [createOut, setCreateOut] = useState(false);
  const [notiOut, setNotiOut] = useState(false);

  const [activePoints, setActivePoints] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0);

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/notification/${profile?._id}`
        );
        setNotifications(response.data);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, [profile]);

  useEffect(() => {
    if (profile) {
      setActivePoints(profile.activePoints || 0);
      setTotalPoints(profile.totalPoints || 0);
    }
  }, [profile]);

  const { getAddress, getCityAndCountry, address, cityCountry } =
    useMapboxGeocode();

  const { showAlert } = useAlert();

  const toggleShowProfileDropdown = () => {
    setShowProfileDropdown(!showProfileDropdown);
  };

  const navigateHome = () => {
    toggleList();
    navigate("/");
  };

  const handleClick = () => {
    showAlert("This is a global alert!");
  };

  const navigateLogin = () => {
    navigate("/login");
  };

  const navigateAddSpot = () => {
    navigate("/addStudySpot");
  };

  const navigateCommunities = () => {
    navigate("/community");
  };

  const navigatePointsPage = () => {
    navigate("/points");
  };

  const handleToggleList = () => {
    toggleList();
    navigate("/");
  };

  const handleToggleMap = () => {
    toggleMap();

    navigate("/");
  };

  const isActive = (current) => {
    if (addressURL.pathname == current) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (location && location.lat && location.lng) {
      getCityAndCountry(location.lat, location.lng);
    }
  }, [location, getCityAndCountry]);

  const handleModalOpen = (modalName) => {
    setIsOpen(false);
    setCreateOut(false);
    setNotiOut(false);

    switch (modalName) {
      case "profile":
        setIsOpen(true);
        break;
      case "create":
        setCreateOut(true);
        break;
      case "notifications":
        setNotiOut(true);
        break;
    }
  };

  return (
    <>
      {showProfileDropdown && (
        <ProfileDropdown
          toggleShowProfileDropdown={toggleShowProfileDropdown}
          showProfileDropdown={showProfileDropdown}
        />
      )}
      <nav className="flex flex-row border-b border-borderLight dark:border-borderDark bg-backgroundLight dark:bg-backgroundDark justify-between items-center px-3 lg:px-7 bg-background fixed top-0 left-0 w-screen h-16 z-50">
        <div className="flex flex-row gap-3">
          <button
            onClick={() => {
              setIsOut(true);
            }}
            className="flex lg:hidden pl-2 pr-1 h-full py-3 text-mainColorLightOrange"
          >
            <FaBars size={18} />
          </button>
          <button
            onClick={navigateHome}
            className="flex w-7 h-7 mt-auto mb-auto mr-1 lg:mr-3"
          >
            <img
              className="w-full h-full object-contain"
              src={StudySpotrLogo}
              alt="Study Spotr Logo"
            ></img>
          </button>
          <div className="hidden lg:flex flex-row gap-3">
            <NavButton
              view={view}
              activeURL="/"
              onclickFunction={handleToggleList}
              icon={<FaList size={12} />}
              viewShould="list"
              text={"Spots"}
            />
            <NavButton
              view={view}
              activeURL="/"
              onclickFunction={handleToggleMap}
              icon={<FaMapMarkerAlt />}
              viewShould="map"
              text={"Map"}
            />
            {/* <NavButton
              // view={view}
              activeURL="/community"
              onclickFunction={navigateCommunities}
              icon={<FaUsers />}
              // viewShould="map"
              text={"Community"}
            /> */}
          </div>
        </div>

        <div className="flex flex-row gap-0 lg:gap-1 items-center">
          {profile && (
            <>
              <CallToActionMainOrangeSmall
                icon={<FiFilePlus />}
                className={"hidden lg:flex w-max h-7 lg:h-8 ml-3 mr-2"}
                text={"Create"}
                onClick={() => handleModalOpen("create")}
              />
              <CallToActionMainSmall
                className={"w-max h-7 lg:h-8 mr-3"}
                icon={<FaRegBell />}
                text={
                  notifications.filter((n) => !n.isRead).length > 0 &&
                  notifications.filter((n) => !n.isRead).length
                }
                onClick={() => handleModalOpen("notifications")}
              />
              <CallToActionMainSmall
                className={"w-max h-7 lg:h-8 mr-3"}
                icon={<FaUserPlus />}
                text={"Invite a friend"}
                onClick={() => {
                  navigator.clipboard.writeText(
                    `https://studyspotr.com/register/${profile.referralLink}`
                  );
                  showAlert("Link copied to clipboard");
                }}
              />
              {/* <button
                onClick={navigatePointsPage}
                className="poppins-regular text-xs flex flex-col items-end mr-3 text-textLight2 dark:text-textDark2 hover:dark:text-textDark hover:text-textLight transition-all duration-500 ease-in-out"
              >
                <p className="flex flex-row gap-1 items-center text-xs">
                  {activePoints} points
                </p>
              </button> */}
            </>
          )}
          {profile ? (
            <button
              // onClick={toggleShowProfileDropdown}
              onClick={() => handleModalOpen("profile")}
              className="flex flex-row gap-1 lg:gap-4 items-center group"
            >
              <div
                // onClick={toggleShowProfileDropdown}
                onClick={() => handleModalOpen("profile")}
                className="w-8 h-8 bg-black text-white overflow-hidden flex flex-row items-center rounded-full poppins-medium lg:text-sm text-xs"
              >
                <img
                  className="w-full h-full object-cover"
                  alt="Profile"
                  src={
                    profile.profilePhoto
                      ? profile.profilePhoto
                      : defaultProfilePhoto
                  }
                />
              </div>
            </button>
          ) : (
            <CallToActionMain text={"Log In"} onClick={navigateLogin} />
          )}
        </div>
      </nav>
      <div className="lg:flex hidden">
        <DesktopModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
        <CreateModal isOpen={createOut} onClose={() => setCreateOut(false)} />
        <NotificationsModal
          notifications={notifications}
          setNotifications={setNotifications}
          isOpen={notiOut}
          onClose={() => setNotiOut(false)}
        />
      </div>
      <div className="flex lg:hidden">
        <LikeModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
        <CreateModal isOpen={createOut} onClose={() => setCreateOut(false)} />
        <NotificationsModal
          notifications={notifications}
          setNotifications={setNotifications}
          isOpen={notiOut}
          onClose={() => setNotiOut(false)}
        />
        <SideModal
          navigateCommunities={navigateCommunities}
          handleToggleList={handleToggleList}
          handleToggleMap={handleToggleMap}
          isOpen={isOut}
          onClose={() => setIsOut(false)}
        />
      </div>
    </>
  );
};
