import { useState } from "react";
import { ProfilePageCard } from "./ProfilePageCard";
import { FaCheck, FaPen, FaTimes } from "react-icons/fa";
import { SocialMediaLink } from "./SocialMediaLink";
import { CallToActionSecondarySmall } from "../General/Buttons/CallToActionSecondarySmall";
import axios from "axios";
import useProfile from "../../Hooks/useProfile";
import { CallToActionMainSmall } from "../General/Buttons/CallToActionMainSmall";
import { AnimatePresence } from "framer-motion";
import AlertPopUp from "../General/Alerts/AlertPopUp";
import { FiAlertCircle } from "react-icons/fi";

export const SocialMediaConnections = () => {
  const { profile, refreshProfile } = useProfile();
  const [editingSocials, setEditingSocials] = useState(false);
  const [newInstagram, setNewInstagram] = useState(null);
  const [newLinkedIn, setNewLinkedIn] = useState(null);
  const [newDiscord, setNewDiscord] = useState(null);
  const [newTwitter, setNewTwitter] = useState(null);
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const submitSocialMediaConnectionChanges = async () => {
    const payload = {};
    if (newInstagram) {
      payload.instagram = newInstagram;
    }
    if (newLinkedIn) {
      payload.linkedIn = newLinkedIn;
    }
    if (newDiscord) {
      payload.discord = newDiscord;
    }
    if (newTwitter) {
      payload.twitter = newTwitter;
    }

    if (Object.keys(payload).length === 0) {
      setMessage("Please update at least one field!");
      return;
    }

    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_URL}/api/auth/updateSocialMediaConnections/${profile._id}`,
        payload, // Send only the updated fields
        { withCredentials: true }
      );
      if (data) {
        refreshProfile();
        setEditingSocials(false);
        setMessage("");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setMessage(error.response.data.message);
        setShowAlert(true);
      } else {
        setMessage("Profile update failed. Please try again.");
        setShowAlert(true);
      }
      setMessage(error.response.data.message);
      setShowAlert(true);
    }
  };

  return (
    <>
      <AnimatePresence>
        {showAlert && message && (
          <AlertPopUp
            className={"right-0 bottom-10"}
            icon={<FiAlertCircle />}
            message={message}
            onClose={handleCloseAlert}
          />
        )}
      </AnimatePresence>

      <ProfilePageCard>
        <div className="flex flex-col w-full gap-4">
          <div className="flex flex-row justify-between gap-4">
            <h2 className="poppins-medium text-black dark:text-stone-300 text-lg">
              Social Media Connections
            </h2>
            <div className="flex flex-row gap-4 items-center w-max ml-auto mb-3 lg:mb-0">
              {editingSocials && (
                <CallToActionMainSmall
                  className={"h-max"}
                  onClick={submitSocialMediaConnectionChanges}
                  text={"Submit"}
                  icon={<FaCheck />}
                />
              )}
              <CallToActionSecondarySmall
                className={"h-max"}
                onClick={() => setEditingSocials((prevState) => !prevState)}
                icon={
                  editingSocials ? <FaTimes size={12} /> : <FaPen size={10} />
                }
                text={editingSocials ? "Cancel" : "Edit"}
              />
            </div>
          </div>

          <div
            className={`flex flex-col lg:flex-row justify-between gap-3 lg:mt-0 lg:gap-6 w-full`}
          >
            <SocialMediaLink
              url={profile && profile?.instagram}
              platform={"Instagram"}
              editingSocials={editingSocials}
              onChange={(e) => setNewInstagram(e.target.value)}
              placeholder={
                profile?.instagram
                  ? profile.instagram
                  : "e.g https://www.instagram.com/username"
              }
              value={newInstagram}
            />
            <SocialMediaLink
              url={profile && profile?.linkedIn}
              platform={"LinkedIn"}
              editingSocials={editingSocials}
              onChange={(e) => setNewLinkedIn(e.target.value)}
              placeholder={
                profile
                  ? profile?.linkedIn
                  : "e.g https://www.linkedin.com/in/username"
              }
              value={newLinkedIn}
            />
          </div>

          <div
            className={`flex flex-col lg:flex-row justify-between gap-3 lg:mt-0 lg:gap-6 w-full`}
          >
            <SocialMediaLink
              url={profile && profile?.discord}
              platform={"Discord"}
              editingSocials={editingSocials}
              onChange={(e) => setNewDiscord(e.target.value)}
              placeholder={
                profile?.discord
                  ? profile?.discord
                  : "e.g https://discord.com/users/userID"
              }
              value={newDiscord}
            />
            <SocialMediaLink
              url={profile && profile?.twitter}
              platform={"Twitter"}
              editingSocials={editingSocials}
              onChange={(e) => setNewTwitter(e.target.value)}
              placeholder={
                profile?.twitter
                  ? profile?.twitter
                  : "e.g https://x.com/username"
              }
              value={newTwitter}
            />
          </div>
        </div>
      </ProfilePageCard>
    </>
  );
};
