import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import ubc from "../Assets/ubcLogo.png";
import sfu from "../Assets/sfuLogo.png";
import uvic from "../Assets/uvic-mark.webp";
import uoft from "../Assets/oftlogo.png";
import { CallToActionMain } from "../Components/General/Buttons/CallToActionMain";
import avatar1 from "../Assets/defaultProfilePhoto.jpg";
import avatar2 from "../Assets/defaultProfilePhoto.jpg";
import avatar3 from "../Assets/defaultProfilePhoto.jpg";
import avatar4 from "../Assets/defaultProfilePhoto.jpg";
import avatar5 from "../Assets/defaultProfilePhoto.jpg";
import { FaGithub, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";
import { CallToActionMainSmall } from "../Components/General/Buttons/CallToActionMainSmall";

export const Home = () => {
  const navigate = useNavigate();

  const navigateToFind = () => {
    navigate("/find");
  };

  const navigateToCommunity = () => {
    navigate("/community");
  };

  return (
    <div className="w-screen pb-0 overflow-y-scroll scrollbar-hide h-screen bg-backgroundLight dark:bg-backgroundDark fade-in pt-12 mt-4 lg:pt-10">
      <div>
        <div className="relative overflow-hidden  h-max py-8 px-2 lg:px-0 lg:py-40 flex flex-col items-center justify-center">
          {/* Animated decorative cards */}
          <motion.div
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: "easeOut" }}
            whileHover={{ scale: 1.05, rotate: -15 }}
            className="absolute left-[5%] top-[20%] transform"
          >
            <div className="bg-white dark:bg-gray-800 -rotate-12 rounded-lg p-4 shadow-lg w-48 border border-purple-200 dark:border-purple-900">
              <h3 className="text-purple-600 dark:text-purple-400 font-semibold">
                Library Study Room
              </h3>
              <p className="text-sm text-gray-600 dark:text-gray-300">
                Quiet space • 4 seats
              </p>
              <div className="mt-2 flex items-center">
                <span className="inline-block w-2 h-2 bg-green-500 rounded-full mr-2 animate-pulse"></span>
                <span className="text-xs">Available Now</span>
              </div>
            </div>
          </motion.div>

          <motion.div
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: "easeOut" }}
            whileHover={{ scale: 1.05, rotate: 15 }}
            className="absolute right-[10%] top-[30%] transform"
          >
            <div className="bg-white dark:bg-gray-800 rotate-12 rounded-lg p-4 shadow-lg w-48 border border-orange-200 dark:border-orange-900">
              <h3 className="text-orange-500 dark:text-orange-400 font-semibold">
                CS Study Group
              </h3>
              <p className="text-sm text-gray-600 dark:text-gray-300">
                Data Structures • 6 members
              </p>
              <div className="mt-2 flex items-center">
                <span className="inline-block w-2 h-2 bg-blue-500 rounded-full mr-2 animate-pulse"></span>
                <span className="text-xs">Active Discussion</span>
              </div>
            </div>
          </motion.div>

          {/* Gradient overlays */}
          <div className="absolute inset-0 bg-gradient-to-r from-mainColorLight/10 via-transparent to-mainColorLightOrange/10" />
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-backgroundDark" />

          {/* Main content */}
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
            className="z-10 text-center"
          >
            <h1
              style={{
                lineHeight: "4rem",
              }}
              className="text-4xl poppins-semibold lg:text-5xl max-w-3xl mx-auto px-10 mt-8 lg:mt-16 text-textLight dark:text-textDark"
            >
              Connecting you with{" "}
              <span className="text-mainColorLight dark:text-mainColorDark">
                study spots
              </span>{" "}
              and{" "}
              <span className="text-mainColorLightOrange dark:text-mainColorDarkOrange2">
                communities
              </span>
            </h1>
            <p className="poppins-light text-sm lg:text-base max-w-2xl mx-auto text-center mt-6 lg:mt-8 text-textLight2 dark:text-textDark2">
              Join or create study groups that match your courses and interests.
              Connect with like-minded peers to discuss topics, share resources,
              and collaborate effectively.
            </p>

            {/* CTA Buttons */}
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.3, duration: 0.6 }}
              className="flex flex-col sm:flex-row gap-4 justify-center mt-10"
            >
              <CallToActionMain
                onClick={navigateToFind}
                text="Find Study Spots"
                className="px-8 py-3 bg-gradient-to-r from-mainColorLight to-mainColorDark rounded-full"
              ></CallToActionMain>
              <CallToActionMain
                onClick={navigateToCommunity}
                text="Join Community"
                className="px-8 py-3 bg-gradient-to-r from-mainColorLightOrange to-mainColorDarkOrange2 rounded-full"
              ></CallToActionMain>
            </motion.div>
          </motion.div>
        </div>

        {/* Student Testimonials Section */}
        <div className="relative py-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
              className="text-center"
            >
              <h2 className="text-3xl poppins-semibold text-textLight dark:text-textDark mb-4">
                Join thousands of satisfied students
              </h2>
              <p className="poppins-light text-textLight2 dark:text-textDark2 mb-12">
                See how StudySpotr is transforming the way students collaborate
                and study
              </p>
            </motion.div>

            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
              className="text-center mb-20"
            >
              <div className="flex flex-col items-center">
                {/* Overlapping avatars */}
                <div className="flex items-center -space-x-4 mb-6">
                  {[avatar1, avatar2, avatar3, avatar4, avatar5].map(
                    (avatar, index) => (
                      <motion.div
                        key={index}
                        initial={{ x: 20, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: index * 0.1, duration: 0.4 }}
                        className="relative"
                      >
                        <img
                          src={avatar}
                          alt={`User ${index + 1}`}
                          className="w-12 h-12 rounded-full border-4 border-white dark:border-gray-800 object-cover shadow-lg hover:z-10 transition-transform hover:scale-110"
                        />
                      </motion.div>
                    )
                  )}
                </div>

                {/* Trusted by text */}
                <h3 className="text-xl poppins-medium text-textLight dark:text-textDark mb-2">
                  Trusted by your peers
                </h3>

                {/* University logos in a more modern layout */}
                <div className="flex flex-wrap justify-center items-center gap-8 mt-8">
                  {[
                    { src: ubc, size: "w-16 h-16" },
                    { src: sfu, size: "w-14 h-14" },
                    { src: uvic, size: "w-16 h-16" },
                    { src: uoft, size: "w-16 h-16" },
                  ].map((logo, index) => (
                    <motion.div
                      key={index}
                      initial={{ y: 20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ delay: 0.2 + index * 0.1, duration: 0.4 }}
                      className="relative group"
                    >
                      <img
                        src={logo.src}
                        alt="University logo"
                        className={`${logo.size} object-contain uni-logo opacity-60 hover:opacity-100 transition-opacity duration-300`}
                      />
                    </motion.div>
                  ))}
                </div>

                <p className="poppins-regular text-[0.6rem] text-textDark2 opacity-40 mt-4 max-w-md">
                  Logos are used for illustrative purposes only. StudySpotr is
                  not affiliated with or endorsed by these institutions.
                </p>
              </div>
            </motion.div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
              {/* Testimonial 1 */}
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.6 }}
                className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-md"
              >
                <div className="flex items-center mb-4">
                  <div className="h-12 w-12 rounded-full bg-gradient-to-r from-mainColorLight to-mainColorDark flex items-center justify-center text-white text-xl font-bold">
                    S
                  </div>
                  <div className="ml-4">
                    <h3 className="poppins-medium text-textLight dark:text-textDark">
                      Sarah Chen
                    </h3>
                    <p className="text-sm text-textLight2 dark:text-textDark2">
                      Computer Science, UBC
                    </p>
                  </div>
                </div>
                <p className="text-textLight2 dark:text-textDark2">
                  "Found my perfect study group through StudySpotr. We meet
                  weekly for algorithm practice and the collaborative
                  environment has improved my grades significantly!"
                </p>
              </motion.div>

              {/* Testimonial 2 */}
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.4, duration: 0.6 }}
                className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-md"
              >
                <div className="flex items-center mb-4">
                  <div className="h-12 w-12 rounded-full bg-gradient-to-r from-mainColorLightOrange to-mainColorDarkOrange2 flex items-center justify-center text-white text-xl font-bold">
                    M
                  </div>
                  <div className="ml-4">
                    <h3 className="poppins-medium text-textLight dark:text-textDark">
                      Michael Patel
                    </h3>
                    <p className="text-sm text-textLight2 dark:text-textDark2">
                      Engineering, UofT
                    </p>
                  </div>
                </div>
                <p className="text-textLight2 dark:text-textDark2">
                  "The study room finder is a game-changer! No more wandering
                  around campus looking for a quiet spot. I can see exactly
                  what's available in real-time."
                </p>
              </motion.div>

              {/* Testimonial 3 */}
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.6, duration: 0.6 }}
                className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-md"
              >
                <div className="flex items-center mb-4">
                  <div className="h-12 w-12 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 flex items-center justify-center text-white text-xl font-bold">
                    J
                  </div>
                  <div className="ml-4">
                    <h3 className="poppins-medium text-textLight dark:text-textDark">
                      Jessica Kim
                    </h3>
                    <p className="text-sm text-textLight2 dark:text-textDark2">
                      Biology, SFU
                    </p>
                  </div>
                </div>
                <p className="text-textLight2 dark:text-textDark2">
                  "Love how easy it is to connect with other students in my
                  program. Made some great friends and study buddies through the
                  community feature!"
                </p>
              </motion.div>
            </div>

            {/* Stats Section */}
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.8, duration: 0.6 }}
              className="grid grid-cols-3 gap-8 mt-16 max-w-3xl mx-auto text-center"
            >
              <div>
                <h3 className="text-3xl font-bold text-mainColorLight dark:text-mainColorDark">
                  2,000+
                </h3>
                <p className="text-sm text-textLight2 dark:text-textDark2">
                  Active Users
                </p>
              </div>
              <div>
                <h3 className="text-3xl font-bold text-mainColorLightOrange dark:text-mainColorDarkOrange2">
                  500+
                </h3>
                <p className="text-sm text-textLight2 dark:text-textDark2">
                  Study Groups
                </p>
              </div>
              <div>
                <h3 className="text-3xl font-bold text-purple-500">4.8/5</h3>
                <p className="text-sm text-textLight2 dark:text-textDark2">
                  User Rating
                </p>
              </div>
            </motion.div>
          </div>
        </div>
      </div>

      {/* New Footer Section */}
      <footer className="bg-backgroundCardsLight dark:bg-backgroundCardsDark mt-20">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-12 py-16">
            {/* Brand Column */}
            <div className="col-span-1 md:col-span-1">
              <h3 className="text-xl poppins-semibold text-textLight dark:text-textDark mb-4">
                StudySpotr
              </h3>
              <p className="text-sm text-textLight2 dark:text-textDark2 poppins-light mb-6">
                Connecting students with study spaces and communities for better
                learning experiences.
              </p>
              <div className="flex space-x-4">
                <a
                  href="#"
                  className="text-textLight2 dark:text-textDark2 hover:text-mainColorLight dark:hover:text-mainColorDark transition-colors"
                >
                  <FaTwitter className="w-5 h-5" />
                </a>
                <a
                  href="#"
                  className="text-textLight2 dark:text-textDark2 hover:text-mainColorLight dark:hover:text-mainColorDark transition-colors"
                >
                  <FaLinkedin className="w-5 h-5" />
                </a>
                <a
                  href="#"
                  className="text-textLight2 dark:text-textDark2 hover:text-mainColorLight dark:hover:text-mainColorDark transition-colors"
                >
                  <FaGithub className="w-5 h-5" />
                </a>
                <a
                  href="#"
                  className="text-textLight2 dark:text-textDark2 hover:text-mainColorLight dark:hover:text-mainColorDark transition-colors"
                >
                  <FaInstagram className="w-5 h-5" />
                </a>
              </div>
            </div>

            {/* Product Column */}
            <div>
              <h3 className="text-sm poppins-semibold text-textLight dark:text-textDark mb-4 uppercase tracking-wider">
                Product
              </h3>
              <ul className="space-y-3">
                {[
                  "Features",
                  "Find Study Spots",
                  "Join Communities",
                  "Premium",
                ].map((item) => (
                  <li key={item}>
                    <a
                      href="#"
                      className="text-sm text-textLight2 poppins-regular dark:text-textDark2 hover:text-mainColorLight dark:hover:text-mainColorDark transition-colors"
                    >
                      {item}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            {/* Resources Column */}
            <div>
              <h3 className="text-sm poppins-semibold text-textLight dark:text-textDark mb-4 uppercase tracking-wider">
                Resources
              </h3>
              <ul className="space-y-3">
                {["Blog", "Help Center", "Contact Us", "Terms of Service"].map(
                  (item) => (
                    <li key={item}>
                      <a
                        href="#"
                        className="text-sm text-textLight2 poppins-regular dark:text-textDark2 hover:text-mainColorLight dark:hover:text-mainColorDark transition-colors"
                      >
                        {item}
                      </a>
                    </li>
                  )
                )}
              </ul>
            </div>

            {/* Newsletter Column */}
            <div>
              <h3 className="text-sm poppins-semibold text-textLight dark:text-textDark mb-4 uppercase tracking-wider">
                Stay Updated
              </h3>
              <p className="text-sm text-textLight2 poppins-regular dark:text-textDark2 mb-4">
                Subscribe to our newsletter for the latest updates and features.
              </p>
              <form className="flex flex-col space-y-2">
                <input
                  type="text"
                  placeholder="Enter your email"
                  className="px-4 py-2 rounded-lg bg-gray-100 dark:bg-gray-800 text-textLight dark:text-textDark border border-gray-200 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-mainColorLight dark:focus:ring-mainColorDark"
                />
                {/* <input
                  type="email"
                  placeholder="Enter your email"
                /> */}
                <CallToActionMain
                  text="Subscribe"
                  className="px-4 py-2 bg-gradient-to-r from-mainColorLight to-mainColorDark text-white rounded-lg"
                ></CallToActionMain>
              </form>
            </div>
          </div>

          {/* Bottom Bar */}
          <div className="border-t border-gray-200 dark:border-gray-800 py-8">
            <div className="flex flex-col md:flex-row justify-between items-center">
              <p className="text-sm text-textLight2 dark:text-textDark2">
                © {new Date().getFullYear()} StudySpotr. All rights reserved.
              </p>
              <div className="flex space-x-6 mt-4 md:mt-0">
                <a
                  href="#"
                  className="text-sm text-textLight2 dark:text-textDark2 hover:text-mainColorLight dark:hover:text-mainColorDark transition-colors"
                >
                  Privacy Policy
                </a>
                <a
                  href="#"
                  className="text-sm text-textLight2 dark:text-textDark2 hover:text-mainColorLight dark:hover:text-mainColorDark transition-colors"
                >
                  Terms of Service
                </a>
                <a
                  href="#"
                  className="text-sm text-textLight2 dark:text-textDark2 hover:text-mainColorLight dark:hover:text-mainColorDark transition-colors"
                >
                  Cookie Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
