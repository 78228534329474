export const CallToActionMainOrange = ({
  text,
  onClick,
  icon,
  type,
  className,
}) => {
  return (
    <button
      className={`bg-mainColorLightOrange dark:bg-mainColorDarkOrange flex flex-row gap-2 items-center lg:hover:scale-95 duration-700 ease-in-out poppins-medium rounded-lg text-white text-xs lg:text-sm px-4 py-2 lg:px-6 lg:py-3 ${
        className ? className : "w-max"
      }`}
      onClick={onClick && onClick}
      type={type}
    >
      {icon && icon} {text}
    </button>
  );
};
