import { useState, useEffect } from "react";
import axios from "axios";
import defaultImage from "../../Assets/defaultProfilePhoto.jpg";
import { AiFillStar } from "react-icons/ai";

function SpotComments({ location, profile }) {
  const [userProfiles, setUserProfiles] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userIds = [
      ...new Set(location.comments.map((comment) => comment.userId)),
    ];

    axios
      .post(`${process.env.REACT_APP_URL}/api/auth/usersByIds`, { userIds })
      .then((response) => {
        const profilesMap = response.data.reduce((acc, user) => {
          acc[user._id] = user.profilePhoto;
          return acc;
        }, {});
        setUserProfiles(profilesMap);
        setLoading(false); // Set loading to false once data is fetched
      })
      .catch((error) => {
        console.error("Failed to fetch user profiles:", error);
        setLoading(false); // Ensure loading is set to false even on error
      });
  }, [location.comments]);

  if (!profile) {
    return (
      <p className="text-sm text-textLight dark:text-textDark2 poppins-regular mt-2 ml-1">
        Please log in to see the comments!
      </p>
    );
  }

  if (loading) {
    return (
      <p className="text-sm text-textLight dark:text-textDark2 poppins-regular mt-2 ml-1">
        Please wait while comments load..
      </p>
    );
  }

  return (
    <div className="h-auto w-full mt-1 flex flex-col gap-3 scrollbar-none overflow-y-scroll scrollbar-hide">
      {location.comments
        .filter(
          (commentObj) =>
            profile && (commentObj.userId === profile._id || !profile.isPremium)
        )
        .map((commentObj) => (
          <div
            key={commentObj._id}
            className="bg-backgroundLight dark:bg-backgroundDark border border-borderLight dark:border-borderDark px-4 py-3 h-max rounded-xl"
          >
            {location.createdById == commentObj.userId && (
              <p className="text-xs text-black dark:text-stone-600 poppins-regular text-opacity-40 mb-2">
                Created this study spot
              </p>
            )}
            <div className="text-sm poppins-semibold flex justify-between flex-row items-center gap-2">
              <div className="flex flex-row mb-1.5 items-center gap-1">
                <div className="flex flex-row text-black dark:text-stone-400 items-center gap-2">
                  <div className="w-6 h-6 rounded-full overflow-hidden">
                    <img
                      className="object-cover w-full h-full rounded-full"
                      src={userProfiles[commentObj.userId] || defaultImage}
                      alt="Profile"
                    />
                  </div>
                  {commentObj.username}
                </div>
                <div className="flex ml-2">
                  {Array(commentObj.rating)
                    .fill()
                    .map((_, index) => (
                      <AiFillStar
                        key={index}
                        size={14}
                        className="text-mainColorLightOrange dark:text-mainColorDarkOrange2"
                      />
                    ))}
                </div>
              </div>
              <p className="text-xs text-black dark:text-stone-500 text-opacity-30 poppins-light">
                {new Date(commentObj.timeStamp).toLocaleDateString()}
              </p>
            </div>
            <p className="text-sm text-black dark:text-stone-400 poppins-regular mt-1">
              {commentObj.comment}
            </p>
          </div>
        ))}
    </div>
  );
}

export default SpotComments;
