import { CallToActionMainSmall } from "../Buttons/CallToActionMainSmall";
import { CallToActionSecondarySmall } from "../Buttons/CallToActionSecondarySmall";

export const ConfirmAlertGeneral = ({
  setCancel,
  setConfirm,
  message,
  title,
  text,
  confirmButtonText = "Confirm",
}) => {
  return (
    <div
      className="overlay fade-in fixed inset-0 z-30 flex items-center justify-center bg-black bg-opacity-60"
      onClick={() => setCancel(false)}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="bg-backgroundCardsLight dark:bg-backgroundCardsDark rounded-md px-6 py-6 lg:px-10 lg:py-8 w-10/12 lg:w-1/3 shadow-lg"
      >
        <h1 className="text-lg poppins-medium mb-1 text-textLight dark:text-textDark">
          {title}
        </h1>

        <p className="poppins-regular text-sm mb-6 text-textLight dark:text-textDark2">
          {text}
        </p>

        <div className="flex flex-row gap-3 justify-end">
          <CallToActionSecondarySmall
            text={"Cancel"}
            onClick={() => setCancel(false)}
          />
          <CallToActionMainSmall
            onClick={() => setConfirm(true)}
            text={confirmButtonText}
          />
        </div>
      </div>
    </div>
  );
};
