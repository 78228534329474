import React, { useState } from "react";
import { CallToActionSecondarySmall } from "../General/Buttons/CallToActionSecondarySmall";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const PaginationProgress = ({
  totalSteps,
  currentStep = 1,
  setCurrentStep,
}) => {
  const handleNext = () => {
    if (currentStep < totalSteps) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handlePrev = () => {
    if (currentStep > 1) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  return (
    <div className="w-full max-w-xl mx-auto bg-transparent rounded-xl">
      <div className="">
        <div className="relative">
          <div className="h-1 w-full bg-backgroundCardsLight2 dark:bg-backgroundCardsDark2 rounded-full">
            <div
              className="h-1 bg-blue-500 rounded-full transition-all duration-300 ease-in-out"
              style={{
                width: `${((currentStep - 1) / (totalSteps - 1)) * 100}%`,
              }}
            />
          </div>

          {/* Step indicators */}
          <div className="absolute top-1/2 -translate-y-1/2 w-full flex justify-between">
            {Array.from({ length: totalSteps }).map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentStep(index + 1)}
                className={`
                w-6 h-6 rounded-full transition-all duration-300
                flex items-center justify-center
                ${
                  index + 1 <= currentStep
                    ? "bg-mainColorLight dark:bg-mainColorDark text-white"
                    : "bg-backgroundCardsLight2 dark:bg-backgroundCardsDark2 text-gray-500"
                }
                ${index + 1 === currentStep ? "ring-4 ring-ringBlue" : ""}
              `}
              >
                <span className="text-xs font-semibold">{index + 1}</span>
              </button>
            ))}
          </div>
        </div>
        <div className="flex mt-6 justify-between items-center">
          <CallToActionSecondarySmall
            onClick={handlePrev}
            disabled={currentStep === 1}
            text={"Back"}
          />
          <CallToActionSecondarySmall
            onClick={handleNext}
            disabled={currentStep === totalSteps}
            text={"Continue"}
          />
          {/* <button
            className={`
            flex items-center gap-2 py-1 rounded-lg
            transition-all duration-200 text-xs poppins-regular
            ${
              currentStep === 1
                ? "text-textLight dark:text-textDark2 cursor-not-allowed"
                : "text-textLight2 dark:text-textDark"
            }
          `}
          >
            
          </button> */}
          {/* 
          <button
            
            className={`
            flex items-center gap-2 py-1 rounded-lg
            transition-all duration-200 text-xs poppins-regular
            ${
              currentStep === totalSteps
                ? "text-textLight dark:text-textDark2 cursor-not-allowed"
                : "text-textLight2 dark:text-textDark"
            }
          `}
          >
            Next Step
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default PaginationProgress;
