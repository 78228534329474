import { useEffect, useState } from "react";
import useProfile from "../Hooks/useProfile";
import { QRCodeCanvas } from "qrcode.react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { FiAlertCircle } from "react-icons/fi";
import { AnimatePresence } from "framer-motion";
import AlertPopUp from "../Components/General/Alerts/AlertPopUp";
import useMapboxGeocode from "../Hooks/useMapboxGeocode";
import { LastUpdated } from "../Components/LastUpdated";
import useGeolocation from "../Hooks/useGeolocation";
import { BasicInformation } from "../Components/Profile/BasicInformation";
import { SocialMediaConnections } from "../Components/Profile/SocialMediaConnections";
import { AdditionalInformation } from "../Components/Profile/AdditionalInformation";
import { Points } from "../Components/Profile/Points";
import { StudyTypeQuizOnProfile } from "../Components/Profile/StudyTypeQuizOnProfile";


export const Profile = () => {
  const { profile } = useProfile();
  const [locationsCreate, setLocationsCreated] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState(null);
  const { location } = useLocation();
  const { locationUser } = useGeolocation();
  const { getCityAndCountry } = useMapboxGeocode();

  console.log(profile);

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const getLocations = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/locations/${id}`
      );

      if (response.data && response.data.length > 0) {
        setLocationsCreated(response.data);
      } else {
        setLocationsCreated([]);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status == 404) {
          return;
        }
      }
    }
  };

  useEffect(() => {
    if (profile) {
      getLocations(profile._id);
    }
  }, [profile]);

  useEffect(() => {
    if (locationUser && locationUser.lat && locationUser.lng) {
      getCityAndCountry(locationUser.lat, locationUser.lng);
    }
  }, [location, getCityAndCountry]);

  if (!profile) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="w-screen overflow-y-scroll h-screen bg-backgroundLight dark:bg-backgroundDark fade-in pt-16 mt-2 lg:pt-28 pb-40">
        <AnimatePresence>
          {showAlert && message && (
            <AlertPopUp
              icon={<FiAlertCircle />}
              message={message}
              onClose={handleCloseAlert}
            />
          )}
        </AnimatePresence>
        <div className="lg:px-20 px-4 lg:py-2 mt-1">
          <div className="flex flex-col gap-2">
            <h1 className="poppins-medium text-2xl text-black dark:text-stone-400">
              Personal Information
            </h1>
            <p className="poppins-regular text-sm text-black dark:text-stone-400">
              Your Profile Details at a Glance
            </p>
          </div>
          <div className="flex flex-col lg:flex-row h-max mt-4 gap-4 w-full">
            <div className="w-full -mt-6">
              <BasicInformation />
              <Points />
              <SocialMediaConnections />
              <StudyTypeQuizOnProfile />
              <AdditionalInformation />
            </div>
          </div>
        </div>
        <LastUpdated />
      </div>
    </>
  );
};


<div className="flex flex-col lg:flex-row justify-between mt-8">
        <h3 className="poppins-medium text-xl text-black dark:text-white mb-4">How to earn points</h3>
        <h3 className="poppins-medium text-xl text-black dark:text-white mb-4">How to redeeem points</h3>
 </div>