export const CallToActionSecondarySmall = ({
  text,
  onClick,
  icon,
  type,
  className,
  disabled = false,
}) => {
  return (
    <button
      className={`${className && className} bg-buttonSecondaryLight ${
        disabled && "opacity-30"
      } dark:bg-buttonSecondaryDark poppins-regular text-textLight2 dark:text-textDark2 lg:hover:scale-95 duration-700 ease-in-out poppins-regular rounded-lg text-xs  px-2.5 py-1.5 lg:px-4 lg:py-2  flex flex-row gap-2 justify-center items-center`}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {icon && <p>{icon}</p>}
      {text && <p>{text}</p>}
    </button>
  );
};
