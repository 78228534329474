export const CallToActionMainSmall = ({
  text,
  onClick,
  icon,
  type,
  className,
  disabled = false,
}) => {
  return (
    <button
      className={`${className && className} bg-mainColorLight ${
        disabled && "opacity-30"
      } dark:bg-mainColorDark text-center poppins-regular text-white lg:hover:scale-95 duration-700 ease-in-out poppins-regular rounded-lg text-xs  px-2.5 py-1.5 lg:px-4 lg:py-2  flex flex-row gap-2 justify-center items-center`}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {icon && <p className="w-max">{icon}</p>}
      {text && <p className="w-max">{text}</p>}
    </button>
  );
};
