import { FaArrowLeft, FaArrowRight, FaTimes } from "react-icons/fa";
import { useGetHelp } from "../../Context/HelpContext";
import { GeneralButton } from "../General/GeneralButton";
import desktop1 from "../../Assets/desktop1.png";
import desktop2 from "../../Assets/desktop2.png";
import desktop3 from "../../Assets/desktop3.png";
import mobile1 from "../../Assets/mobile1.png";
import mobile2 from "../../Assets/mobile2.png";
import mobile3 from "../../Assets/mobile3.png";
import logo from "../../Assets/studySpotrLogoOrange.png";
import { useState } from "react";

export const MainInstructions = () => {
  const { showingHelp, setShowingHelp } = useGetHelp();
  const [index, setIndex] = useState(0);
  const [animation, setAnimation] = useState(""); // For tracking fade animation

  const images = [desktop1, desktop2, desktop3];
  const imagesMobile = [mobile1, mobile2, mobile3];

  // Update index with fade animation
  const next = () => {
    if (index < images.length - 1) {
      setAnimation("fade-out");
      setTimeout(() => {
        setIndex(index + 1);
        setAnimation("fade-in");
      }, 300); // Match this with animation duration
    }
  };

  const back = () => {
    if (index > 0) {
      setAnimation("fade-out");
      setTimeout(() => {
        setIndex(index - 1);
        setAnimation("fade-in");
      }, 300); // Match this with animation duration
    }
  };

  return (
    <div className="w-screen fade-in h-auto lg:h-screen flex items-center flex-col justify-center overflow-hidden">
      <img src={logo} className="w-8 lg:w-12 mt-2 lg:-mt-16 h-auto mb-2" />
      <div className="w-10/12 overflow-hidden rounded-xl h-auto hidden lg:flex">
        <img
          src={images[index]}
          className={`object-contain w-full h-full transition-opacity duration-300 ${animation}`}
          alt={`Instruction ${index + 1}`}
        />
      </div>

      <div className="w-10/12 overflow-hidden rounded-xl h-auto block lg:hidden">
        <img
          src={imagesMobile[index]}
          className={`object-contain w-full h-full transition-opacity duration-300 ${animation}`}
          alt={`Instruction ${index + 1}`}
        />
      </div>

      <div className="lg:fixed mt-3 lg:mt-0 lg:bottom-4 w-screen px-4 lg:px-32">
        <div className="w-max ml-auto mr-auto mb-4 lg:-mb-6 flex flex-row gap-2">
          {images.map((_, i) => (
            <div
              key={i}
              className={`w-2 h-2 rounded-full ${
                index === i ? "bg-orange-500" : "bg-stone-600"
              }`}
            ></div>
          ))}
        </div>

        <div className="flex flex-row justify-between items-center">
          <GeneralButton
            text={"Close"}
            icon={<FaTimes />}
            onClick={() => setShowingHelp(false)}
            buttonBool={true}
          />
          <div className="flex flex-row items-center gap-2">
            {index > 0 && (
              <GeneralButton
                text={"Back"}
                icon={<FaArrowLeft />}
                onClick={back}
                buttonBool={index > 0}
              />
            )}
            {index < 2 ? (
              <GeneralButton
                text={"Next"}
                icon={<FaArrowRight />}
                iconRight={true}
                orange={true}
                onClick={next}
                buttonBool={index < images.length - 1}
              />
            ) : (
              <GeneralButton
                text={"Finish"}
                icon={<FaArrowRight />}
                iconRight={true}
                orange={true}
                onClick={() => setShowingHelp(false)}
                buttonBool={index < images.length - 1}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
