export const CallToActionMainOrangeSmall = ({
  text,
  onClick,
  icon,
  type,
  className,
  disabled = false,
}) => {
  return (
    <button
      className={`${
        className && className
      } bg-mainColorLightOrange dark:bg-mainColorDarkOrange poppins-medium text-white lg:hover:scale-95 duration-700 ease-in-out poppins-regular rounded-lg text-xs  px-2.5 py-1.5 lg:px-4 lg:py-2  flex flex-row gap-2 justify-center items-center`}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {icon && <p>{icon}</p>}
      {text && <p>{text}</p>}
    </button>
  );
};
