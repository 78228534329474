import { List } from "../Components/Find/List";
import FindMap from "../Components/Find/FindMap";
import { useViewContext } from "../Context/ViewContext";
import { useGetHelp } from "../Context/HelpContext";
import { MainInstructions } from "../Components/Instructions/MainInstructions";
import useLocations from "../Hooks/useLocations";
import useSelectLocation from "../Hooks/useSelectLocation";

export const Find = () => {
  const { view } = useViewContext();
  const { showingHelp, setShowingHelp } = useGetHelp();
  const { locations, loading, error: locError } = useLocations();
  const { selectedLocation, deselectLocation } = useSelectLocation(locations);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (locError) {
    return <div>Error loading locations: {locError}</div>;
  }

  return (
    <div className="w-screen h-screen overflow-hidden">
      {/* {!selectedLocation && (
        <>
          <button
            onClick={() => setShowingHelp(true)}
            className="hidden fade-in lg:block fixed bottom-3 z-20 right-3 bg-black opacity-80 text-white poppins-medium text-xs px-4 py-2 rounded-md"
          >
            Need Help?
          </button>
          {view === "map" && (
            <button
              onClick={() => setShowingHelp(true)}
              className="lg:hidden fade-in block fixed top-48 mt-2 z-20 right-3 bg-black opacity-80 text-white poppins-medium text-xs px-3 py-1.5 rounded-md"
            >
              Need Help?
            </button>
          )}
        </>
      )} */}
      {showingHelp && (
        <div className="fixed bg-black bg-opacity-95 top-0 left-0 z-50 h-screen w-screen overflow-hidden flex flex-col">
          <MainInstructions />
        </div>
      )}
      <div className="relative h-full w-full">
        <div
          className={`h-full w-full flex transition-all duration-700 transform`}
        >
          <div
            className={`h-full bg-backgroundLight dark:bg-backgroundDark z-30 transition-all duration-700 ${
              view === "list" ? "w-full lg:w-1/3" : "w-0"
            }`}
          >
            <List />
          </div>

          <div
            className={`h-full bg-backgroundLight dark:bg-backgroundDark transition-all duration-700 ${
              view === "list" ? "w-0 lg:w-2/3" : "w-full"
            }`}
          >
            <FindMap />
          </div>
        </div>
      </div>
    </div>
  );
};
