import { motion, AnimatePresence } from "framer-motion";
import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaInfo, FaInfoCircle, FaTimes, FaUsers } from "react-icons/fa";
import { useTheme } from "../Context/ThemeContext";
import useProfile from "../Hooks/useProfile";
import {
  FiAlertTriangle,
  FiBell,
  FiBookOpen,
  FiCheckCircle,
  FiMessageSquare,
} from "react-icons/fi";

const DropdownItem = ({ noti, onClick }) => (
  <button
    onClick={(e) => {
      e.stopPropagation(); // Prevent the modal from closing when a notification is clicked
      onClick(noti?.link); // Redirect to the link
    }}
    className={`text-sm hover:cursor-pointer text-left w-full text-textLight py-4 dark:text-textDark px-6 border-2 border-mainColorDark dark:border-borderDark lg:hover:text-mainColorLightOrange lg:dark:hover:text-mainColorLightOrange transition duration-500 ease-in-out poppins-light tracking-wide flex flex-row gap-2 lg:gap-5 items-center`}
  >
    <div className="flex flex-row items-center gap-2">
      <p className="w-1/6 lg:w-1/12">
        {noti.type === "info" && (
          <FiAlertTriangle size={20} className="text-mainColorLightOrange" />
        )}
      </p>
      <p className="w-5/6 lg:w-11/12">{noti.description}</p>
    </div>
  </button>
);

const getTimeSincePost = (timestamp) => {
  const now = new Date();
  const postDate = new Date(timestamp);
  const diffInSeconds = Math.floor((now - postDate) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds}s`;
  }

  const diffInMinutes = Math.floor(diffInSeconds / 60);
  if (diffInMinutes < 60) {
    return `${diffInMinutes}m`;
  }

  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) {
    return `${diffInHours}h`;
  }

  const diffInDays = Math.floor(diffInHours / 24);
  if (diffInDays < 365) {
    return `${diffInDays}d`;
  }

  const diffInYears = Math.floor(diffInDays / 365);
  return `${diffInYears}${diffInYears === 1 ? "y" : "y"}`;
};

export const NotificationsModal = ({
  isOpen,
  onClose,
  userId,
  notifications,
  setNotifications,
}) => {
  const navigate = useNavigate();
  const { profile } = useProfile();
  const { isDarkMode, toggleDarkMode } = useTheme();

  const dismissNotification = async (id) => {
    // Immediately update the UI by marking the notification as read
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification?._id === id
          ? { ...notification, isRead: true } // Mark as read
          : notification
      )
    );

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_URL}/notification/${id}/hide`
      );

      if (response.data.message === "Notification marked as hidden.") {
        // Remove the notification from the list right away
        setNotifications((prevNotifications) =>
          prevNotifications.filter((note) => note._id !== id)
        );
      } else {
        console.error(
          "Error in marking notification as hidden:",
          response.data
        );
      }
    } catch (error) {
      console.error("Error marking notification as hidden:", error);
    }
  };

  const getIcon = (type) => {
    switch (type) {
      case "urgent":
        return <FiAlertTriangle className="text-amber-500" size={16} />;
      case "warning":
        return <FiCheckCircle className="text-emerald-500" size={16} />;
      case "info":
      default:
        return <FiMessageSquare className="text-blue-500" size={16} />;
    }
  };

  useEffect(() => {
    if (isOpen) {
      const fetchNotifications = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_URL}/notification/${profile._id}`
          );
          setNotifications(response.data);
        } catch (error) {
          console.error("Error fetching notifications:", error);
        }
      };

      fetchNotifications();
    }
  }, [isOpen, userId]);

  // Handle notification click and redirect
  const handleNotificationClick = (link) => {
    window.location.href = link; // Redirect to the notification link
  };

  const markAsRead = async (notificationId) => {
    try {
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification?._id === notificationId
            ? { ...notification, isRead: true }
            : notification
        )
      );

      // Make the API call to update the backend
      const response = await axios.put(
        `${process.env.REACT_APP_URL}/notification/${notificationId}/read`
      );

      if (response.data.message === "Notification marked as read.") {
        console.log("Notification successfully marked as read");
      } else {
        console.error("Error in marking notification as read:", response.data);
      }
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          {/* Background Overlay */}
          <motion.div
            className="fixed inset-0 z-40"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
          />
          <motion.div
            className="fixed overflow-hidden text-left top-16 mt-2 w-80 lg:w-96 right-2 lg:right-32 mr-3 bg-backgroundLight dark:bg-backgroundCardsDark rounded-2xl z-40 shadow-xl flex flex-col"
            initial={{ y: "-100%" }}
            animate={{ y: 0 }}
            exit={{ y: "-120%" }}
            transition={{ type: "spring", stiffness: 120, damping: 20 }}
          >
            {/* Header */}
            <div className="px-6 py-4 bg-backgroundLight dark:bg-backgroundCardsDark flex items-center justify-between border-b border-borderLight dark:border-borderDark">
              <div className="flex items-center gap-2">
                <FiBell size={18} className="text-textLight dark:text-textDark" />
                <h2 className="poppins-medium text-textLight dark:text-textDark">Notifications</h2>
                <span className="bg-blue-500 text-white text-xs poppins-regular px-2 py-0.5 rounded-full">
                  {notifications.filter((n) => !n.isRead).length}
                </span>
              </div>
            </div>

            {/* Notifications List */}
            <div className="max-h-96 overflow-y-auto">
              {notifications.filter((n) => !n.isRead).length === 0 ? (
                <div className="py-8 text-center text-gray-500">
                  <FiBookOpen
                    className="mx-auto mb-2 text-gray-400"
                    size={24}
                  />
                  <p>No notifications</p>
                </div>
              ) : (
                notifications.map(
                  (notification) =>
                    notification.showModal && (
                      <div
                        key={notification.id}
                        className={`relative px-4 py-4 border-b border-borderLight dark:border-borderDark transition-colors ${
                          notification.isRead ? "bg-backgroundCardsLight dark:bg-backgroundCardsDark" : "bg-backgroundLight dark:bg-backgroundDark"
                        }`}
                      >
                        <div className="flex gap-3">
                          <div
                            className={`h-4 w-4 lg:h-8 lg:w-8 flex items-center justify-center mt-auto mb-auto p-1 rounded-full ${
                              notification.isRead 
                                ? "bg-backgroundLight dark:bg-backgroundDark" 
                                : "bg-backgroundCardsLight dark:bg-backgroundDark"
                            }`}
                          >
                            {getIcon(notification.type)}
                          </div>
                          <div className="flex-1 min-w-0">
                            <div className="flex justify-between items-start">
                              <h3
                                className={`text-xs lg:text-sm poppins-medium ${
                                  notification.isRead
                                    ? "text-textLight2 dark:text-textDark2"
                                    : "text-textLight dark:text-textDark"
                                }`}
                              >
                                {notification.description}
                              </h3>
                              <span className="text-[0.6rem] poppins-light text-textLight2 dark:text-textDark2 whitespace-nowrap ml-2">
                                {getTimeSincePost(notification.createdAt)} ago
                              </span>
                            </div>
                            <p
                              className={`text-sm mt-1 ${
                                notification.isRead
                                  ? "text-textLight2 dark:text-textDark2"
                                  : "text-textLight dark:text-textDark"
                              }`}
                            >
                              {notification.message}
                            </p>
                            <div className="mt-2 flex gap-3">
                              {!notification.isRead && notification.type != "urgent" && (
                                <button
                                  onClick={() => markAsRead(notification?._id)}
                                  className="text-[0.7rem] poppins-regular text-textLight2 dark:text-textDark2 hover:text-mainColorLightOrange dark:hover:text-mainColorLightOrange"
                                >
                                  Mark as read
                                </button>
                              )}
                              <a
                                href={notification.link}
                                target="_blank"
                                className="text-[0.7rem] poppins-medium text-mainColorLight dark:text-mainColorDark hover:text-mainColorLightOrange dark:hover:text-mainColorLightOrange"
                                onClick={(e) => {
                                  e.preventDefault();
                                  markAsRead(notification?._id);
                                  dismissNotification(notification?._id);
                                  window.open(notification.link, "_blank");
                                }}
                              >
                                Take action
                              </a>
                            </div>
                          </div>

                          {notification.isRead && (
                            <button
                              onClick={() =>
                                dismissNotification(notification?._id)
                              }
                              className="text-textLight2 dark:text-textDark2 hover:text-mainColorLightOrange dark:hover:text-mainColorLightOrange"
                              aria-label="Dismiss notification"
                            >
                              <FaTimes size={13} />
                            </button>
                          )}
                        </div>

                        {!notification.isRead && (
                          <div className="absolute left-0 top-0 bottom-0 w-1 bg-blue-500"></div>
                        )}
                      </div>
                    )
                )
              )}
            </div>

            {/* Footer */}
            <div className="px-6 py-3 bg-backgroundLight dark:bg-backgroundCardsDark border-t border-borderLight dark:border-borderDark">
              <button className="w-full text-sm text-textLight2 dark:text-textDark2 hover:text-mainColorLightOrange dark:hover:text-mainColorLightOrange font-medium transition duration-300">
                View all notifications
              </button>
            </div>
          </motion.div>

          {/* <h3 className="poppins-medium text-base text-textLight2 dark:text-textDark2 ml-6 mb-3">
              Notifications
            </h3>
            {notifications.length > 0 ? (
              notifications.map((notification, index) => (
                <DropdownItem
                  key={index}
                  noti={notification}
                  onClick={handleNotificationClick} // Pass the function here
                />
              ))
            ) : (
              <p className="text-center text-textLight dark:text-textDark py-3">
                No notifications
              </p>
            )}

            <div className="w-full h-[1.5px] bg-borderLight dark:bg-borderDark"></div>
            <button
              onClick={onClose}
              className={`text-sm w-full py-6 text-textLight dark:text-textDark px-6 border-borderLight dark:border-borderDark border-opacity-30  lg:hover:text-mainColorLightOrange lg:dark:hover:text-mainColorLightOrange transition duration-500 ease-in-out poppins-light tracking-wide flex flex-row gap-2 lg:gap-5 items-center `}
            >
              <FaTimes
                className={"text-textLight2 dark:text-textDark2"}
                size={16}
              />
              Close
            </button>
          </motion.div> */}
        </>
      )}
    </AnimatePresence>
  );
};
