import React from "react";

const SpotTag = ({ label, isLast }) => {
  return (
    <div
      className={`w-max poppins-regular text-textLight2 dark:text-textDark3 text-xs flex flex-row items-center gap-2`}
    >
      {label}
      {!isLast && (
        <div className="w-1 h-1 bg-backgroundLight3 dark:bg-backgroundDark3 rounded-full"></div>
      )}
    </div>
  );
};

export default SpotTag;
