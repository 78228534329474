import "./App.css";
import { Analytics } from "@vercel/analytics/react";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ViewContextProvider } from "./Context/ViewContext";
import { Find } from "./Pages/Find";
import { Create } from "./Pages/Create";
import ProtectedRoute from "./Components/ProtectedRoute";
import { Profile } from "./Pages/Profile";
import { Login } from "./Pages/Login";
import { Register } from "./Pages/Register";
import { Nav } from "./Components/Nav";
import useLocations from "./Hooks/useLocations";
import { SavedLocationsProvider } from "./Context/SavedLocationContext";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import { UpdateContextProvider } from "./Context/UpdatedContext";
import { RoutesContextProvider } from "./Context/RoutesContext";
import { GetHelpContext, GetHelpProvider } from "./Context/HelpContext";
import { ResetPassword } from "./Pages/ResetPassword";
import { CreatedSpots } from "./Pages/CreatedSpots";
import { Community } from "./Pages/Community";
import { ThemeProvider } from "./Context/ThemeContext";
import { DistanceProvider } from "./Context/DistanceContext";
import { FiAlertCircle } from "react-icons/fi";
import { AnimatePresence } from "framer-motion";
import AlertPopUp from "./Components/General/Alerts/AlertPopUp";
import { AlertProvider, useAlert } from "./Context/AlertContext";
import ErrorBoundary from "./ErrorBoundary";
import { StudyGroup } from "./Pages/StudyGroup";
import { DistanceUnitProvider } from "./Context/DistanceUnitContext";
import { DiscussionPost } from "./Components/Community/StudyGroup/Discussions/DiscussionPost";
import { CreateStudyGroup } from "./Components/Community/Create/CreateStudyGroup";
import { CreateStudySession } from "./Components/Community/Create/CreateStudySession";
import { StudySession } from "./Pages/StudySession";
import { SessionChat } from "./Pages/SessionChat";
import { CreateDiscussionPost } from "./Components/Community/Create/CreateDiscussionPost";
import { PointsPage } from "./Pages/PointsPage";
import { StudyTypeQuizOnProfile } from "./Components/Profile/StudyTypeQuizOnProfile";
import { StudyTypeQuiz } from "./Pages/StudyTypeQuiz";
import { Home } from "./Pages/Home";

function App() {
  const { locations } = useLocations();
  const { alert, hideAlert } = useAlert();

  document.addEventListener("gesturestart", function (e) {
    e.preventDefault();
  });

  const initialMap = () => {
    const mapStyle = localStorage.getItem("mapStyle");
    if (!mapStyle || mapStyle === "") {
      console.log("Setting mapStyle to default");
      localStorage.setItem(
        "mapStyle",
        "mapbox://styles/studyspotr/cm3wa1bm4001m01rc57lp98sh"
      );
    }
  };

  useEffect(() => {
    initialMap();
  }, []);

  return (
    <Router>
      <AnimatePresence>
        {alert.visible && (
          <AlertPopUp
            icon={<FiAlertCircle />}
            message={alert.message}
            onClose={hideAlert}
          />
        )}
      </AnimatePresence>
      <ThemeProvider>
        <ErrorBoundary>
          <DistanceUnitProvider>
            <DistanceProvider>
              <ViewContextProvider>
                <UpdateContextProvider>
                  <RoutesContextProvider>
                    <GetHelpProvider>
                      <SavedLocationsProvider locations={locations}>
                        <Nav />
                        <Routes>
                          <Route path="/home" element={<Home />} />
                          <Route path="/:id/edit" element={<Find />} />
                          <Route path="/:id" element={<Find />} />
                          <Route path="/" element={<Find />} />
                          <Route path="/login" element={<Login />} />
                          <Route
                            path="/reset-password"
                            element={<ResetPassword />}
                          />
                          <Route
                            path="/reset-password/:id"
                            element={<ResetPassword />}
                          />
                          <Route path="/register" element={<Register />} />
                          <Route
                            path="/register/:referralLink"
                            element={<Register />}
                          />
                          <Route
                            path="/profile"
                            element={<ProtectedRoute element={<Profile />} />}
                          />
                          <Route
                            path="/privacy"
                            element={
                              <ProtectedRoute element={<PrivacyPolicy />} />
                            }
                          />
                          <Route
                            path="/created-spots"
                            element={
                              <ProtectedRoute element={<CreatedSpots />} />
                            }
                          />
                          <Route
                            path="/addStudySpot"
                            element={<ProtectedRoute element={<Create />} />}
                          />
                          {/* <Route
                            path="/community"
                            element={<ProtectedRoute element={<Community />} />}
                          />
                          <Route
                            path="/community/createastudygroup"
                            element={
                              <ProtectedRoute element={<CreateStudyGroup />} />
                            }
                          />
                          <Route
                            path="/community/studygroup/:id/createPost"
                            element={
                              <ProtectedRoute
                                element={<CreateDiscussionPost />}
                              />
                            }
                          />
                          <Route
                            path="/community/createastudysession"
                            element={
                              <ProtectedRoute
                                element={<CreateStudySession />}
                              />
                            }
                          />
                          <Route
                            path="/community/studygroup/:id"
                            element={
                              <ProtectedRoute element={<StudyGroup />} />
                            }
                          />
                          <Route
                            path="/community/studysession/:id"
                            element={
                              <ProtectedRoute element={<StudySession />} />
                            }
                          />
                          <Route
                            path="/community/studysession/:id/chatroom"
                            element={
                              <ProtectedRoute element={<SessionChat />} />
                            }
                          />
                          <Route
                            path="/community/studygroup/:groupId/:postId"
                            element={
                              <ProtectedRoute element={<DiscussionPost />} />
                            }
                          /> */}
                          <Route
                            path="/points"
                            element={
                              <ProtectedRoute element={<PointsPage />} />
                            }
                          />
                          <Route
                            path="/studyTypeQuiz"
                            element={
                              <ProtectedRoute element={<StudyTypeQuiz />} />
                            }
                          />
                        </Routes>
                      </SavedLocationsProvider>
                    </GetHelpProvider>
                  </RoutesContextProvider>
                </UpdateContextProvider>
              </ViewContextProvider>
            </DistanceProvider>
          </DistanceUnitProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </Router>
  );
}

export default App;
