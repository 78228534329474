import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import axios from "axios";
import React from "react";
import {
  FiDatabase,
  FiLock,
  FiLogOut,
  FiPlus,
  FiSettings,
  FiUser,
  FiUserPlus,
  FiUsers,
} from "react-icons/fi";
import {
  RiBookMarkedLine,
  RiBookmarkLine,
  RiFileAddLine,
} from "react-icons/ri";
import { useNavigate, useLocation } from "react-router-dom";
import { FaCog, FaCoins, FaMoon, FaRegMoon, FaTimes } from "react-icons/fa";
import { useTheme } from "../Context/ThemeContext";

const DropdownItem = ({ icon: Icon, label, onClick, isActive }) => (
  <button
    onClick={onClick}
    className={`text-sm w-full text-textLight py-1.5 dark:text-textDark px-6 border-borderLight dark:border-borderDark border-opacity-30 lg:hover:bg-backgroundCardsLight lg:dark:hover:bg-backgroundCardsDark transition duration-500 ease-in-out poppins-light tracking-wide flex flex-row gap-2 lg:gap-5 items-center`}
  >
    <Icon className={"text-textLight2 dark:text-textDark2"} size={16} />
    {label}
  </button>
);

export const LikeModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isDarkMode, toggleDarkMode } = useTheme();

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_URL}/api/auth/logout`);
      localStorage.removeItem("token");
      navigate("/login");
      window.location.reload();
    } catch (error) {
      console.log("Logout error:", error);
    }
  };

  const menuItems = [
    {
      icon: FiUser,
      label: "View Profile",
      action: () => {
        navigate("/profile");
        onClose();
      },
      path: "/profile",
    },
    {
      icon: FaCoins,
      label: "Points",
      action: () => {
        navigate("/points");
        onClose();
      },
      path: "/points",
    },
    {
      icon: FiDatabase,
      label: "Data & Privacy",
      action: () => {
        navigate("/privacy");
        onClose();
      },
      path: "/privacy",
    },
    {
      icon: RiFileAddLine,
      label: "Created Spots",
      action: () => {
        navigate("/created-spots");
        onClose();
      },
      path: "/created-spots",
    },
    {
      icon: isDarkMode ? FaMoon : FaRegMoon,
      label: `Dark Mode: ${isDarkMode ? "On" : "Off"}`,
      action: toggleDarkMode,
    },
    {
      icon: FaCog,
      label: "Settings",
      //   action: () => {
      //     navigate("/created-spots");
      //     onClose();
      //   },
    },

    // { icon: FiLogOut, label: "Log Out", action: handleLogout },
  ];
  return (
    <AnimatePresence>
      {isOpen && (
        <>
          {/* Background Overlay */}
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-80 z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose} // Close when clicking outside
          />

          <motion.div
            className="fixed bottom-0 left-0 right-0 bg-backgroundLight dark:bg-backgroundCardsDark rounded-t-2xl z-50 shadow-lg flex flex-col gap-6 pt-6"
            initial={{ y: "100%" }}
            animate={{ y: 0 }}
            exit={{ y: "100%" }}
            transition={{ type: "spring", stiffness: 120, damping: 20 }}
          >
            {menuItems.map((item, index) => (
              <DropdownItem
                key={index}
                icon={item.icon}
                label={item.label}
                onClick={item.action}
              />
            ))}
            <div className="w-full h-[1.5px] bg-borderLight dark:bg-borderDark"></div>
            <button
              onClick={handleLogout}
              className={`text-sm w-full text-textLight dark:text-textDark px-6 border-borderLight dark:border-borderDark border-opacity-30 lg:hover:bg-backgroundCardsLight lg:dark:hover:bg-backgroundCardsDark transition duration-500 ease-in-out poppins-light tracking-wide flex flex-row gap-2 lg:gap-5 items-center `}
            >
              <FiLogOut
                className={"text-textLight2 dark:text-textDark2"}
                size={16}
              />
              Log Out
            </button>
            <div className="w-full h-[1.5px] bg-borderLight dark:bg-borderDark"></div>
            <button
              onClick={onClose}
              className={`text-sm w-full pb-6 text-textLight dark:text-textDark px-6 border-borderLight dark:border-borderDark border-opacity-30 lg:hover:bg-backgroundCardsLight lg:dark:hover:bg-backgroundCardsDark transition duration-500 ease-in-out poppins-light tracking-wide flex flex-row gap-2 lg:gap-5 items-center `}
            >
              <FaTimes
                className={"text-textLight2 dark:text-textDark2"}
                size={16}
              />
              Close
            </button>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};
