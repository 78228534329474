import React, { createContext, useContext, useState } from "react";

const UpdatedContext = createContext();

export const useUpdatedContext = () => useContext(UpdatedContext);

export const UpdateContextProvider = ({ children }) => {
  const [date, setDate] = useState("Nov 17 at 2024, 10:24:44 PM PST");

  const getCurrentDateTime = () => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
      timeZone: "America/Los_Angeles", // Pacific Time
      timeZoneName: "short",
    };

    let currentDate = new Date().toLocaleString("en-US", options);
    currentDate = currentDate.replace(",", " at");

    // Replace PDT with PST if you need it to always show PST
    if (currentDate.includes("PDT")) {
      currentDate = currentDate.replace("PDT", "PST");
    }

    return currentDate;
  };

  const updateTime = () => {
    console.log(getCurrentDateTime());
  };

  return (
    <UpdatedContext.Provider
      value={{
        date,
        updateTime,
      }}
    >
      {children}
    </UpdatedContext.Provider>
  );
};
